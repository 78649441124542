import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { default as sessionStorage } from "redux-persist/lib/storage/session";
import storage from "redux-persist/lib/storage";
import { GenerateSlice } from "../../../utils";

import { slice as ordinationsSlice } from "./ordinations/slice";
import { slice as bookingSlice } from "./booking/slice";

export const reducer = combineReducers({
  itemgroups: GenerateSlice("atorder_itemgroups").reducer,
  items: GenerateSlice("atorder_items").reducer,
  ordinations: persistReducer(
    { key: "atorder.ordinations", storage: sessionStorage },
    ordinationsSlice.reducer
  ),
  booking: persistReducer(
    { key: "atorder.booking", storage: storage },
    bookingSlice.reducer
  ),
});
