/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      enabled
      logo
      currency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      shortCode
      isPartner
      partnershipStartDate
      isServiceManager
      nextItemSorting
      nextItemGroupSorting
      defaultPartnerCode
      companyName
      companyVat
      firstUserId
      firstRetailStoreId
      userCount
      moitoIotCount
      retailStoreCount
      cashRegisterCount
      rtUtilityInactive
      currentRtUtilityCategory
      partnerText
      partnerTosAccepted
      partnerTosAcceptedVersion
      privacyPolicyAcceptedDate
      archismallUsername
      archismallPassword
      archismallActiveInvoicesLastDatetime
      archismallPassiveInvoicesLastDatetime
      isBetaTester
      partnerCashRegisterCounter
      partnerMoitoIotCounter
      extensions
      mobile
      email
      zipCode
      county
      municipality
      address
      country
      isAccountant
      accountantId
      stripeAccountId
      stripeRegistrationCompleted
      blockLevel
      dailyReportMode
      createdAt
      updatedAt
      tenantUser {
        items {
          id
          userId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      party {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCurrency = /* GraphQL */ `
  mutation CreateCurrency(
    $input: CreateCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    createCurrency(input: $input, condition: $condition) {
      id
      name
      symbol
      code
      createdAt
      updatedAt
    }
  }
`;
export const updateCurrency = /* GraphQL */ `
  mutation UpdateCurrency(
    $input: UpdateCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    updateCurrency(input: $input, condition: $condition) {
      id
      name
      symbol
      code
      createdAt
      updatedAt
    }
  }
`;
export const deleteCurrency = /* GraphQL */ `
  mutation DeleteCurrency(
    $input: DeleteCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    deleteCurrency(input: $input, condition: $condition) {
      id
      name
      symbol
      code
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      partyId
      enabled
      tenantDefault
      retailStoreDefault
      showTutorial
      email
      mobile
      pushNotificationTokens {
        service
        deviceToken
      }
      deleted
      firstName
      lastName
      createdAt
      updatedAt
      tenantUser {
        items {
          id
          userId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      userPrivilege {
        items {
          id
          userId
          privilegeId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      userLicense {
        items {
          id
          tenantId
          userId
          licenseId
          validFrom
          validTo
          createdAt
          updatedAt
        }
        nextToken
      }
      userRetailStore {
        items {
          id
          userId
          retailStoreId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePartnershipRequest = /* GraphQL */ `
  mutation UpdatePartnershipRequest(
    $input: UpdatePartnershipRequestInput!
    $condition: ModelPartnershipRequestConditionInput
  ) {
    updatePartnershipRequest(input: $input, condition: $condition) {
      id
      tenantId
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const createLicense = /* GraphQL */ `
  mutation CreateLicense(
    $input: CreateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    createLicense(input: $input, condition: $condition) {
      id
      name
      commercialName
      baseName
      erpItemId
      createdAt
      updatedAt
    }
  }
`;
export const updateLicense = /* GraphQL */ `
  mutation UpdateLicense(
    $input: UpdateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    updateLicense(input: $input, condition: $condition) {
      id
      name
      commercialName
      baseName
      erpItemId
      createdAt
      updatedAt
    }
  }
`;
export const deleteLicense = /* GraphQL */ `
  mutation DeleteLicense(
    $input: DeleteLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    deleteLicense(input: $input, condition: $condition) {
      id
      name
      commercialName
      baseName
      erpItemId
      createdAt
      updatedAt
    }
  }
`;
export const createAppSettings = /* GraphQL */ `
  mutation CreateAppSettings(
    $input: CreateAppSettingsInput!
    $condition: ModelAppSettingsConditionInput
  ) {
    createAppSettings(input: $input, condition: $condition) {
      id
      firstTenantUserPrivilegeGroups
      defaultCurrency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      oldEJExpDate
      lastTobaccoItemsS3Key
      lastTobaccoBarcodesS3Key
      licensesByDealerS3Key
      createdAt
      updatedAt
      defaultPartner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      AtorderLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      AtorderDeliveryLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EInvoiceItLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityFwDownloadLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityLogsLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserCollectorLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotAutomaticUpdateLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotMultipleUsersLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotOnlineBackupLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotCollectorLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotDealerLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotCateringLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityEJExtensionLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      CalendarLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      ForValueLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserAccountantLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJManagementLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJOneYearLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJTwoYearLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJNoLimitLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      OpticalLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotKioskLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotTobaccoLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      partnerPrivilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      serviceManagerPrivilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      lastMoitoIotStableVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      lastMoitoIotBetaVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteAppSettings = /* GraphQL */ `
  mutation DeleteAppSettings(
    $input: DeleteAppSettingsInput!
    $condition: ModelAppSettingsConditionInput
  ) {
    deleteAppSettings(input: $input, condition: $condition) {
      id
      firstTenantUserPrivilegeGroups
      defaultCurrency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      oldEJExpDate
      lastTobaccoItemsS3Key
      lastTobaccoBarcodesS3Key
      licensesByDealerS3Key
      createdAt
      updatedAt
      defaultPartner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      AtorderLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      AtorderDeliveryLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EInvoiceItLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityFwDownloadLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityLogsLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserCollectorLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotAutomaticUpdateLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotMultipleUsersLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotOnlineBackupLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotCollectorLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotDealerLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotCateringLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityEJExtensionLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      CalendarLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      ForValueLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserAccountantLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJManagementLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJOneYearLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJTwoYearLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJNoLimitLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      OpticalLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotKioskLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotTobaccoLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      partnerPrivilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      serviceManagerPrivilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      lastMoitoIotStableVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      lastMoitoIotBetaVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateLicenseRequest = /* GraphQL */ `
  mutation UpdateLicenseRequest(
    $input: UpdateLicenseRequestInput!
    $condition: ModelLicenseRequestConditionInput
  ) {
    updateLicenseRequest(input: $input, condition: $condition) {
      id
      tenantId
      partnerId
      retailStoreId
      duration
      deleted
      extensions
      startFromDate
      level
      isFree
      autorenew
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      applicantUser {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPrivilege = /* GraphQL */ `
  mutation CreatePrivilege(
    $input: CreatePrivilegeInput!
    $condition: ModelPrivilegeConditionInput
  ) {
    createPrivilege(input: $input, condition: $condition) {
      id
      name
      description
      ability
      entity
      createdAt
      updatedAt
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      privilegeGroups {
        items {
          id
          privilegeId
          privilegeGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      userPrivilege {
        items {
          id
          userId
          privilegeId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePrivilege = /* GraphQL */ `
  mutation UpdatePrivilege(
    $input: UpdatePrivilegeInput!
    $condition: ModelPrivilegeConditionInput
  ) {
    updatePrivilege(input: $input, condition: $condition) {
      id
      name
      description
      ability
      entity
      createdAt
      updatedAt
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      privilegeGroups {
        items {
          id
          privilegeId
          privilegeGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      userPrivilege {
        items {
          id
          userId
          privilegeId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePrivilege = /* GraphQL */ `
  mutation DeletePrivilege(
    $input: DeletePrivilegeInput!
    $condition: ModelPrivilegeConditionInput
  ) {
    deletePrivilege(input: $input, condition: $condition) {
      id
      name
      description
      ability
      entity
      createdAt
      updatedAt
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      privilegeGroups {
        items {
          id
          privilegeId
          privilegeGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      userPrivilege {
        items {
          id
          userId
          privilegeId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPrivilegePrivilegeGroup = /* GraphQL */ `
  mutation CreatePrivilegePrivilegeGroup(
    $input: CreatePrivilegePrivilegeGroupInput!
    $condition: ModelPrivilegePrivilegeGroupConditionInput
  ) {
    createPrivilegePrivilegeGroup(input: $input, condition: $condition) {
      id
      privilegeId
      privilegeGroupId
      createdAt
      updatedAt
      privilege {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
      privilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
    }
  }
`;
export const updatePrivilegePrivilegeGroup = /* GraphQL */ `
  mutation UpdatePrivilegePrivilegeGroup(
    $input: UpdatePrivilegePrivilegeGroupInput!
    $condition: ModelPrivilegePrivilegeGroupConditionInput
  ) {
    updatePrivilegePrivilegeGroup(input: $input, condition: $condition) {
      id
      privilegeId
      privilegeGroupId
      createdAt
      updatedAt
      privilege {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
      privilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
    }
  }
`;
export const deletePrivilegePrivilegeGroup = /* GraphQL */ `
  mutation DeletePrivilegePrivilegeGroup(
    $input: DeletePrivilegePrivilegeGroupInput!
    $condition: ModelPrivilegePrivilegeGroupConditionInput
  ) {
    deletePrivilegePrivilegeGroup(input: $input, condition: $condition) {
      id
      privilegeId
      privilegeGroupId
      createdAt
      updatedAt
      privilege {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
      privilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
    }
  }
`;
export const createPrivilegeGroup = /* GraphQL */ `
  mutation CreatePrivilegeGroup(
    $input: CreatePrivilegeGroupInput!
    $condition: ModelPrivilegeGroupConditionInput
  ) {
    createPrivilegeGroup(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      privileges {
        items {
          id
          privilegeId
          privilegeGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePrivilegeGroup = /* GraphQL */ `
  mutation UpdatePrivilegeGroup(
    $input: UpdatePrivilegeGroupInput!
    $condition: ModelPrivilegeGroupConditionInput
  ) {
    updatePrivilegeGroup(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      privileges {
        items {
          id
          privilegeId
          privilegeGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePrivilegeGroup = /* GraphQL */ `
  mutation DeletePrivilegeGroup(
    $input: DeletePrivilegeGroupInput!
    $condition: ModelPrivilegeGroupConditionInput
  ) {
    deletePrivilegeGroup(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      privileges {
        items {
          id
          privilegeId
          privilegeGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTenantUser = /* GraphQL */ `
  mutation UpdateTenantUser(
    $input: UpdateTenantUserInput!
    $condition: ModelTenantUserConditionInput
  ) {
    updateTenantUser(input: $input, condition: $condition) {
      id
      userId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const deleteTenantUser = /* GraphQL */ `
  mutation DeleteTenantUser(
    $input: DeleteTenantUserInput!
    $condition: ModelTenantUserConditionInput
  ) {
    deleteTenantUser(input: $input, condition: $condition) {
      id
      userId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const updateUserPrivilege = /* GraphQL */ `
  mutation UpdateUserPrivilege(
    $input: UpdateUserPrivilegeInput!
    $condition: ModelUserPrivilegeConditionInput
  ) {
    updateUserPrivilege(input: $input, condition: $condition) {
      id
      userId
      privilegeId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      privilege {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserPrivilege = /* GraphQL */ `
  mutation DeleteUserPrivilege(
    $input: DeleteUserPrivilegeInput!
    $condition: ModelUserPrivilegeConditionInput
  ) {
    deleteUserPrivilege(input: $input, condition: $condition) {
      id
      userId
      privilegeId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      privilege {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
    }
  }
`;
export const deleteRetailStoreLicense = /* GraphQL */ `
  mutation DeleteRetailStoreLicense(
    $input: DeleteRetailStoreLicenseInput!
    $condition: ModelRetailStoreLicenseConditionInput
  ) {
    deleteRetailStoreLicense(input: $input, condition: $condition) {
      id
      retailStoreId
      licenseId
      partnerId
      tenantId
      validFrom
      validTo
      assignedToUser
      activatedAt
      number
      electronicCashRegisterId
      moitoIotCode
      moitoIotId
      level
      isFree
      autorenew
      orderConfirmationNotified
      firmware
      ecrCompanyName
      ecrCompanyVat
      ecrRetailStoreName
      adminNotified
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      approvedBy {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      renewedRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteParty = /* GraphQL */ `
  mutation DeleteParty(
    $input: DeletePartyInput!
    $condition: ModelPartyConditionInput
  ) {
    deleteParty(input: $input, condition: $condition) {
      id
      firstName
      lastName
      companyName
      companyVat
      fiscalCode
      email
      logo
      mobile
      zipCode
      county
      municipality
      address
      country
      role
      recipientCode
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      tenantId
      description
      createdByModule
      deleted
      companyName
      companyVat
      firstName
      lastName
      mobile
      visible
      fiscalCode
      email
      zipCode
      county
      municipality
      address
      country
      recipientCode
      extensions
      latitude
      longitude
      addressPlaceId
      loyaltyPoints
      addressNotes
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      vatRate {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      autoDiscount {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      prepaidCard {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      pointsCard {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSalesOrder = /* GraphQL */ `
  mutation DeleteSalesOrder(
    $input: DeleteSalesOrderInput!
    $condition: ModelSalesOrderConditionInput
  ) {
    deleteSalesOrder(input: $input, condition: $condition) {
      id
      tenantId
      customerId
      retailStoreId
      notes
      extensions
      createdByModule
      deleted
      atorderSessionId
      atorderStatus
      atorderType
      atorderOldDatetime
      datetime
      lastCustomerUpdate
      customerCreationDatetime
      amount
      paymentType
      stripePaymentIntent
      stripePaymentStatus
      stripeRefund
      orderNumber
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      salesOrderLines {
        items {
          id
          tenantId
          salesOrderId
          itemId
          price
          quantity
          notes
          extensions
          sorting
          totalAmount
          vatRate
          modifiedByModule
          createdAt
          updatedAt
        }
        nextToken
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreLocation {
        id
        tenantId
        retailStoreRoomId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      images
      atorderType
      sorting
      vatRate
      vatCode
      extensions
      createdByModule
      deleted
      qtyOnHand
      qtyOnHandByStoreId
      enabledRetailStoreIds
      loyaltyPoints
      time
      image
      externalId
      atorderHide
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      itemGroup {
        items {
          id
          tenantId
          itemId
          itemGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      priceList {
        items {
          id
          tenantId
          itemId
          priceListId
          price
          deleted
          extensions
          createdAt
          updatedAt
        }
        nextToken
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      variantGroups {
        items {
          id
          tenantId
          variantGroupId
          itemId
          createdAt
          updatedAt
        }
        nextToken
      }
      logicalPrinters {
        items {
          id
          tenantId
          retailStoreId
          itemId
          logicalPrinterId
          isMain
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteItemGroup = /* GraphQL */ `
  mutation DeleteItemGroup(
    $input: DeleteItemGroupInput!
    $condition: ModelItemGroupConditionInput
  ) {
    deleteItemGroup(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      sorting
      extensions
      createdByModule
      atorderType
      deleted
      enabledRetailStoreIds
      color
      isCover
      type
      externalId
      atorderHide
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        items {
          id
          tenantId
          itemId
          itemGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteItemPriceList = /* GraphQL */ `
  mutation DeleteItemPriceList(
    $input: DeleteItemPriceListInput!
    $condition: ModelItemPriceListConditionInput
  ) {
    deleteItemPriceList(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      priceListId
      currency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      price
      deleted
      extensions
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePriceList = /* GraphQL */ `
  mutation DeletePriceList(
    $input: DeletePriceListInput!
    $condition: ModelPriceListConditionInput
  ) {
    deletePriceList(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      extensions
      createdByModule
      sorting
      deleted
      visible
      enabled
      enowebId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        items {
          id
          tenantId
          itemId
          priceListId
          price
          deleted
          extensions
          createdAt
          updatedAt
        }
        nextToken
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteRetailStore = /* GraphQL */ `
  mutation DeleteRetailStore(
    $input: DeleteRetailStoreInput!
    $condition: ModelRetailStoreConditionInput
  ) {
    deleteRetailStore(input: $input, condition: $condition) {
      id
      tenantId
      name
      extensions
      deleted
      partnerId
      zipCode
      county
      municipality
      address
      country
      latitude
      longitude
      accountantId
      isSharedTenant
      iban
      enowebMemId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStoreLicense {
        items {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        nextToken
      }
      party {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
      user {
        items {
          id
          userId
          retailStoreId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      invoiceSectional {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      cashRegisters {
        items {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteRetailStoreRoom = /* GraphQL */ `
  mutation DeleteRetailStoreRoom(
    $input: DeleteRetailStoreRoomInput!
    $condition: ModelRetailStoreRoomConditionInput
  ) {
    deleteRetailStoreRoom(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      coverItem {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      logicalPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteRetailStoreLocation = /* GraphQL */ `
  mutation DeleteRetailStoreLocation(
    $input: DeleteRetailStoreLocationInput!
    $condition: ModelRetailStoreLocationConditionInput
  ) {
    deleteRetailStoreLocation(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreRoomId
      retailStoreId
      name
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreRoom {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateUserRetailStore = /* GraphQL */ `
  mutation UpdateUserRetailStore(
    $input: UpdateUserRetailStoreInput!
    $condition: ModelUserRetailStoreConditionInput
  ) {
    updateUserRetailStore(input: $input, condition: $condition) {
      id
      userId
      retailStoreId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserRetailStore = /* GraphQL */ `
  mutation DeleteUserRetailStore(
    $input: DeleteUserRetailStoreInput!
    $condition: ModelUserRetailStoreConditionInput
  ) {
    deleteUserRetailStore(input: $input, condition: $condition) {
      id
      userId
      retailStoreId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      tenantId
      userId
      type
      status
      context
      text
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      news {
        id
        title
        description
        notified
        type
        attribute
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteInvoiceSectional = /* GraphQL */ `
  mutation DeleteInvoiceSectional(
    $input: DeleteInvoiceSectionalInput!
    $condition: ModelInvoiceSectionalConditionInput
  ) {
    deleteInvoiceSectional(input: $input, condition: $condition) {
      id
      tenantId
      partnerId
      code
      description
      deleted
      nextNumber
      retailStoreId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteSalesInvoice = /* GraphQL */ `
  mutation DeleteSalesInvoice(
    $input: DeleteSalesInvoiceInput!
    $condition: ModelSalesInvoiceConditionInput
  ) {
    deleteSalesInvoice(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      customerId
      number
      invoiceId
      date
      type
      taxRegime
      paymentCode
      paymentMode
      paymentAmount
      iban
      notes
      customerFiscalCode
      customerCompanyVat
      customerCompanyName
      customerZipCode
      customerCounty
      customerMunicipality
      customerAddress
      customerCountry
      customerRecipientCode
      customerEmail
      extensions
      isPAInvoice
      sentToArchismall
      pricesWithoutVat
      createdByModule
      xmlS3Key
      pdfS3Key
      archismallProgressiveId
      status
      idSdi
      idArchismall
      errorDescription
      emailNotificationSent
      writeOffInvoiceId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      sectional {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      salesInvoiceLines {
        items {
          id
          tenantId
          salesInvoiceId
          vatRate
          vatCode
          vatCodeDescription
          lineNum
          price
          quantity
          unitPrice
          taxableAmount
          vatAmount
          discountType
          discountAmount
          extensions
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteEInvoiceTransmission = /* GraphQL */ `
  mutation DeleteEInvoiceTransmission(
    $input: DeleteEInvoiceTransmissionInput!
    $condition: ModelEInvoiceTransmissionConditionInput
  ) {
    deleteEInvoiceTransmission(input: $input, condition: $condition) {
      id
      tenantId
      salesInvoiceId
      retailStoreId
      datetime
      status
      details
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      salesInvoice {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
    }
  }
`;
export const deleteElectronicCashRegister = /* GraphQL */ `
  mutation DeleteElectronicCashRegister(
    $input: DeleteElectronicCashRegisterInput!
    $condition: ModelElectronicCashRegisterConditionInput
  ) {
    deleteElectronicCashRegister(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      partnerId
      name
      number
      description
      ejNumber
      ejInitDay
      ejMissingDays
      ejDueDate
      ejType
      lastZNumber
      fwVersion
      enabled
      deleted
      extensions
      rtUtilityInactive
      lastSyncDatetime
      type
      ecrModel
      version
      tags
      advMessage
      lastZErrorIds
      imageS3Key
      pendingEJExtension
      exportPendingXML
      accountantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      lastPVIntervention {
        id
        partnerId
        tenantId
        retailStoreId
        number
        fiscalCode
        vatNumber
        datetime
        code
        note
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteECRDailySales = /* GraphQL */ `
  mutation DeleteECRDailySales(
    $input: DeleteECRDailySalesInput!
    $condition: ModelECRDailySalesConditionInput
  ) {
    deleteECRDailySales(input: $input, condition: $condition) {
      id
      tenantId
      electronicCashRegisterId
      number
      datetime
      content
      notified
      accountantId
      createdAt
      updatedAt
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteECRDailyTech = /* GraphQL */ `
  mutation DeleteECRDailyTech(
    $input: DeleteECRDailyTechInput!
    $condition: ModelECRDailyTechConditionInput
  ) {
    deleteECRDailyTech(input: $input, condition: $condition) {
      id
      number
      datetime
      content
      rtUtilityProcessed
      createdAt
      updatedAt
    }
  }
`;
export const createECRConfigurationFavorite = /* GraphQL */ `
  mutation CreateECRConfigurationFavorite(
    $input: CreateECRConfigurationFavoriteInput!
    $condition: ModelECRConfigurationFavoriteConditionInput
  ) {
    createECRConfigurationFavorite(input: $input, condition: $condition) {
      id
      name
      partnerId
      plu
      content
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateECRConfigurationFavorite = /* GraphQL */ `
  mutation UpdateECRConfigurationFavorite(
    $input: UpdateECRConfigurationFavoriteInput!
    $condition: ModelECRConfigurationFavoriteConditionInput
  ) {
    updateECRConfigurationFavorite(input: $input, condition: $condition) {
      id
      name
      partnerId
      plu
      content
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteECRConfigurationFavorite = /* GraphQL */ `
  mutation DeleteECRConfigurationFavorite(
    $input: DeleteECRConfigurationFavoriteInput!
    $condition: ModelECRConfigurationFavoriteConditionInput
  ) {
    deleteECRConfigurationFavorite(input: $input, condition: $condition) {
      id
      name
      partnerId
      plu
      content
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePartnerNote = /* GraphQL */ `
  mutation UpdatePartnerNote(
    $input: UpdatePartnerNoteInput!
    $condition: ModelPartnerNoteConditionInput
  ) {
    updatePartnerNote(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      ecrNumber
      companyName
      retailStoreName
      description
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePartnerNote = /* GraphQL */ `
  mutation DeletePartnerNote(
    $input: DeletePartnerNoteInput!
    $condition: ModelPartnerNoteConditionInput
  ) {
    deletePartnerNote(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      ecrNumber
      companyName
      retailStoreName
      description
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createFirmware = /* GraphQL */ `
  mutation CreateFirmware(
    $input: CreateFirmwareInput!
    $condition: ModelFirmwareConditionInput
  ) {
    createFirmware(input: $input, condition: $condition) {
      id
      version
      notes
      DCashFilename
      DCashPlusFilename
      DPalmFilename
      DFlexFilename
      BlancaFilename
      DFrontFilename
      DTouch10Filename
      downloadable
      billable
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteFirmware = /* GraphQL */ `
  mutation DeleteFirmware(
    $input: DeleteFirmwareInput!
    $condition: ModelFirmwareConditionInput
  ) {
    deleteFirmware(input: $input, condition: $condition) {
      id
      version
      notes
      DCashFilename
      DCashPlusFilename
      DPalmFilename
      DFlexFilename
      BlancaFilename
      DFrontFilename
      DTouch10Filename
      downloadable
      billable
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteFirmwareUpgradeHeader = /* GraphQL */ `
  mutation DeleteFirmwareUpgradeHeader(
    $input: DeleteFirmwareUpgradeHeaderInput!
    $condition: ModelFirmwareUpgradeHeaderConditionInput
  ) {
    deleteFirmwareUpgradeHeader(input: $input, condition: $condition) {
      id
      tenantId
      firmwareId
      isServiceManagerIntervention
      fiscalCode
      datetime
      vatNumber
      cancelled
      linesCount
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      firmware {
        id
        version
        notes
        DCashFilename
        DCashPlusFilename
        DPalmFilename
        DFlexFilename
        BlancaFilename
        DFrontFilename
        DTouch10Filename
        downloadable
        billable
        deleted
        createdAt
        updatedAt
      }
      lines {
        items {
          id
          tenantId
          number
          installed
          cancelled
          headerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCertificate = /* GraphQL */ `
  mutation CreateCertificate(
    $input: CreateCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    createCertificate(input: $input, condition: $condition) {
      id
      name
      type
      notes
      filename
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const updateCertificate = /* GraphQL */ `
  mutation UpdateCertificate(
    $input: UpdateCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    updateCertificate(input: $input, condition: $condition) {
      id
      name
      type
      notes
      filename
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteCertificate = /* GraphQL */ `
  mutation DeleteCertificate(
    $input: DeleteCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    deleteCertificate(input: $input, condition: $condition) {
      id
      name
      type
      notes
      filename
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const updateRtUtilityLog = /* GraphQL */ `
  mutation UpdateRtUtilityLog(
    $input: UpdateRtUtilityLogInput!
    $condition: ModelRtUtilityLogConditionInput
  ) {
    updateRtUtilityLog(input: $input, condition: $condition) {
      id
      partnerId
      userId
      tenantId
      tenantCompanyName
      tenantCompanyVat
      userAttribute
      number
      description
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const deleteRtUtilityLog = /* GraphQL */ `
  mutation DeleteRtUtilityLog(
    $input: DeleteRtUtilityLogInput!
    $condition: ModelRtUtilityLogConditionInput
  ) {
    deleteRtUtilityLog(input: $input, condition: $condition) {
      id
      partnerId
      userId
      tenantId
      tenantCompanyName
      tenantCompanyVat
      userAttribute
      number
      description
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const createCommunication = /* GraphQL */ `
  mutation CreateCommunication(
    $input: CreateCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    createCommunication(input: $input, condition: $condition) {
      id
      title
      description
      notified
      type
      attribute
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommunication = /* GraphQL */ `
  mutation DeleteCommunication(
    $input: DeleteCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    deleteCommunication(input: $input, condition: $condition) {
      id
      title
      description
      notified
      type
      attribute
      createdAt
      updatedAt
    }
  }
`;
export const deleteFirmwareStat = /* GraphQL */ `
  mutation DeleteFirmwareStat(
    $input: DeleteFirmwareStatInput!
    $condition: ModelFirmwareStatConditionInput
  ) {
    deleteFirmwareStat(input: $input, condition: $condition) {
      id
      version
      numbers
      createdAt
      updatedAt
    }
  }
`;
export const deleteMoitoIot = /* GraphQL */ `
  mutation DeleteMoitoIot(
    $input: DeleteMoitoIotInput!
    $condition: ModelMoitoIotConditionInput
  ) {
    deleteMoitoIot(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      partnerId
      name
      code
      version
      androidApiLevel
      isBetaInstallation
      lastSyncDatetime
      lastBackupS3Key
      lastBackupDatetime
      usersCount
      kiosksCount
      deleted
      moitoIotInactive
      tags
      firstSyncDone
      extensions
      isFree
      lastAssistanceUpload
      ecrNumber
      isSyncDisabled
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      fixedVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createMoitoIotAppVersion = /* GraphQL */ `
  mutation CreateMoitoIotAppVersion(
    $input: CreateMoitoIotAppVersionInput!
    $condition: ModelMoitoIotAppVersionConditionInput
  ) {
    createMoitoIotAppVersion(input: $input, condition: $condition) {
      id
      adminId
      version
      frontendZipPath
      apkPath
      apk21Path
      datetime
      notes
      isStable
      deleted
      recordType
      createdAt
      updatedAt
      fromVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      toVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteMoitoIotAppVersion = /* GraphQL */ `
  mutation DeleteMoitoIotAppVersion(
    $input: DeleteMoitoIotAppVersionInput!
    $condition: ModelMoitoIotAppVersionConditionInput
  ) {
    deleteMoitoIotAppVersion(input: $input, condition: $condition) {
      id
      adminId
      version
      frontendZipPath
      apkPath
      apk21Path
      datetime
      notes
      isStable
      deleted
      recordType
      createdAt
      updatedAt
      fromVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      toVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePurchInvoice = /* GraphQL */ `
  mutation DeletePurchInvoice(
    $input: DeletePurchInvoiceInput!
    $condition: ModelPurchInvoiceConditionInput
  ) {
    deletePurchInvoice(input: $input, condition: $condition) {
      id
      tenantId
      number
      date
      idSdi
      vendorId
      filename
      progressiveId
      type
      xmlS3Key
      pdfS3Key
      taxableAmount
      vatAmount
      paymentDueDate
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      vendor {
        id
        tenantId
        deleted
        companyName
        companyVat
        fiscalCode
        zipCode
        county
        municipality
        address
        country
        recipientCode
        email
        mobile
        extensions
        costCenterId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        costCenter {
          id
          tenantId
          name
          description
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePublishedMoitoIotMessage = /* GraphQL */ `
  mutation DeletePublishedMoitoIotMessage(
    $input: DeletePublishedMoitoIotMessageInput!
    $condition: ModelPublishedMoitoIotMessageConditionInput
  ) {
    deletePublishedMoitoIotMessage(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const deleteReceivedMoitoIotMessage = /* GraphQL */ `
  mutation DeleteReceivedMoitoIotMessage(
    $input: DeleteReceivedMoitoIotMessageInput!
    $condition: ModelReceivedMoitoIotMessageConditionInput
  ) {
    deleteReceivedMoitoIotMessage(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const deleteVatRate = /* GraphQL */ `
  mutation DeleteVatRate(
    $input: DeleteVatRateInput!
    $condition: ModelVatRateConditionInput
  ) {
    deleteVatRate(input: $input, condition: $condition) {
      id
      tenantId
      code
      description
      sorting
      rate
      extensions
      isAdded
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      id
      tenantId
      color
      description
      forFree
      sorting
      enabled
      activityCode
      extensions
      createdByModule
      deleted
      rtDepartment
      expenseType
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      vatRate {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteVariant = /* GraphQL */ `
  mutation DeleteVariant(
    $input: DeleteVariantInput!
    $condition: ModelVariantConditionInput
  ) {
    deleteVariant(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variantVariantGroups {
        items {
          id
          tenantId
          variantId
          variantGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      variantModifiers {
        items {
          id
          tenantId
          variantId
          modifierId
          price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteModifier = /* GraphQL */ `
  mutation DeleteModifier(
    $input: DeleteModifierInput!
    $condition: ModelModifierConditionInput
  ) {
    deleteModifier(input: $input, condition: $condition) {
      id
      tenantId
      description
      color
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteVariantGroup = /* GraphQL */ `
  mutation DeleteVariantGroup(
    $input: DeleteVariantGroupInput!
    $condition: ModelVariantGroupConditionInput
  ) {
    deleteVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      enabled
      color
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteMoitoIotOperator = /* GraphQL */ `
  mutation DeleteMoitoIotOperator(
    $input: DeleteMoitoIotOperatorInput!
    $condition: ModelMoitoIotOperatorConditionInput
  ) {
    deleteMoitoIotOperator(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      description
      username
      password
      deleted
      sorting
      icon
      color
      enabled
      isAdmin
      blockPayments
      printWorkshift
      openDrawer
      incoming
      withdrawals
      insertPrice
      voids
      refunds
      discounts
      cancellation
      changeVat
      vatImmune
      preAccounts
      zreport
      updateWarehouse
      insertItems
      takeDesk
      forceTakeDesk
      journal
      finalPreAccounts
      testMode
      frontendConfigurations
      type
      showSettings
      showEditingMode
      showDtrs
      showInvoiceOutcome
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreRoom {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      orderPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      receiptPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      invoicePrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      preorderPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      tallonPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      reportPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      kioskPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteDiscountSurcharge = /* GraphQL */ `
  mutation DeleteDiscountSurcharge(
    $input: DeleteDiscountSurchargeInput!
    $condition: ModelDiscountSurchargeConditionInput
  ) {
    deleteDiscountSurcharge(input: $input, condition: $condition) {
      id
      tenantId
      description
      value
      percValue
      discountSurcharge
      itemSubTotal
      enabled
      sorting
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePhase = /* GraphQL */ `
  mutation DeletePhase(
    $input: DeletePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    deletePhase(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      enabled
      code
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteMoitoIotPhysicalPrinter = /* GraphQL */ `
  mutation DeleteMoitoIotPhysicalPrinter(
    $input: DeleteMoitoIotPhysicalPrinterInput!
    $condition: ModelMoitoIotPhysicalPrinterConditionInput
  ) {
    deleteMoitoIotPhysicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      type
      driver
      name
      description
      model
      version
      number
      com
      baud
      ip
      port
      rowLength
      isTest
      screenSize
      cashBalance
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteMoitoIotLogicalPrinter = /* GraphQL */ `
  mutation DeleteMoitoIotLogicalPrinter(
    $input: DeleteMoitoIotLogicalPrinterInput!
    $condition: ModelMoitoIotLogicalPrinterConditionInput
  ) {
    deleteMoitoIotLogicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      physicalPrinterId
      name
      description
      printLogoOrder
      beepAfterOrder
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      physicalPrinter {
        id
        tenantId
        retailStoreId
        type
        driver
        name
        description
        model
        version
        number
        com
        baud
        ip
        port
        rowLength
        isTest
        screenSize
        cashBalance
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReceipt = /* GraphQL */ `
  mutation DeleteReceipt(
    $input: DeleteReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    deleteReceipt(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      moitoIotId
      moitoIotNumber
      ecrNumber
      moitoIotDatetime
      zMoitoIot
      zEcr
      ecrDatetime
      number
      amount
      percDiscount
      valDiscount
      discountDescription
      customerId
      posted
      type
      description
      extensions
      deleted
      netAmount
      taxAmount
      payedAmount
      changeAmount
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      receiptPayments {
        items {
          id
          tenantId
          receiptId
          paymentId
          retailStoreId
          amount
          sorting
          description
          loyaltyCardId
          loyaltyCardInitialAmount
          loyaltyCardFinalAmount
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      lines {
        items {
          id
          tenantId
          retailStoreId
          receiptId
          departmentId
          sorting
          description
          percDiscount
          valDiscount
          discountDescription
          comment
          price
          quantity
          total
          loyaltyPoints
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      tenantId
      sorting
      type
      name
      color
      enabled
      presetAmount
      automaticDiscount
      allowLeftovers
      allowIncoming
      allowWithdrawals
      allowComment
      allowZero
      automaticOpening
      willFollowInvoice
      isLoyaltyCard
      extensions
      deleted
      isBankTransfer
      isTracked
      eftAmount
      cashDrawer
      adminOnly
      satispay
      satispayMode
      paymentDtrId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReceiptPayment = /* GraphQL */ `
  mutation DeleteReceiptPayment(
    $input: DeleteReceiptPaymentInput!
    $condition: ModelReceiptPaymentConditionInput
  ) {
    deleteReceiptPayment(input: $input, condition: $condition) {
      id
      tenantId
      receiptId
      paymentId
      retailStoreId
      amount
      sorting
      description
      loyaltyCardId
      loyaltyCardInitialAmount
      loyaltyCardFinalAmount
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      payment {
        id
        tenantId
        sorting
        type
        name
        color
        enabled
        presetAmount
        automaticDiscount
        allowLeftovers
        allowIncoming
        allowWithdrawals
        allowComment
        allowZero
        automaticOpening
        willFollowInvoice
        isLoyaltyCard
        extensions
        deleted
        isBankTransfer
        isTracked
        eftAmount
        cashDrawer
        adminOnly
        satispay
        satispayMode
        paymentDtrId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReceiptLine = /* GraphQL */ `
  mutation DeleteReceiptLine(
    $input: DeleteReceiptLineInput!
    $condition: ModelReceiptLineConditionInput
  ) {
    deleteReceiptLine(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      departmentId
      sorting
      description
      percDiscount
      valDiscount
      discountDescription
      comment
      price
      quantity
      total
      loyaltyPoints
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReceiptVat = /* GraphQL */ `
  mutation DeleteReceiptVat(
    $input: DeleteReceiptVatInput!
    $condition: ModelReceiptVatConditionInput
  ) {
    deleteReceiptVat(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      sorting
      vatCode
      vatRate
      totalAmount
      netAmount
      vatAmount
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReceiptLineVariant = /* GraphQL */ `
  mutation DeleteReceiptLineVariant(
    $input: DeleteReceiptLineVariantInput!
    $condition: ModelReceiptLineVariantConditionInput
  ) {
    deleteReceiptLineVariant(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      receiptLineId
      variantId
      modifierId
      modifierDescription
      modifierRightPosition
      modifierColor
      variantDescription
      price
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      receiptLine {
        id
        tenantId
        retailStoreId
        receiptId
        departmentId
        sorting
        description
        percDiscount
        valDiscount
        discountDescription
        comment
        price
        quantity
        total
        loyaltyPoints
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteECRPendingXML = /* GraphQL */ `
  mutation DeleteECRPendingXML(
    $input: DeleteECRPendingXMLInput!
    $condition: ModelECRPendingXMLConditionInput
  ) {
    deleteECRPendingXML(input: $input, condition: $condition) {
      id
      electronicCashRegisterId
      partnerId
      tenantId
      number
      filename
      datetime
      s3Key
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteECRAdvMessage = /* GraphQL */ `
  mutation DeleteECRAdvMessage(
    $input: DeleteECRAdvMessageInput!
    $condition: ModelECRAdvMessageConditionInput
  ) {
    deleteECRAdvMessage(input: $input, condition: $condition) {
      id
      electronicCashRegisterId
      partnerId
      message
      printed
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteLoyaltyCard = /* GraphQL */ `
  mutation DeleteLoyaltyCard(
    $input: DeleteLoyaltyCardInput!
    $condition: ModelLoyaltyCardConditionInput
  ) {
    deleteLoyaltyCard(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      customerId
      s3Key
      initialAmount
      remainingAmount
      email
      type
      percDiscount
      datetime
      dueDate
      revoked
      description
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteOperator = /* GraphQL */ `
  mutation DeleteOperator(
    $input: DeleteOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    deleteOperator(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      description
      name
      deleted
      sorting
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      id
      tenantId
      deleted
      companyName
      companyVat
      fiscalCode
      zipCode
      county
      municipality
      address
      country
      recipientCode
      email
      mobile
      extensions
      costCenterId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      costCenter {
        id
        tenantId
        name
        description
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteCostCenter = /* GraphQL */ `
  mutation DeleteCostCenter(
    $input: DeleteCostCenterInput!
    $condition: ModelCostCenterConditionInput
  ) {
    deleteCostCenter(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      sorting
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      enabled
      logo
      currency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      shortCode
      isPartner
      partnershipStartDate
      isServiceManager
      nextItemSorting
      nextItemGroupSorting
      defaultPartnerCode
      companyName
      companyVat
      firstUserId
      firstRetailStoreId
      userCount
      moitoIotCount
      retailStoreCount
      cashRegisterCount
      rtUtilityInactive
      currentRtUtilityCategory
      partnerText
      partnerTosAccepted
      partnerTosAcceptedVersion
      privacyPolicyAcceptedDate
      archismallUsername
      archismallPassword
      archismallActiveInvoicesLastDatetime
      archismallPassiveInvoicesLastDatetime
      isBetaTester
      partnerCashRegisterCounter
      partnerMoitoIotCounter
      extensions
      mobile
      email
      zipCode
      county
      municipality
      address
      country
      isAccountant
      accountantId
      stripeAccountId
      stripeRegistrationCompleted
      blockLevel
      dailyReportMode
      createdAt
      updatedAt
      tenantUser {
        items {
          id
          userId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      party {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      enabled
      logo
      currency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      shortCode
      isPartner
      partnershipStartDate
      isServiceManager
      nextItemSorting
      nextItemGroupSorting
      defaultPartnerCode
      companyName
      companyVat
      firstUserId
      firstRetailStoreId
      userCount
      moitoIotCount
      retailStoreCount
      cashRegisterCount
      rtUtilityInactive
      currentRtUtilityCategory
      partnerText
      partnerTosAccepted
      partnerTosAcceptedVersion
      privacyPolicyAcceptedDate
      archismallUsername
      archismallPassword
      archismallActiveInvoicesLastDatetime
      archismallPassiveInvoicesLastDatetime
      isBetaTester
      partnerCashRegisterCounter
      partnerMoitoIotCounter
      extensions
      mobile
      email
      zipCode
      county
      municipality
      address
      country
      isAccountant
      accountantId
      stripeAccountId
      stripeRegistrationCompleted
      blockLevel
      dailyReportMode
      createdAt
      updatedAt
      tenantUser {
        items {
          id
          userId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      party {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      partyId
      enabled
      tenantDefault
      retailStoreDefault
      showTutorial
      email
      mobile
      pushNotificationTokens {
        service
        deviceToken
      }
      deleted
      firstName
      lastName
      createdAt
      updatedAt
      tenantUser {
        items {
          id
          userId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      userPrivilege {
        items {
          id
          userId
          privilegeId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      userLicense {
        items {
          id
          tenantId
          userId
          licenseId
          validFrom
          validTo
          createdAt
          updatedAt
        }
        nextToken
      }
      userRetailStore {
        items {
          id
          userId
          retailStoreId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      partyId
      enabled
      tenantDefault
      retailStoreDefault
      showTutorial
      email
      mobile
      pushNotificationTokens {
        service
        deviceToken
      }
      deleted
      firstName
      lastName
      createdAt
      updatedAt
      tenantUser {
        items {
          id
          userId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      userPrivilege {
        items {
          id
          userId
          privilegeId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      userLicense {
        items {
          id
          tenantId
          userId
          licenseId
          validFrom
          validTo
          createdAt
          updatedAt
        }
        nextToken
      }
      userRetailStore {
        items {
          id
          userId
          retailStoreId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPartnershipRequest = /* GraphQL */ `
  mutation CreatePartnershipRequest(
    $input: CreatePartnershipRequestInput!
    $condition: ModelPartnershipRequestConditionInput
  ) {
    createPartnershipRequest(input: $input, condition: $condition) {
      id
      tenantId
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const deletePartnershipRequest = /* GraphQL */ `
  mutation DeletePartnershipRequest(
    $input: DeletePartnershipRequestInput!
    $condition: ModelPartnershipRequestConditionInput
  ) {
    deletePartnershipRequest(input: $input, condition: $condition) {
      id
      tenantId
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const updateAppSettings = /* GraphQL */ `
  mutation UpdateAppSettings(
    $input: UpdateAppSettingsInput!
    $condition: ModelAppSettingsConditionInput
  ) {
    updateAppSettings(input: $input, condition: $condition) {
      id
      firstTenantUserPrivilegeGroups
      defaultCurrency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      oldEJExpDate
      lastTobaccoItemsS3Key
      lastTobaccoBarcodesS3Key
      licensesByDealerS3Key
      createdAt
      updatedAt
      defaultPartner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      AtorderLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      AtorderDeliveryLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EInvoiceItLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityFwDownloadLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityLogsLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserCollectorLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotAutomaticUpdateLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotMultipleUsersLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotOnlineBackupLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotCollectorLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotDealerLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotCateringLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityEJExtensionLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      CalendarLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      ForValueLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserAccountantLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJManagementLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJOneYearLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJTwoYearLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJNoLimitLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      OpticalLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotKioskLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotTobaccoLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      partnerPrivilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      serviceManagerPrivilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      lastMoitoIotStableVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      lastMoitoIotBetaVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createLicenseRequest = /* GraphQL */ `
  mutation CreateLicenseRequest(
    $input: CreateLicenseRequestInput!
    $condition: ModelLicenseRequestConditionInput
  ) {
    createLicenseRequest(input: $input, condition: $condition) {
      id
      tenantId
      partnerId
      retailStoreId
      duration
      deleted
      extensions
      startFromDate
      level
      isFree
      autorenew
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      applicantUser {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteLicenseRequest = /* GraphQL */ `
  mutation DeleteLicenseRequest(
    $input: DeleteLicenseRequestInput!
    $condition: ModelLicenseRequestConditionInput
  ) {
    deleteLicenseRequest(input: $input, condition: $condition) {
      id
      tenantId
      partnerId
      retailStoreId
      duration
      deleted
      extensions
      startFromDate
      level
      isFree
      autorenew
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      applicantUser {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createTenantUser = /* GraphQL */ `
  mutation CreateTenantUser(
    $input: CreateTenantUserInput!
    $condition: ModelTenantUserConditionInput
  ) {
    createTenantUser(input: $input, condition: $condition) {
      id
      userId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const createUserPrivilege = /* GraphQL */ `
  mutation CreateUserPrivilege(
    $input: CreateUserPrivilegeInput!
    $condition: ModelUserPrivilegeConditionInput
  ) {
    createUserPrivilege(input: $input, condition: $condition) {
      id
      userId
      privilegeId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      privilege {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
    }
  }
`;
export const createUserLicense = /* GraphQL */ `
  mutation CreateUserLicense(
    $input: CreateUserLicenseInput!
    $condition: ModelUserLicenseConditionInput
  ) {
    createUserLicense(input: $input, condition: $condition) {
      id
      tenantId
      userId
      licenseId
      validFrom
      validTo
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      retailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateUserLicense = /* GraphQL */ `
  mutation UpdateUserLicense(
    $input: UpdateUserLicenseInput!
    $condition: ModelUserLicenseConditionInput
  ) {
    updateUserLicense(input: $input, condition: $condition) {
      id
      tenantId
      userId
      licenseId
      validFrom
      validTo
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      retailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteUserLicense = /* GraphQL */ `
  mutation DeleteUserLicense(
    $input: DeleteUserLicenseInput!
    $condition: ModelUserLicenseConditionInput
  ) {
    deleteUserLicense(input: $input, condition: $condition) {
      id
      tenantId
      userId
      licenseId
      validFrom
      validTo
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      retailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createRetailStoreLicense = /* GraphQL */ `
  mutation CreateRetailStoreLicense(
    $input: CreateRetailStoreLicenseInput!
    $condition: ModelRetailStoreLicenseConditionInput
  ) {
    createRetailStoreLicense(input: $input, condition: $condition) {
      id
      retailStoreId
      licenseId
      partnerId
      tenantId
      validFrom
      validTo
      assignedToUser
      activatedAt
      number
      electronicCashRegisterId
      moitoIotCode
      moitoIotId
      level
      isFree
      autorenew
      orderConfirmationNotified
      firmware
      ecrCompanyName
      ecrCompanyVat
      ecrRetailStoreName
      adminNotified
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      approvedBy {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      renewedRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateRetailStoreLicense = /* GraphQL */ `
  mutation UpdateRetailStoreLicense(
    $input: UpdateRetailStoreLicenseInput!
    $condition: ModelRetailStoreLicenseConditionInput
  ) {
    updateRetailStoreLicense(input: $input, condition: $condition) {
      id
      retailStoreId
      licenseId
      partnerId
      tenantId
      validFrom
      validTo
      assignedToUser
      activatedAt
      number
      electronicCashRegisterId
      moitoIotCode
      moitoIotId
      level
      isFree
      autorenew
      orderConfirmationNotified
      firmware
      ecrCompanyName
      ecrCompanyVat
      ecrRetailStoreName
      adminNotified
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      approvedBy {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      renewedRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createParty = /* GraphQL */ `
  mutation CreateParty(
    $input: CreatePartyInput!
    $condition: ModelPartyConditionInput
  ) {
    createParty(input: $input, condition: $condition) {
      id
      firstName
      lastName
      companyName
      companyVat
      fiscalCode
      email
      logo
      mobile
      zipCode
      county
      municipality
      address
      country
      role
      recipientCode
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const updateParty = /* GraphQL */ `
  mutation UpdateParty(
    $input: UpdatePartyInput!
    $condition: ModelPartyConditionInput
  ) {
    updateParty(input: $input, condition: $condition) {
      id
      firstName
      lastName
      companyName
      companyVat
      fiscalCode
      email
      logo
      mobile
      zipCode
      county
      municipality
      address
      country
      role
      recipientCode
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      tenantId
      description
      createdByModule
      deleted
      companyName
      companyVat
      firstName
      lastName
      mobile
      visible
      fiscalCode
      email
      zipCode
      county
      municipality
      address
      country
      recipientCode
      extensions
      latitude
      longitude
      addressPlaceId
      loyaltyPoints
      addressNotes
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      vatRate {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      autoDiscount {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      prepaidCard {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      pointsCard {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      tenantId
      description
      createdByModule
      deleted
      companyName
      companyVat
      firstName
      lastName
      mobile
      visible
      fiscalCode
      email
      zipCode
      county
      municipality
      address
      country
      recipientCode
      extensions
      latitude
      longitude
      addressPlaceId
      loyaltyPoints
      addressNotes
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      vatRate {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      autoDiscount {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      prepaidCard {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      pointsCard {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createSalesOrder = /* GraphQL */ `
  mutation CreateSalesOrder(
    $input: CreateSalesOrderInput!
    $condition: ModelSalesOrderConditionInput
  ) {
    createSalesOrder(input: $input, condition: $condition) {
      id
      tenantId
      customerId
      retailStoreId
      notes
      extensions
      createdByModule
      deleted
      atorderSessionId
      atorderStatus
      atorderType
      atorderOldDatetime
      datetime
      lastCustomerUpdate
      customerCreationDatetime
      amount
      paymentType
      stripePaymentIntent
      stripePaymentStatus
      stripeRefund
      orderNumber
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      salesOrderLines {
        items {
          id
          tenantId
          salesOrderId
          itemId
          price
          quantity
          notes
          extensions
          sorting
          totalAmount
          vatRate
          modifiedByModule
          createdAt
          updatedAt
        }
        nextToken
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreLocation {
        id
        tenantId
        retailStoreRoomId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSalesOrder = /* GraphQL */ `
  mutation UpdateSalesOrder(
    $input: UpdateSalesOrderInput!
    $condition: ModelSalesOrderConditionInput
  ) {
    updateSalesOrder(input: $input, condition: $condition) {
      id
      tenantId
      customerId
      retailStoreId
      notes
      extensions
      createdByModule
      deleted
      atorderSessionId
      atorderStatus
      atorderType
      atorderOldDatetime
      datetime
      lastCustomerUpdate
      customerCreationDatetime
      amount
      paymentType
      stripePaymentIntent
      stripePaymentStatus
      stripeRefund
      orderNumber
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      salesOrderLines {
        items {
          id
          tenantId
          salesOrderId
          itemId
          price
          quantity
          notes
          extensions
          sorting
          totalAmount
          vatRate
          modifiedByModule
          createdAt
          updatedAt
        }
        nextToken
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreLocation {
        id
        tenantId
        retailStoreRoomId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createSalesOrderLine = /* GraphQL */ `
  mutation CreateSalesOrderLine(
    $input: CreateSalesOrderLineInput!
    $condition: ModelSalesOrderLineConditionInput
  ) {
    createSalesOrderLine(input: $input, condition: $condition) {
      id
      tenantId
      salesOrderId
      itemId
      price
      quantity
      notes
      extensions
      sorting
      totalAmount
      vatRate
      modifiedByModule
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      salesOrder {
        id
        tenantId
        customerId
        retailStoreId
        notes
        extensions
        createdByModule
        deleted
        atorderSessionId
        atorderStatus
        atorderType
        atorderOldDatetime
        datetime
        lastCustomerUpdate
        customerCreationDatetime
        amount
        paymentType
        stripePaymentIntent
        stripePaymentStatus
        stripeRefund
        orderNumber
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        salesOrderLines {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreLocation {
          id
          tenantId
          retailStoreRoomId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
    }
  }
`;
export const updateSalesOrderLine = /* GraphQL */ `
  mutation UpdateSalesOrderLine(
    $input: UpdateSalesOrderLineInput!
    $condition: ModelSalesOrderLineConditionInput
  ) {
    updateSalesOrderLine(input: $input, condition: $condition) {
      id
      tenantId
      salesOrderId
      itemId
      price
      quantity
      notes
      extensions
      sorting
      totalAmount
      vatRate
      modifiedByModule
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      salesOrder {
        id
        tenantId
        customerId
        retailStoreId
        notes
        extensions
        createdByModule
        deleted
        atorderSessionId
        atorderStatus
        atorderType
        atorderOldDatetime
        datetime
        lastCustomerUpdate
        customerCreationDatetime
        amount
        paymentType
        stripePaymentIntent
        stripePaymentStatus
        stripeRefund
        orderNumber
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        salesOrderLines {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreLocation {
          id
          tenantId
          retailStoreRoomId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
    }
  }
`;
export const deleteSalesOrderLine = /* GraphQL */ `
  mutation DeleteSalesOrderLine(
    $input: DeleteSalesOrderLineInput!
    $condition: ModelSalesOrderLineConditionInput
  ) {
    deleteSalesOrderLine(input: $input, condition: $condition) {
      id
      tenantId
      salesOrderId
      itemId
      price
      quantity
      notes
      extensions
      sorting
      totalAmount
      vatRate
      modifiedByModule
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      salesOrder {
        id
        tenantId
        customerId
        retailStoreId
        notes
        extensions
        createdByModule
        deleted
        atorderSessionId
        atorderStatus
        atorderType
        atorderOldDatetime
        datetime
        lastCustomerUpdate
        customerCreationDatetime
        amount
        paymentType
        stripePaymentIntent
        stripePaymentStatus
        stripeRefund
        orderNumber
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        salesOrderLines {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreLocation {
          id
          tenantId
          retailStoreRoomId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      images
      atorderType
      sorting
      vatRate
      vatCode
      extensions
      createdByModule
      deleted
      qtyOnHand
      qtyOnHandByStoreId
      enabledRetailStoreIds
      loyaltyPoints
      time
      image
      externalId
      atorderHide
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      itemGroup {
        items {
          id
          tenantId
          itemId
          itemGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      priceList {
        items {
          id
          tenantId
          itemId
          priceListId
          price
          deleted
          extensions
          createdAt
          updatedAt
        }
        nextToken
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      variantGroups {
        items {
          id
          tenantId
          variantGroupId
          itemId
          createdAt
          updatedAt
        }
        nextToken
      }
      logicalPrinters {
        items {
          id
          tenantId
          retailStoreId
          itemId
          logicalPrinterId
          isMain
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      images
      atorderType
      sorting
      vatRate
      vatCode
      extensions
      createdByModule
      deleted
      qtyOnHand
      qtyOnHandByStoreId
      enabledRetailStoreIds
      loyaltyPoints
      time
      image
      externalId
      atorderHide
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      itemGroup {
        items {
          id
          tenantId
          itemId
          itemGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      priceList {
        items {
          id
          tenantId
          itemId
          priceListId
          price
          deleted
          extensions
          createdAt
          updatedAt
        }
        nextToken
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      variantGroups {
        items {
          id
          tenantId
          variantGroupId
          itemId
          createdAt
          updatedAt
        }
        nextToken
      }
      logicalPrinters {
        items {
          id
          tenantId
          retailStoreId
          itemId
          logicalPrinterId
          isMain
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createItemItemGroup = /* GraphQL */ `
  mutation CreateItemItemGroup(
    $input: CreateItemItemGroupInput!
    $condition: ModelItemItemGroupConditionInput
  ) {
    createItemItemGroup(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      itemGroupId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      itemGroup {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
    }
  }
`;
export const updateItemItemGroup = /* GraphQL */ `
  mutation UpdateItemItemGroup(
    $input: UpdateItemItemGroupInput!
    $condition: ModelItemItemGroupConditionInput
  ) {
    updateItemItemGroup(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      itemGroupId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      itemGroup {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
    }
  }
`;
export const deleteItemItemGroup = /* GraphQL */ `
  mutation DeleteItemItemGroup(
    $input: DeleteItemItemGroupInput!
    $condition: ModelItemItemGroupConditionInput
  ) {
    deleteItemItemGroup(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      itemGroupId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      itemGroup {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
    }
  }
`;
export const createItemGroup = /* GraphQL */ `
  mutation CreateItemGroup(
    $input: CreateItemGroupInput!
    $condition: ModelItemGroupConditionInput
  ) {
    createItemGroup(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      sorting
      extensions
      createdByModule
      atorderType
      deleted
      enabledRetailStoreIds
      color
      isCover
      type
      externalId
      atorderHide
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        items {
          id
          tenantId
          itemId
          itemGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateItemGroup = /* GraphQL */ `
  mutation UpdateItemGroup(
    $input: UpdateItemGroupInput!
    $condition: ModelItemGroupConditionInput
  ) {
    updateItemGroup(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      sorting
      extensions
      createdByModule
      atorderType
      deleted
      enabledRetailStoreIds
      color
      isCover
      type
      externalId
      atorderHide
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        items {
          id
          tenantId
          itemId
          itemGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createItemPriceList = /* GraphQL */ `
  mutation CreateItemPriceList(
    $input: CreateItemPriceListInput!
    $condition: ModelItemPriceListConditionInput
  ) {
    createItemPriceList(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      priceListId
      currency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      price
      deleted
      extensions
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateItemPriceList = /* GraphQL */ `
  mutation UpdateItemPriceList(
    $input: UpdateItemPriceListInput!
    $condition: ModelItemPriceListConditionInput
  ) {
    updateItemPriceList(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      priceListId
      currency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      price
      deleted
      extensions
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPriceList = /* GraphQL */ `
  mutation CreatePriceList(
    $input: CreatePriceListInput!
    $condition: ModelPriceListConditionInput
  ) {
    createPriceList(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      extensions
      createdByModule
      sorting
      deleted
      visible
      enabled
      enowebId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        items {
          id
          tenantId
          itemId
          priceListId
          price
          deleted
          extensions
          createdAt
          updatedAt
        }
        nextToken
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updatePriceList = /* GraphQL */ `
  mutation UpdatePriceList(
    $input: UpdatePriceListInput!
    $condition: ModelPriceListConditionInput
  ) {
    updatePriceList(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      extensions
      createdByModule
      sorting
      deleted
      visible
      enabled
      enowebId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        items {
          id
          tenantId
          itemId
          priceListId
          price
          deleted
          extensions
          createdAt
          updatedAt
        }
        nextToken
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createRetailStore = /* GraphQL */ `
  mutation CreateRetailStore(
    $input: CreateRetailStoreInput!
    $condition: ModelRetailStoreConditionInput
  ) {
    createRetailStore(input: $input, condition: $condition) {
      id
      tenantId
      name
      extensions
      deleted
      partnerId
      zipCode
      county
      municipality
      address
      country
      latitude
      longitude
      accountantId
      isSharedTenant
      iban
      enowebMemId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStoreLicense {
        items {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        nextToken
      }
      party {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
      user {
        items {
          id
          userId
          retailStoreId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      invoiceSectional {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      cashRegisters {
        items {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateRetailStore = /* GraphQL */ `
  mutation UpdateRetailStore(
    $input: UpdateRetailStoreInput!
    $condition: ModelRetailStoreConditionInput
  ) {
    updateRetailStore(input: $input, condition: $condition) {
      id
      tenantId
      name
      extensions
      deleted
      partnerId
      zipCode
      county
      municipality
      address
      country
      latitude
      longitude
      accountantId
      isSharedTenant
      iban
      enowebMemId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStoreLicense {
        items {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        nextToken
      }
      party {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
      user {
        items {
          id
          userId
          retailStoreId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      invoiceSectional {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      cashRegisters {
        items {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRetailStoreRoom = /* GraphQL */ `
  mutation CreateRetailStoreRoom(
    $input: CreateRetailStoreRoomInput!
    $condition: ModelRetailStoreRoomConditionInput
  ) {
    createRetailStoreRoom(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      coverItem {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      logicalPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateRetailStoreRoom = /* GraphQL */ `
  mutation UpdateRetailStoreRoom(
    $input: UpdateRetailStoreRoomInput!
    $condition: ModelRetailStoreRoomConditionInput
  ) {
    updateRetailStoreRoom(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      coverItem {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      logicalPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createRetailStoreLocation = /* GraphQL */ `
  mutation CreateRetailStoreLocation(
    $input: CreateRetailStoreLocationInput!
    $condition: ModelRetailStoreLocationConditionInput
  ) {
    createRetailStoreLocation(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreRoomId
      retailStoreId
      name
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreRoom {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateRetailStoreLocation = /* GraphQL */ `
  mutation UpdateRetailStoreLocation(
    $input: UpdateRetailStoreLocationInput!
    $condition: ModelRetailStoreLocationConditionInput
  ) {
    updateRetailStoreLocation(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreRoomId
      retailStoreId
      name
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreRoom {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createUserRetailStore = /* GraphQL */ `
  mutation CreateUserRetailStore(
    $input: CreateUserRetailStoreInput!
    $condition: ModelUserRetailStoreConditionInput
  ) {
    createUserRetailStore(input: $input, condition: $condition) {
      id
      userId
      retailStoreId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      tenantId
      userId
      type
      status
      context
      text
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      news {
        id
        title
        description
        notified
        type
        attribute
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      tenantId
      userId
      type
      status
      context
      text
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      news {
        id
        title
        description
        notified
        type
        attribute
        createdAt
        updatedAt
      }
    }
  }
`;
export const createInvoiceSectional = /* GraphQL */ `
  mutation CreateInvoiceSectional(
    $input: CreateInvoiceSectionalInput!
    $condition: ModelInvoiceSectionalConditionInput
  ) {
    createInvoiceSectional(input: $input, condition: $condition) {
      id
      tenantId
      partnerId
      code
      description
      deleted
      nextNumber
      retailStoreId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateInvoiceSectional = /* GraphQL */ `
  mutation UpdateInvoiceSectional(
    $input: UpdateInvoiceSectionalInput!
    $condition: ModelInvoiceSectionalConditionInput
  ) {
    updateInvoiceSectional(input: $input, condition: $condition) {
      id
      tenantId
      partnerId
      code
      description
      deleted
      nextNumber
      retailStoreId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createSalesInvoice = /* GraphQL */ `
  mutation CreateSalesInvoice(
    $input: CreateSalesInvoiceInput!
    $condition: ModelSalesInvoiceConditionInput
  ) {
    createSalesInvoice(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      customerId
      number
      invoiceId
      date
      type
      taxRegime
      paymentCode
      paymentMode
      paymentAmount
      iban
      notes
      customerFiscalCode
      customerCompanyVat
      customerCompanyName
      customerZipCode
      customerCounty
      customerMunicipality
      customerAddress
      customerCountry
      customerRecipientCode
      customerEmail
      extensions
      isPAInvoice
      sentToArchismall
      pricesWithoutVat
      createdByModule
      xmlS3Key
      pdfS3Key
      archismallProgressiveId
      status
      idSdi
      idArchismall
      errorDescription
      emailNotificationSent
      writeOffInvoiceId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      sectional {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      salesInvoiceLines {
        items {
          id
          tenantId
          salesInvoiceId
          vatRate
          vatCode
          vatCodeDescription
          lineNum
          price
          quantity
          unitPrice
          taxableAmount
          vatAmount
          discountType
          discountAmount
          extensions
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSalesInvoice = /* GraphQL */ `
  mutation UpdateSalesInvoice(
    $input: UpdateSalesInvoiceInput!
    $condition: ModelSalesInvoiceConditionInput
  ) {
    updateSalesInvoice(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      customerId
      number
      invoiceId
      date
      type
      taxRegime
      paymentCode
      paymentMode
      paymentAmount
      iban
      notes
      customerFiscalCode
      customerCompanyVat
      customerCompanyName
      customerZipCode
      customerCounty
      customerMunicipality
      customerAddress
      customerCountry
      customerRecipientCode
      customerEmail
      extensions
      isPAInvoice
      sentToArchismall
      pricesWithoutVat
      createdByModule
      xmlS3Key
      pdfS3Key
      archismallProgressiveId
      status
      idSdi
      idArchismall
      errorDescription
      emailNotificationSent
      writeOffInvoiceId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      sectional {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      salesInvoiceLines {
        items {
          id
          tenantId
          salesInvoiceId
          vatRate
          vatCode
          vatCodeDescription
          lineNum
          price
          quantity
          unitPrice
          taxableAmount
          vatAmount
          discountType
          discountAmount
          extensions
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSalesInvoiceLine = /* GraphQL */ `
  mutation CreateSalesInvoiceLine(
    $input: CreateSalesInvoiceLineInput!
    $condition: ModelSalesInvoiceLineConditionInput
  ) {
    createSalesInvoiceLine(input: $input, condition: $condition) {
      id
      tenantId
      salesInvoiceId
      vatRate
      vatCode
      vatCodeDescription
      lineNum
      price
      quantity
      unitPrice
      taxableAmount
      vatAmount
      discountType
      discountAmount
      extensions
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      salesInvoice {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
    }
  }
`;
export const updateSalesInvoiceLine = /* GraphQL */ `
  mutation UpdateSalesInvoiceLine(
    $input: UpdateSalesInvoiceLineInput!
    $condition: ModelSalesInvoiceLineConditionInput
  ) {
    updateSalesInvoiceLine(input: $input, condition: $condition) {
      id
      tenantId
      salesInvoiceId
      vatRate
      vatCode
      vatCodeDescription
      lineNum
      price
      quantity
      unitPrice
      taxableAmount
      vatAmount
      discountType
      discountAmount
      extensions
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      salesInvoice {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
    }
  }
`;
export const deleteSalesInvoiceLine = /* GraphQL */ `
  mutation DeleteSalesInvoiceLine(
    $input: DeleteSalesInvoiceLineInput!
    $condition: ModelSalesInvoiceLineConditionInput
  ) {
    deleteSalesInvoiceLine(input: $input, condition: $condition) {
      id
      tenantId
      salesInvoiceId
      vatRate
      vatCode
      vatCodeDescription
      lineNum
      price
      quantity
      unitPrice
      taxableAmount
      vatAmount
      discountType
      discountAmount
      extensions
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      salesInvoice {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
    }
  }
`;
export const createEInvoiceTransmission = /* GraphQL */ `
  mutation CreateEInvoiceTransmission(
    $input: CreateEInvoiceTransmissionInput!
    $condition: ModelEInvoiceTransmissionConditionInput
  ) {
    createEInvoiceTransmission(input: $input, condition: $condition) {
      id
      tenantId
      salesInvoiceId
      retailStoreId
      datetime
      status
      details
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      salesInvoice {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
    }
  }
`;
export const updateEInvoiceTransmission = /* GraphQL */ `
  mutation UpdateEInvoiceTransmission(
    $input: UpdateEInvoiceTransmissionInput!
    $condition: ModelEInvoiceTransmissionConditionInput
  ) {
    updateEInvoiceTransmission(input: $input, condition: $condition) {
      id
      tenantId
      salesInvoiceId
      retailStoreId
      datetime
      status
      details
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      salesInvoice {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
    }
  }
`;
export const createElectronicCashRegister = /* GraphQL */ `
  mutation CreateElectronicCashRegister(
    $input: CreateElectronicCashRegisterInput!
    $condition: ModelElectronicCashRegisterConditionInput
  ) {
    createElectronicCashRegister(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      partnerId
      name
      number
      description
      ejNumber
      ejInitDay
      ejMissingDays
      ejDueDate
      ejType
      lastZNumber
      fwVersion
      enabled
      deleted
      extensions
      rtUtilityInactive
      lastSyncDatetime
      type
      ecrModel
      version
      tags
      advMessage
      lastZErrorIds
      imageS3Key
      pendingEJExtension
      exportPendingXML
      accountantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      lastPVIntervention {
        id
        partnerId
        tenantId
        retailStoreId
        number
        fiscalCode
        vatNumber
        datetime
        code
        note
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateElectronicCashRegister = /* GraphQL */ `
  mutation UpdateElectronicCashRegister(
    $input: UpdateElectronicCashRegisterInput!
    $condition: ModelElectronicCashRegisterConditionInput
  ) {
    updateElectronicCashRegister(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      partnerId
      name
      number
      description
      ejNumber
      ejInitDay
      ejMissingDays
      ejDueDate
      ejType
      lastZNumber
      fwVersion
      enabled
      deleted
      extensions
      rtUtilityInactive
      lastSyncDatetime
      type
      ecrModel
      version
      tags
      advMessage
      lastZErrorIds
      imageS3Key
      pendingEJExtension
      exportPendingXML
      accountantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      lastPVIntervention {
        id
        partnerId
        tenantId
        retailStoreId
        number
        fiscalCode
        vatNumber
        datetime
        code
        note
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createECRConfiguration = /* GraphQL */ `
  mutation CreateECRConfiguration(
    $input: CreateECRConfigurationInput!
    $condition: ModelECRConfigurationConditionInput
  ) {
    createECRConfiguration(input: $input, condition: $condition) {
      id
      partnerId
      number
      content
      version
      plu
      status
      logs
      createdAt
      updatedAt
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateECRConfiguration = /* GraphQL */ `
  mutation UpdateECRConfiguration(
    $input: UpdateECRConfigurationInput!
    $condition: ModelECRConfigurationConditionInput
  ) {
    updateECRConfiguration(input: $input, condition: $condition) {
      id
      partnerId
      number
      content
      version
      plu
      status
      logs
      createdAt
      updatedAt
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteECRConfiguration = /* GraphQL */ `
  mutation DeleteECRConfiguration(
    $input: DeleteECRConfigurationInput!
    $condition: ModelECRConfigurationConditionInput
  ) {
    deleteECRConfiguration(input: $input, condition: $condition) {
      id
      partnerId
      number
      content
      version
      plu
      status
      logs
      createdAt
      updatedAt
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createECRDailySales = /* GraphQL */ `
  mutation CreateECRDailySales(
    $input: CreateECRDailySalesInput!
    $condition: ModelECRDailySalesConditionInput
  ) {
    createECRDailySales(input: $input, condition: $condition) {
      id
      tenantId
      electronicCashRegisterId
      number
      datetime
      content
      notified
      accountantId
      createdAt
      updatedAt
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateECRDailySales = /* GraphQL */ `
  mutation UpdateECRDailySales(
    $input: UpdateECRDailySalesInput!
    $condition: ModelECRDailySalesConditionInput
  ) {
    updateECRDailySales(input: $input, condition: $condition) {
      id
      tenantId
      electronicCashRegisterId
      number
      datetime
      content
      notified
      accountantId
      createdAt
      updatedAt
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createECRDailyTech = /* GraphQL */ `
  mutation CreateECRDailyTech(
    $input: CreateECRDailyTechInput!
    $condition: ModelECRDailyTechConditionInput
  ) {
    createECRDailyTech(input: $input, condition: $condition) {
      id
      number
      datetime
      content
      rtUtilityProcessed
      createdAt
      updatedAt
    }
  }
`;
export const updateECRDailyTech = /* GraphQL */ `
  mutation UpdateECRDailyTech(
    $input: UpdateECRDailyTechInput!
    $condition: ModelECRDailyTechConditionInput
  ) {
    updateECRDailyTech(input: $input, condition: $condition) {
      id
      number
      datetime
      content
      rtUtilityProcessed
      createdAt
      updatedAt
    }
  }
`;
export const createECRTechIntervention = /* GraphQL */ `
  mutation CreateECRTechIntervention(
    $input: CreateECRTechInterventionInput!
    $condition: ModelECRTechInterventionConditionInput
  ) {
    createECRTechIntervention(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      retailStoreId
      number
      fiscalCode
      vatNumber
      datetime
      code
      note
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateECRTechIntervention = /* GraphQL */ `
  mutation UpdateECRTechIntervention(
    $input: UpdateECRTechInterventionInput!
    $condition: ModelECRTechInterventionConditionInput
  ) {
    updateECRTechIntervention(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      retailStoreId
      number
      fiscalCode
      vatNumber
      datetime
      code
      note
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteECRTechIntervention = /* GraphQL */ `
  mutation DeleteECRTechIntervention(
    $input: DeleteECRTechInterventionInput!
    $condition: ModelECRTechInterventionConditionInput
  ) {
    deleteECRTechIntervention(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      retailStoreId
      number
      fiscalCode
      vatNumber
      datetime
      code
      note
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createECRError = /* GraphQL */ `
  mutation CreateECRError(
    $input: CreateECRErrorInput!
    $condition: ModelECRErrorConditionInput
  ) {
    createECRError(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      retailStoreId
      number
      code
      description
      datetime
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateECRError = /* GraphQL */ `
  mutation UpdateECRError(
    $input: UpdateECRErrorInput!
    $condition: ModelECRErrorConditionInput
  ) {
    updateECRError(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      retailStoreId
      number
      code
      description
      datetime
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteECRError = /* GraphQL */ `
  mutation DeleteECRError(
    $input: DeleteECRErrorInput!
    $condition: ModelECRErrorConditionInput
  ) {
    deleteECRError(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      retailStoreId
      number
      code
      description
      datetime
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createPartnerNote = /* GraphQL */ `
  mutation CreatePartnerNote(
    $input: CreatePartnerNoteInput!
    $condition: ModelPartnerNoteConditionInput
  ) {
    createPartnerNote(input: $input, condition: $condition) {
      id
      partnerId
      tenantId
      ecrNumber
      companyName
      retailStoreName
      description
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateFirmware = /* GraphQL */ `
  mutation UpdateFirmware(
    $input: UpdateFirmwareInput!
    $condition: ModelFirmwareConditionInput
  ) {
    updateFirmware(input: $input, condition: $condition) {
      id
      version
      notes
      DCashFilename
      DCashPlusFilename
      DPalmFilename
      DFlexFilename
      BlancaFilename
      DFrontFilename
      DTouch10Filename
      downloadable
      billable
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const createFirmwareUpgradeHeader = /* GraphQL */ `
  mutation CreateFirmwareUpgradeHeader(
    $input: CreateFirmwareUpgradeHeaderInput!
    $condition: ModelFirmwareUpgradeHeaderConditionInput
  ) {
    createFirmwareUpgradeHeader(input: $input, condition: $condition) {
      id
      tenantId
      firmwareId
      isServiceManagerIntervention
      fiscalCode
      datetime
      vatNumber
      cancelled
      linesCount
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      firmware {
        id
        version
        notes
        DCashFilename
        DCashPlusFilename
        DPalmFilename
        DFlexFilename
        BlancaFilename
        DFrontFilename
        DTouch10Filename
        downloadable
        billable
        deleted
        createdAt
        updatedAt
      }
      lines {
        items {
          id
          tenantId
          number
          installed
          cancelled
          headerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFirmwareUpgradeHeader = /* GraphQL */ `
  mutation UpdateFirmwareUpgradeHeader(
    $input: UpdateFirmwareUpgradeHeaderInput!
    $condition: ModelFirmwareUpgradeHeaderConditionInput
  ) {
    updateFirmwareUpgradeHeader(input: $input, condition: $condition) {
      id
      tenantId
      firmwareId
      isServiceManagerIntervention
      fiscalCode
      datetime
      vatNumber
      cancelled
      linesCount
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      firmware {
        id
        version
        notes
        DCashFilename
        DCashPlusFilename
        DPalmFilename
        DFlexFilename
        BlancaFilename
        DFrontFilename
        DTouch10Filename
        downloadable
        billable
        deleted
        createdAt
        updatedAt
      }
      lines {
        items {
          id
          tenantId
          number
          installed
          cancelled
          headerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFirmwareUpgradeLine = /* GraphQL */ `
  mutation CreateFirmwareUpgradeLine(
    $input: CreateFirmwareUpgradeLineInput!
    $condition: ModelFirmwareUpgradeLineConditionInput
  ) {
    createFirmwareUpgradeLine(input: $input, condition: $condition) {
      id
      tenantId
      number
      installed
      cancelled
      headerId
      createdAt
      updatedAt
      header {
        id
        tenantId
        firmwareId
        isServiceManagerIntervention
        fiscalCode
        datetime
        vatNumber
        cancelled
        linesCount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        firmware {
          id
          version
          notes
          DCashFilename
          DCashPlusFilename
          DPalmFilename
          DFlexFilename
          BlancaFilename
          DFrontFilename
          DTouch10Filename
          downloadable
          billable
          deleted
          createdAt
          updatedAt
        }
        lines {
          nextToken
        }
      }
    }
  }
`;
export const updateFirmwareUpgradeLine = /* GraphQL */ `
  mutation UpdateFirmwareUpgradeLine(
    $input: UpdateFirmwareUpgradeLineInput!
    $condition: ModelFirmwareUpgradeLineConditionInput
  ) {
    updateFirmwareUpgradeLine(input: $input, condition: $condition) {
      id
      tenantId
      number
      installed
      cancelled
      headerId
      createdAt
      updatedAt
      header {
        id
        tenantId
        firmwareId
        isServiceManagerIntervention
        fiscalCode
        datetime
        vatNumber
        cancelled
        linesCount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        firmware {
          id
          version
          notes
          DCashFilename
          DCashPlusFilename
          DPalmFilename
          DFlexFilename
          BlancaFilename
          DFrontFilename
          DTouch10Filename
          downloadable
          billable
          deleted
          createdAt
          updatedAt
        }
        lines {
          nextToken
        }
      }
    }
  }
`;
export const deleteFirmwareUpgradeLine = /* GraphQL */ `
  mutation DeleteFirmwareUpgradeLine(
    $input: DeleteFirmwareUpgradeLineInput!
    $condition: ModelFirmwareUpgradeLineConditionInput
  ) {
    deleteFirmwareUpgradeLine(input: $input, condition: $condition) {
      id
      tenantId
      number
      installed
      cancelled
      headerId
      createdAt
      updatedAt
      header {
        id
        tenantId
        firmwareId
        isServiceManagerIntervention
        fiscalCode
        datetime
        vatNumber
        cancelled
        linesCount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        firmware {
          id
          version
          notes
          DCashFilename
          DCashPlusFilename
          DPalmFilename
          DFlexFilename
          BlancaFilename
          DFrontFilename
          DTouch10Filename
          downloadable
          billable
          deleted
          createdAt
          updatedAt
        }
        lines {
          nextToken
        }
      }
    }
  }
`;
export const createRtUtilityLog = /* GraphQL */ `
  mutation CreateRtUtilityLog(
    $input: CreateRtUtilityLogInput!
    $condition: ModelRtUtilityLogConditionInput
  ) {
    createRtUtilityLog(input: $input, condition: $condition) {
      id
      partnerId
      userId
      tenantId
      tenantCompanyName
      tenantCompanyVat
      userAttribute
      number
      description
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const updateCommunication = /* GraphQL */ `
  mutation UpdateCommunication(
    $input: UpdateCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    updateCommunication(input: $input, condition: $condition) {
      id
      title
      description
      notified
      type
      attribute
      createdAt
      updatedAt
    }
  }
`;
export const createFirmwareStat = /* GraphQL */ `
  mutation CreateFirmwareStat(
    $input: CreateFirmwareStatInput!
    $condition: ModelFirmwareStatConditionInput
  ) {
    createFirmwareStat(input: $input, condition: $condition) {
      id
      version
      numbers
      createdAt
      updatedAt
    }
  }
`;
export const updateFirmwareStat = /* GraphQL */ `
  mutation UpdateFirmwareStat(
    $input: UpdateFirmwareStatInput!
    $condition: ModelFirmwareStatConditionInput
  ) {
    updateFirmwareStat(input: $input, condition: $condition) {
      id
      version
      numbers
      createdAt
      updatedAt
    }
  }
`;
export const createMoitoIot = /* GraphQL */ `
  mutation CreateMoitoIot(
    $input: CreateMoitoIotInput!
    $condition: ModelMoitoIotConditionInput
  ) {
    createMoitoIot(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      partnerId
      name
      code
      version
      androidApiLevel
      isBetaInstallation
      lastSyncDatetime
      lastBackupS3Key
      lastBackupDatetime
      usersCount
      kiosksCount
      deleted
      moitoIotInactive
      tags
      firstSyncDone
      extensions
      isFree
      lastAssistanceUpload
      ecrNumber
      isSyncDisabled
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      fixedVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateMoitoIot = /* GraphQL */ `
  mutation UpdateMoitoIot(
    $input: UpdateMoitoIotInput!
    $condition: ModelMoitoIotConditionInput
  ) {
    updateMoitoIot(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      partnerId
      name
      code
      version
      androidApiLevel
      isBetaInstallation
      lastSyncDatetime
      lastBackupS3Key
      lastBackupDatetime
      usersCount
      kiosksCount
      deleted
      moitoIotInactive
      tags
      firstSyncDone
      extensions
      isFree
      lastAssistanceUpload
      ecrNumber
      isSyncDisabled
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      fixedVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateMoitoIotAppVersion = /* GraphQL */ `
  mutation UpdateMoitoIotAppVersion(
    $input: UpdateMoitoIotAppVersionInput!
    $condition: ModelMoitoIotAppVersionConditionInput
  ) {
    updateMoitoIotAppVersion(input: $input, condition: $condition) {
      id
      adminId
      version
      frontendZipPath
      apkPath
      apk21Path
      datetime
      notes
      isStable
      deleted
      recordType
      createdAt
      updatedAt
      fromVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      toVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPurchInvoice = /* GraphQL */ `
  mutation CreatePurchInvoice(
    $input: CreatePurchInvoiceInput!
    $condition: ModelPurchInvoiceConditionInput
  ) {
    createPurchInvoice(input: $input, condition: $condition) {
      id
      tenantId
      number
      date
      idSdi
      vendorId
      filename
      progressiveId
      type
      xmlS3Key
      pdfS3Key
      taxableAmount
      vatAmount
      paymentDueDate
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      vendor {
        id
        tenantId
        deleted
        companyName
        companyVat
        fiscalCode
        zipCode
        county
        municipality
        address
        country
        recipientCode
        email
        mobile
        extensions
        costCenterId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        costCenter {
          id
          tenantId
          name
          description
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePurchInvoice = /* GraphQL */ `
  mutation UpdatePurchInvoice(
    $input: UpdatePurchInvoiceInput!
    $condition: ModelPurchInvoiceConditionInput
  ) {
    updatePurchInvoice(input: $input, condition: $condition) {
      id
      tenantId
      number
      date
      idSdi
      vendorId
      filename
      progressiveId
      type
      xmlS3Key
      pdfS3Key
      taxableAmount
      vatAmount
      paymentDueDate
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      vendor {
        id
        tenantId
        deleted
        companyName
        companyVat
        fiscalCode
        zipCode
        county
        municipality
        address
        country
        recipientCode
        email
        mobile
        extensions
        costCenterId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        costCenter {
          id
          tenantId
          name
          description
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPublishedMoitoIotMessage = /* GraphQL */ `
  mutation CreatePublishedMoitoIotMessage(
    $input: CreatePublishedMoitoIotMessageInput!
    $condition: ModelPublishedMoitoIotMessageConditionInput
  ) {
    createPublishedMoitoIotMessage(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const updatePublishedMoitoIotMessage = /* GraphQL */ `
  mutation UpdatePublishedMoitoIotMessage(
    $input: UpdatePublishedMoitoIotMessageInput!
    $condition: ModelPublishedMoitoIotMessageConditionInput
  ) {
    updatePublishedMoitoIotMessage(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const createReceivedMoitoIotMessage = /* GraphQL */ `
  mutation CreateReceivedMoitoIotMessage(
    $input: CreateReceivedMoitoIotMessageInput!
    $condition: ModelReceivedMoitoIotMessageConditionInput
  ) {
    createReceivedMoitoIotMessage(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const updateReceivedMoitoIotMessage = /* GraphQL */ `
  mutation UpdateReceivedMoitoIotMessage(
    $input: UpdateReceivedMoitoIotMessageInput!
    $condition: ModelReceivedMoitoIotMessageConditionInput
  ) {
    updateReceivedMoitoIotMessage(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const createVatRate = /* GraphQL */ `
  mutation CreateVatRate(
    $input: CreateVatRateInput!
    $condition: ModelVatRateConditionInput
  ) {
    createVatRate(input: $input, condition: $condition) {
      id
      tenantId
      code
      description
      sorting
      rate
      extensions
      isAdded
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateVatRate = /* GraphQL */ `
  mutation UpdateVatRate(
    $input: UpdateVatRateInput!
    $condition: ModelVatRateConditionInput
  ) {
    updateVatRate(input: $input, condition: $condition) {
      id
      tenantId
      code
      description
      sorting
      rate
      extensions
      isAdded
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
      tenantId
      color
      description
      forFree
      sorting
      enabled
      activityCode
      extensions
      createdByModule
      deleted
      rtDepartment
      expenseType
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      vatRate {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
      tenantId
      color
      description
      forFree
      sorting
      enabled
      activityCode
      extensions
      createdByModule
      deleted
      rtDepartment
      expenseType
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      vatRate {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVariant = /* GraphQL */ `
  mutation CreateVariant(
    $input: CreateVariantInput!
    $condition: ModelVariantConditionInput
  ) {
    createVariant(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variantVariantGroups {
        items {
          id
          tenantId
          variantId
          variantGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      variantModifiers {
        items {
          id
          tenantId
          variantId
          modifierId
          price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateVariant = /* GraphQL */ `
  mutation UpdateVariant(
    $input: UpdateVariantInput!
    $condition: ModelVariantConditionInput
  ) {
    updateVariant(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variantVariantGroups {
        items {
          id
          tenantId
          variantId
          variantGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      variantModifiers {
        items {
          id
          tenantId
          variantId
          modifierId
          price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createModifier = /* GraphQL */ `
  mutation CreateModifier(
    $input: CreateModifierInput!
    $condition: ModelModifierConditionInput
  ) {
    createModifier(input: $input, condition: $condition) {
      id
      tenantId
      description
      color
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateModifier = /* GraphQL */ `
  mutation UpdateModifier(
    $input: UpdateModifierInput!
    $condition: ModelModifierConditionInput
  ) {
    updateModifier(input: $input, condition: $condition) {
      id
      tenantId
      description
      color
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVariantGroup = /* GraphQL */ `
  mutation CreateVariantGroup(
    $input: CreateVariantGroupInput!
    $condition: ModelVariantGroupConditionInput
  ) {
    createVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      enabled
      color
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateVariantGroup = /* GraphQL */ `
  mutation UpdateVariantGroup(
    $input: UpdateVariantGroupInput!
    $condition: ModelVariantGroupConditionInput
  ) {
    updateVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      enabled
      color
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVariantVariantGroup = /* GraphQL */ `
  mutation CreateVariantVariantGroup(
    $input: CreateVariantVariantGroupInput!
    $condition: ModelVariantVariantGroupConditionInput
  ) {
    createVariantVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      variantId
      variantGroupId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      variantGroup {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateVariantVariantGroup = /* GraphQL */ `
  mutation UpdateVariantVariantGroup(
    $input: UpdateVariantVariantGroupInput!
    $condition: ModelVariantVariantGroupConditionInput
  ) {
    updateVariantVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      variantId
      variantGroupId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      variantGroup {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteVariantVariantGroup = /* GraphQL */ `
  mutation DeleteVariantVariantGroup(
    $input: DeleteVariantVariantGroupInput!
    $condition: ModelVariantVariantGroupConditionInput
  ) {
    deleteVariantVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      variantId
      variantGroupId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      variantGroup {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVariantModifier = /* GraphQL */ `
  mutation CreateVariantModifier(
    $input: CreateVariantModifierInput!
    $condition: ModelVariantModifierConditionInput
  ) {
    createVariantModifier(input: $input, condition: $condition) {
      id
      tenantId
      variantId
      modifierId
      price
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateVariantModifier = /* GraphQL */ `
  mutation UpdateVariantModifier(
    $input: UpdateVariantModifierInput!
    $condition: ModelVariantModifierConditionInput
  ) {
    updateVariantModifier(input: $input, condition: $condition) {
      id
      tenantId
      variantId
      modifierId
      price
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteVariantModifier = /* GraphQL */ `
  mutation DeleteVariantModifier(
    $input: DeleteVariantModifierInput!
    $condition: ModelVariantModifierConditionInput
  ) {
    deleteVariantModifier(input: $input, condition: $condition) {
      id
      tenantId
      variantId
      modifierId
      price
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createMoitoIotOperator = /* GraphQL */ `
  mutation CreateMoitoIotOperator(
    $input: CreateMoitoIotOperatorInput!
    $condition: ModelMoitoIotOperatorConditionInput
  ) {
    createMoitoIotOperator(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      description
      username
      password
      deleted
      sorting
      icon
      color
      enabled
      isAdmin
      blockPayments
      printWorkshift
      openDrawer
      incoming
      withdrawals
      insertPrice
      voids
      refunds
      discounts
      cancellation
      changeVat
      vatImmune
      preAccounts
      zreport
      updateWarehouse
      insertItems
      takeDesk
      forceTakeDesk
      journal
      finalPreAccounts
      testMode
      frontendConfigurations
      type
      showSettings
      showEditingMode
      showDtrs
      showInvoiceOutcome
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreRoom {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      orderPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      receiptPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      invoicePrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      preorderPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      tallonPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      reportPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      kioskPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateMoitoIotOperator = /* GraphQL */ `
  mutation UpdateMoitoIotOperator(
    $input: UpdateMoitoIotOperatorInput!
    $condition: ModelMoitoIotOperatorConditionInput
  ) {
    updateMoitoIotOperator(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      description
      username
      password
      deleted
      sorting
      icon
      color
      enabled
      isAdmin
      blockPayments
      printWorkshift
      openDrawer
      incoming
      withdrawals
      insertPrice
      voids
      refunds
      discounts
      cancellation
      changeVat
      vatImmune
      preAccounts
      zreport
      updateWarehouse
      insertItems
      takeDesk
      forceTakeDesk
      journal
      finalPreAccounts
      testMode
      frontendConfigurations
      type
      showSettings
      showEditingMode
      showDtrs
      showInvoiceOutcome
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreRoom {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      orderPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      receiptPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      invoicePrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      preorderPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      tallonPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      reportPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      kioskPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createDiscountSurcharge = /* GraphQL */ `
  mutation CreateDiscountSurcharge(
    $input: CreateDiscountSurchargeInput!
    $condition: ModelDiscountSurchargeConditionInput
  ) {
    createDiscountSurcharge(input: $input, condition: $condition) {
      id
      tenantId
      description
      value
      percValue
      discountSurcharge
      itemSubTotal
      enabled
      sorting
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateDiscountSurcharge = /* GraphQL */ `
  mutation UpdateDiscountSurcharge(
    $input: UpdateDiscountSurchargeInput!
    $condition: ModelDiscountSurchargeConditionInput
  ) {
    updateDiscountSurcharge(input: $input, condition: $condition) {
      id
      tenantId
      description
      value
      percValue
      discountSurcharge
      itemSubTotal
      enabled
      sorting
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPhase = /* GraphQL */ `
  mutation CreatePhase(
    $input: CreatePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    createPhase(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      enabled
      code
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePhase = /* GraphQL */ `
  mutation UpdatePhase(
    $input: UpdatePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    updatePhase(input: $input, condition: $condition) {
      id
      tenantId
      description
      sorting
      enabled
      code
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createItemVariantGroup = /* GraphQL */ `
  mutation CreateItemVariantGroup(
    $input: CreateItemVariantGroupInput!
    $condition: ModelItemVariantGroupConditionInput
  ) {
    createItemVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      variantGroupId
      itemId
      createdAt
      updatedAt
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      variantGroup {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateItemVariantGroup = /* GraphQL */ `
  mutation UpdateItemVariantGroup(
    $input: UpdateItemVariantGroupInput!
    $condition: ModelItemVariantGroupConditionInput
  ) {
    updateItemVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      variantGroupId
      itemId
      createdAt
      updatedAt
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      variantGroup {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteItemVariantGroup = /* GraphQL */ `
  mutation DeleteItemVariantGroup(
    $input: DeleteItemVariantGroupInput!
    $condition: ModelItemVariantGroupConditionInput
  ) {
    deleteItemVariantGroup(input: $input, condition: $condition) {
      id
      tenantId
      variantGroupId
      itemId
      createdAt
      updatedAt
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      variantGroup {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createMoitoIotPhysicalPrinter = /* GraphQL */ `
  mutation CreateMoitoIotPhysicalPrinter(
    $input: CreateMoitoIotPhysicalPrinterInput!
    $condition: ModelMoitoIotPhysicalPrinterConditionInput
  ) {
    createMoitoIotPhysicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      type
      driver
      name
      description
      model
      version
      number
      com
      baud
      ip
      port
      rowLength
      isTest
      screenSize
      cashBalance
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateMoitoIotPhysicalPrinter = /* GraphQL */ `
  mutation UpdateMoitoIotPhysicalPrinter(
    $input: UpdateMoitoIotPhysicalPrinterInput!
    $condition: ModelMoitoIotPhysicalPrinterConditionInput
  ) {
    updateMoitoIotPhysicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      type
      driver
      name
      description
      model
      version
      number
      com
      baud
      ip
      port
      rowLength
      isTest
      screenSize
      cashBalance
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createMoitoIotLogicalPrinter = /* GraphQL */ `
  mutation CreateMoitoIotLogicalPrinter(
    $input: CreateMoitoIotLogicalPrinterInput!
    $condition: ModelMoitoIotLogicalPrinterConditionInput
  ) {
    createMoitoIotLogicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      physicalPrinterId
      name
      description
      printLogoOrder
      beepAfterOrder
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      physicalPrinter {
        id
        tenantId
        retailStoreId
        type
        driver
        name
        description
        model
        version
        number
        com
        baud
        ip
        port
        rowLength
        isTest
        screenSize
        cashBalance
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateMoitoIotLogicalPrinter = /* GraphQL */ `
  mutation UpdateMoitoIotLogicalPrinter(
    $input: UpdateMoitoIotLogicalPrinterInput!
    $condition: ModelMoitoIotLogicalPrinterConditionInput
  ) {
    updateMoitoIotLogicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      physicalPrinterId
      name
      description
      printLogoOrder
      beepAfterOrder
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      physicalPrinter {
        id
        tenantId
        retailStoreId
        type
        driver
        name
        description
        model
        version
        number
        com
        baud
        ip
        port
        rowLength
        isTest
        screenSize
        cashBalance
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createItemMoitoIotLogicalPrinter = /* GraphQL */ `
  mutation CreateItemMoitoIotLogicalPrinter(
    $input: CreateItemMoitoIotLogicalPrinterInput!
    $condition: ModelItemMoitoIotLogicalPrinterConditionInput
  ) {
    createItemMoitoIotLogicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      itemId
      logicalPrinterId
      isMain
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      logicalPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateItemMoitoIotLogicalPrinter = /* GraphQL */ `
  mutation UpdateItemMoitoIotLogicalPrinter(
    $input: UpdateItemMoitoIotLogicalPrinterInput!
    $condition: ModelItemMoitoIotLogicalPrinterConditionInput
  ) {
    updateItemMoitoIotLogicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      itemId
      logicalPrinterId
      isMain
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      logicalPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteItemMoitoIotLogicalPrinter = /* GraphQL */ `
  mutation DeleteItemMoitoIotLogicalPrinter(
    $input: DeleteItemMoitoIotLogicalPrinterInput!
    $condition: ModelItemMoitoIotLogicalPrinterConditionInput
  ) {
    deleteItemMoitoIotLogicalPrinter(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      itemId
      logicalPrinterId
      isMain
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      logicalPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createReceipt = /* GraphQL */ `
  mutation CreateReceipt(
    $input: CreateReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    createReceipt(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      moitoIotId
      moitoIotNumber
      ecrNumber
      moitoIotDatetime
      zMoitoIot
      zEcr
      ecrDatetime
      number
      amount
      percDiscount
      valDiscount
      discountDescription
      customerId
      posted
      type
      description
      extensions
      deleted
      netAmount
      taxAmount
      payedAmount
      changeAmount
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      receiptPayments {
        items {
          id
          tenantId
          receiptId
          paymentId
          retailStoreId
          amount
          sorting
          description
          loyaltyCardId
          loyaltyCardInitialAmount
          loyaltyCardFinalAmount
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      lines {
        items {
          id
          tenantId
          retailStoreId
          receiptId
          departmentId
          sorting
          description
          percDiscount
          valDiscount
          discountDescription
          comment
          price
          quantity
          total
          loyaltyPoints
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
    }
  }
`;
export const updateReceipt = /* GraphQL */ `
  mutation UpdateReceipt(
    $input: UpdateReceiptInput!
    $condition: ModelReceiptConditionInput
  ) {
    updateReceipt(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      moitoIotId
      moitoIotNumber
      ecrNumber
      moitoIotDatetime
      zMoitoIot
      zEcr
      ecrDatetime
      number
      amount
      percDiscount
      valDiscount
      discountDescription
      customerId
      posted
      type
      description
      extensions
      deleted
      netAmount
      taxAmount
      payedAmount
      changeAmount
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      receiptPayments {
        items {
          id
          tenantId
          receiptId
          paymentId
          retailStoreId
          amount
          sorting
          description
          loyaltyCardId
          loyaltyCardInitialAmount
          loyaltyCardFinalAmount
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      lines {
        items {
          id
          tenantId
          retailStoreId
          receiptId
          departmentId
          sorting
          description
          percDiscount
          valDiscount
          discountDescription
          comment
          price
          quantity
          total
          loyaltyPoints
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      tenantId
      sorting
      type
      name
      color
      enabled
      presetAmount
      automaticDiscount
      allowLeftovers
      allowIncoming
      allowWithdrawals
      allowComment
      allowZero
      automaticOpening
      willFollowInvoice
      isLoyaltyCard
      extensions
      deleted
      isBankTransfer
      isTracked
      eftAmount
      cashDrawer
      adminOnly
      satispay
      satispayMode
      paymentDtrId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      tenantId
      sorting
      type
      name
      color
      enabled
      presetAmount
      automaticDiscount
      allowLeftovers
      allowIncoming
      allowWithdrawals
      allowComment
      allowZero
      automaticOpening
      willFollowInvoice
      isLoyaltyCard
      extensions
      deleted
      isBankTransfer
      isTracked
      eftAmount
      cashDrawer
      adminOnly
      satispay
      satispayMode
      paymentDtrId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createReceiptPayment = /* GraphQL */ `
  mutation CreateReceiptPayment(
    $input: CreateReceiptPaymentInput!
    $condition: ModelReceiptPaymentConditionInput
  ) {
    createReceiptPayment(input: $input, condition: $condition) {
      id
      tenantId
      receiptId
      paymentId
      retailStoreId
      amount
      sorting
      description
      loyaltyCardId
      loyaltyCardInitialAmount
      loyaltyCardFinalAmount
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      payment {
        id
        tenantId
        sorting
        type
        name
        color
        enabled
        presetAmount
        automaticDiscount
        allowLeftovers
        allowIncoming
        allowWithdrawals
        allowComment
        allowZero
        automaticOpening
        willFollowInvoice
        isLoyaltyCard
        extensions
        deleted
        isBankTransfer
        isTracked
        eftAmount
        cashDrawer
        adminOnly
        satispay
        satispayMode
        paymentDtrId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReceiptPayment = /* GraphQL */ `
  mutation UpdateReceiptPayment(
    $input: UpdateReceiptPaymentInput!
    $condition: ModelReceiptPaymentConditionInput
  ) {
    updateReceiptPayment(input: $input, condition: $condition) {
      id
      tenantId
      receiptId
      paymentId
      retailStoreId
      amount
      sorting
      description
      loyaltyCardId
      loyaltyCardInitialAmount
      loyaltyCardFinalAmount
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      payment {
        id
        tenantId
        sorting
        type
        name
        color
        enabled
        presetAmount
        automaticDiscount
        allowLeftovers
        allowIncoming
        allowWithdrawals
        allowComment
        allowZero
        automaticOpening
        willFollowInvoice
        isLoyaltyCard
        extensions
        deleted
        isBankTransfer
        isTracked
        eftAmount
        cashDrawer
        adminOnly
        satispay
        satispayMode
        paymentDtrId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createReceiptLine = /* GraphQL */ `
  mutation CreateReceiptLine(
    $input: CreateReceiptLineInput!
    $condition: ModelReceiptLineConditionInput
  ) {
    createReceiptLine(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      departmentId
      sorting
      description
      percDiscount
      valDiscount
      discountDescription
      comment
      price
      quantity
      total
      loyaltyPoints
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReceiptLine = /* GraphQL */ `
  mutation UpdateReceiptLine(
    $input: UpdateReceiptLineInput!
    $condition: ModelReceiptLineConditionInput
  ) {
    updateReceiptLine(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      departmentId
      sorting
      description
      percDiscount
      valDiscount
      discountDescription
      comment
      price
      quantity
      total
      loyaltyPoints
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createReceiptVat = /* GraphQL */ `
  mutation CreateReceiptVat(
    $input: CreateReceiptVatInput!
    $condition: ModelReceiptVatConditionInput
  ) {
    createReceiptVat(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      sorting
      vatCode
      vatRate
      totalAmount
      netAmount
      vatAmount
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReceiptVat = /* GraphQL */ `
  mutation UpdateReceiptVat(
    $input: UpdateReceiptVatInput!
    $condition: ModelReceiptVatConditionInput
  ) {
    updateReceiptVat(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      sorting
      vatCode
      vatRate
      totalAmount
      netAmount
      vatAmount
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createReceiptLineVariant = /* GraphQL */ `
  mutation CreateReceiptLineVariant(
    $input: CreateReceiptLineVariantInput!
    $condition: ModelReceiptLineVariantConditionInput
  ) {
    createReceiptLineVariant(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      receiptLineId
      variantId
      modifierId
      modifierDescription
      modifierRightPosition
      modifierColor
      variantDescription
      price
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      receiptLine {
        id
        tenantId
        retailStoreId
        receiptId
        departmentId
        sorting
        description
        percDiscount
        valDiscount
        discountDescription
        comment
        price
        quantity
        total
        loyaltyPoints
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReceiptLineVariant = /* GraphQL */ `
  mutation UpdateReceiptLineVariant(
    $input: UpdateReceiptLineVariantInput!
    $condition: ModelReceiptLineVariantConditionInput
  ) {
    updateReceiptLineVariant(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      receiptId
      receiptLineId
      variantId
      modifierId
      modifierDescription
      modifierRightPosition
      modifierColor
      variantDescription
      price
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      receiptLine {
        id
        tenantId
        retailStoreId
        receiptId
        departmentId
        sorting
        description
        percDiscount
        valDiscount
        discountDescription
        comment
        price
        quantity
        total
        loyaltyPoints
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createMoitoIotMessageFilter = /* GraphQL */ `
  mutation CreateMoitoIotMessageFilter(
    $input: CreateMoitoIotMessageFilterInput!
    $condition: ModelMoitoIotMessageFilterConditionInput
  ) {
    createMoitoIotMessageFilter(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      createdAt
      updatedAt
    }
  }
`;
export const updateMoitoIotMessageFilter = /* GraphQL */ `
  mutation UpdateMoitoIotMessageFilter(
    $input: UpdateMoitoIotMessageFilterInput!
    $condition: ModelMoitoIotMessageFilterConditionInput
  ) {
    updateMoitoIotMessageFilter(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMoitoIotMessageFilter = /* GraphQL */ `
  mutation DeleteMoitoIotMessageFilter(
    $input: DeleteMoitoIotMessageFilterInput!
    $condition: ModelMoitoIotMessageFilterConditionInput
  ) {
    deleteMoitoIotMessageFilter(input: $input, condition: $condition) {
      id
      timestamp
      deviceId
      createdAt
      updatedAt
    }
  }
`;
export const createECRPendingXML = /* GraphQL */ `
  mutation CreateECRPendingXML(
    $input: CreateECRPendingXMLInput!
    $condition: ModelECRPendingXMLConditionInput
  ) {
    createECRPendingXML(input: $input, condition: $condition) {
      id
      electronicCashRegisterId
      partnerId
      tenantId
      number
      filename
      datetime
      s3Key
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateECRPendingXML = /* GraphQL */ `
  mutation UpdateECRPendingXML(
    $input: UpdateECRPendingXMLInput!
    $condition: ModelECRPendingXMLConditionInput
  ) {
    updateECRPendingXML(input: $input, condition: $condition) {
      id
      electronicCashRegisterId
      partnerId
      tenantId
      number
      filename
      datetime
      s3Key
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createCalendarEventType = /* GraphQL */ `
  mutation CreateCalendarEventType(
    $input: CreateCalendarEventTypeInput!
    $condition: ModelCalendarEventTypeConditionInput
  ) {
    createCalendarEventType(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      color
      notificationEnabled
      notificationAnticipationMinutes
      title
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateCalendarEventType = /* GraphQL */ `
  mutation UpdateCalendarEventType(
    $input: UpdateCalendarEventTypeInput!
    $condition: ModelCalendarEventTypeConditionInput
  ) {
    updateCalendarEventType(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      color
      notificationEnabled
      notificationAnticipationMinutes
      title
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteCalendarEventType = /* GraphQL */ `
  mutation DeleteCalendarEventType(
    $input: DeleteCalendarEventTypeInput!
    $condition: ModelCalendarEventTypeConditionInput
  ) {
    deleteCalendarEventType(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      name
      color
      notificationEnabled
      notificationAnticipationMinutes
      title
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createCalendarEvent = /* GraphQL */ `
  mutation CreateCalendarEvent(
    $input: CreateCalendarEventInput!
    $condition: ModelCalendarEventConditionInput
  ) {
    createCalendarEvent(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      userId
      calendarEventTypeId
      customerId
      notificationEnabled
      notificationDatetime
      notificationSent
      title
      description
      startDatetime
      endDatetime
      tags
      isAllDayEvent
      extensions
      mobile
      email
      deleted
      operatorId
      closed
      isPending
      address
      source
      printed
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEventType {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      calendarEventItems {
        items {
          id
          tenantId
          retailStoreId
          itemId
          qty
          calendarEventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCalendarEvent = /* GraphQL */ `
  mutation UpdateCalendarEvent(
    $input: UpdateCalendarEventInput!
    $condition: ModelCalendarEventConditionInput
  ) {
    updateCalendarEvent(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      userId
      calendarEventTypeId
      customerId
      notificationEnabled
      notificationDatetime
      notificationSent
      title
      description
      startDatetime
      endDatetime
      tags
      isAllDayEvent
      extensions
      mobile
      email
      deleted
      operatorId
      closed
      isPending
      address
      source
      printed
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEventType {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      calendarEventItems {
        items {
          id
          tenantId
          retailStoreId
          itemId
          qty
          calendarEventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCalendarEvent = /* GraphQL */ `
  mutation DeleteCalendarEvent(
    $input: DeleteCalendarEventInput!
    $condition: ModelCalendarEventConditionInput
  ) {
    deleteCalendarEvent(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      userId
      calendarEventTypeId
      customerId
      notificationEnabled
      notificationDatetime
      notificationSent
      title
      description
      startDatetime
      endDatetime
      tags
      isAllDayEvent
      extensions
      mobile
      email
      deleted
      operatorId
      closed
      isPending
      address
      source
      printed
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEventType {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      calendarEventItems {
        items {
          id
          tenantId
          retailStoreId
          itemId
          qty
          calendarEventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createECRAdvMessage = /* GraphQL */ `
  mutation CreateECRAdvMessage(
    $input: CreateECRAdvMessageInput!
    $condition: ModelECRAdvMessageConditionInput
  ) {
    createECRAdvMessage(input: $input, condition: $condition) {
      id
      electronicCashRegisterId
      partnerId
      message
      printed
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateECRAdvMessage = /* GraphQL */ `
  mutation UpdateECRAdvMessage(
    $input: UpdateECRAdvMessageInput!
    $condition: ModelECRAdvMessageConditionInput
  ) {
    updateECRAdvMessage(input: $input, condition: $condition) {
      id
      electronicCashRegisterId
      partnerId
      message
      printed
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createLoyaltyCard = /* GraphQL */ `
  mutation CreateLoyaltyCard(
    $input: CreateLoyaltyCardInput!
    $condition: ModelLoyaltyCardConditionInput
  ) {
    createLoyaltyCard(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      customerId
      s3Key
      initialAmount
      remainingAmount
      email
      type
      percDiscount
      datetime
      dueDate
      revoked
      description
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateLoyaltyCard = /* GraphQL */ `
  mutation UpdateLoyaltyCard(
    $input: UpdateLoyaltyCardInput!
    $condition: ModelLoyaltyCardConditionInput
  ) {
    updateLoyaltyCard(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      customerId
      s3Key
      initialAmount
      remainingAmount
      email
      type
      percDiscount
      datetime
      dueDate
      revoked
      description
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createOperator = /* GraphQL */ `
  mutation CreateOperator(
    $input: CreateOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    createOperator(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      description
      name
      deleted
      sorting
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateOperator = /* GraphQL */ `
  mutation UpdateOperator(
    $input: UpdateOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    updateOperator(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      description
      name
      deleted
      sorting
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createCalendarEventItem = /* GraphQL */ `
  mutation CreateCalendarEventItem(
    $input: CreateCalendarEventItemInput!
    $condition: ModelCalendarEventItemConditionInput
  ) {
    createCalendarEventItem(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      itemId
      qty
      calendarEventId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      calendarEventItemVariants {
        items {
          id
          tenantId
          retailStoreId
          price
          calendarEventId
          calendarEventItemId
          variantId
          modifierId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCalendarEventItem = /* GraphQL */ `
  mutation UpdateCalendarEventItem(
    $input: UpdateCalendarEventItemInput!
    $condition: ModelCalendarEventItemConditionInput
  ) {
    updateCalendarEventItem(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      itemId
      qty
      calendarEventId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      calendarEventItemVariants {
        items {
          id
          tenantId
          retailStoreId
          price
          calendarEventId
          calendarEventItemId
          variantId
          modifierId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCalendarEventItem = /* GraphQL */ `
  mutation DeleteCalendarEventItem(
    $input: DeleteCalendarEventItemInput!
    $condition: ModelCalendarEventItemConditionInput
  ) {
    deleteCalendarEventItem(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      itemId
      qty
      calendarEventId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      calendarEventItemVariants {
        items {
          id
          tenantId
          retailStoreId
          price
          calendarEventId
          calendarEventItemId
          variantId
          modifierId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCalendarEventItemVariant = /* GraphQL */ `
  mutation CreateCalendarEventItemVariant(
    $input: CreateCalendarEventItemVariantInput!
    $condition: ModelCalendarEventItemVariantConditionInput
  ) {
    createCalendarEventItemVariant(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      price
      calendarEventId
      calendarEventItemId
      variantId
      modifierId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      calendarEventItem {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
    }
  }
`;
export const updateCalendarEventItemVariant = /* GraphQL */ `
  mutation UpdateCalendarEventItemVariant(
    $input: UpdateCalendarEventItemVariantInput!
    $condition: ModelCalendarEventItemVariantConditionInput
  ) {
    updateCalendarEventItemVariant(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      price
      calendarEventId
      calendarEventItemId
      variantId
      modifierId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      calendarEventItem {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
    }
  }
`;
export const deleteCalendarEventItemVariant = /* GraphQL */ `
  mutation DeleteCalendarEventItemVariant(
    $input: DeleteCalendarEventItemVariantInput!
    $condition: ModelCalendarEventItemVariantConditionInput
  ) {
    deleteCalendarEventItemVariant(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      price
      calendarEventId
      calendarEventItemId
      variantId
      modifierId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      calendarEventItem {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
    }
  }
`;
export const createEJProgramming = /* GraphQL */ `
  mutation CreateEJProgramming(
    $input: CreateEJProgrammingInput!
    $condition: ModelEJProgrammingConditionInput
  ) {
    createEJProgramming(input: $input, condition: $condition) {
      id
      tenantId
      status
      type
      number
      completedDatetime
      deleted
      reason
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const updateEJProgramming = /* GraphQL */ `
  mutation UpdateEJProgramming(
    $input: UpdateEJProgrammingInput!
    $condition: ModelEJProgrammingConditionInput
  ) {
    updateEJProgramming(input: $input, condition: $condition) {
      id
      tenantId
      status
      type
      number
      completedDatetime
      deleted
      reason
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const deleteEJProgramming = /* GraphQL */ `
  mutation DeleteEJProgramming(
    $input: DeleteEJProgrammingInput!
    $condition: ModelEJProgrammingConditionInput
  ) {
    deleteEJProgramming(input: $input, condition: $condition) {
      id
      tenantId
      status
      type
      number
      completedDatetime
      deleted
      reason
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const createECRNumber = /* GraphQL */ `
  mutation CreateECRNumber(
    $input: CreateECRNumberInput!
    $condition: ModelECRNumberConditionInput
  ) {
    createECRNumber(input: $input, condition: $condition) {
      id
      fwVersion
      ejNumber
      ejInitDay
      ejMissingDays
      ejType
      ejExtensionRequested
      ejProgrammingRequested
      forcedEjExpires
      createdAt
      updatedAt
    }
  }
`;
export const updateECRNumber = /* GraphQL */ `
  mutation UpdateECRNumber(
    $input: UpdateECRNumberInput!
    $condition: ModelECRNumberConditionInput
  ) {
    updateECRNumber(input: $input, condition: $condition) {
      id
      fwVersion
      ejNumber
      ejInitDay
      ejMissingDays
      ejType
      ejExtensionRequested
      ejProgrammingRequested
      forcedEjExpires
      createdAt
      updatedAt
    }
  }
`;
export const deleteECRNumber = /* GraphQL */ `
  mutation DeleteECRNumber(
    $input: DeleteECRNumberInput!
    $condition: ModelECRNumberConditionInput
  ) {
    deleteECRNumber(input: $input, condition: $condition) {
      id
      fwVersion
      ejNumber
      ejInitDay
      ejMissingDays
      ejType
      ejExtensionRequested
      ejProgrammingRequested
      forcedEjExpires
      createdAt
      updatedAt
    }
  }
`;
export const createOpticalFile = /* GraphQL */ `
  mutation CreateOpticalFile(
    $input: CreateOpticalFileInput!
    $condition: ModelOpticalFileConditionInput
  ) {
    createOpticalFile(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      status
      date
      xmlS3Key
      pdfS3Key
      zipS3Key
      csvS3Key
      errorReason
      protocolId
      sendOutcomeCode
      sendOutcomeDescription
      errorDescription
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateOpticalFile = /* GraphQL */ `
  mutation UpdateOpticalFile(
    $input: UpdateOpticalFileInput!
    $condition: ModelOpticalFileConditionInput
  ) {
    updateOpticalFile(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      status
      date
      xmlS3Key
      pdfS3Key
      zipS3Key
      csvS3Key
      errorReason
      protocolId
      sendOutcomeCode
      sendOutcomeDescription
      errorDescription
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteOpticalFile = /* GraphQL */ `
  mutation DeleteOpticalFile(
    $input: DeleteOpticalFileInput!
    $condition: ModelOpticalFileConditionInput
  ) {
    deleteOpticalFile(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      status
      date
      xmlS3Key
      pdfS3Key
      zipS3Key
      csvS3Key
      errorReason
      protocolId
      sendOutcomeCode
      sendOutcomeDescription
      errorDescription
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createInventoryMovement = /* GraphQL */ `
  mutation CreateInventoryMovement(
    $input: CreateInventoryMovementInput!
    $condition: ModelInventoryMovementConditionInput
  ) {
    createInventoryMovement(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      datetime
      reasonCode
      itemId
      qtyDiff
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const updateInventoryMovement = /* GraphQL */ `
  mutation UpdateInventoryMovement(
    $input: UpdateInventoryMovementInput!
    $condition: ModelInventoryMovementConditionInput
  ) {
    updateInventoryMovement(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      datetime
      reasonCode
      itemId
      qtyDiff
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const deleteInventoryMovement = /* GraphQL */ `
  mutation DeleteInventoryMovement(
    $input: DeleteInventoryMovementInput!
    $condition: ModelInventoryMovementConditionInput
  ) {
    deleteInventoryMovement(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      datetime
      reasonCode
      itemId
      qtyDiff
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const createBarcode = /* GraphQL */ `
  mutation CreateBarcode(
    $input: CreateBarcodeInput!
    $condition: ModelBarcodeConditionInput
  ) {
    createBarcode(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      barcode
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
    }
  }
`;
export const updateBarcode = /* GraphQL */ `
  mutation UpdateBarcode(
    $input: UpdateBarcodeInput!
    $condition: ModelBarcodeConditionInput
  ) {
    updateBarcode(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      barcode
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
    }
  }
`;
export const deleteBarcode = /* GraphQL */ `
  mutation DeleteBarcode(
    $input: DeleteBarcodeInput!
    $condition: ModelBarcodeConditionInput
  ) {
    deleteBarcode(input: $input, condition: $condition) {
      id
      tenantId
      itemId
      barcode
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
    }
  }
`;
export const createMoitoIotFrontendConfigurations = /* GraphQL */ `
  mutation CreateMoitoIotFrontendConfigurations(
    $input: CreateMoitoIotFrontendConfigurationsInput!
    $condition: ModelMoitoIotFrontendConfigurationsConditionInput
  ) {
    createMoitoIotFrontendConfigurations(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      operatorId
      keyboard
      topbar
      bottombar
      settings
      layout
      numpad
      current_list
      tab_view
      resize_bar
      zoom
      default_list
      last_load_status
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateMoitoIotFrontendConfigurations = /* GraphQL */ `
  mutation UpdateMoitoIotFrontendConfigurations(
    $input: UpdateMoitoIotFrontendConfigurationsInput!
    $condition: ModelMoitoIotFrontendConfigurationsConditionInput
  ) {
    updateMoitoIotFrontendConfigurations(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      operatorId
      keyboard
      topbar
      bottombar
      settings
      layout
      numpad
      current_list
      tab_view
      resize_bar
      zoom
      default_list
      last_load_status
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteMoitoIotFrontendConfigurations = /* GraphQL */ `
  mutation DeleteMoitoIotFrontendConfigurations(
    $input: DeleteMoitoIotFrontendConfigurationsInput!
    $condition: ModelMoitoIotFrontendConfigurationsConditionInput
  ) {
    deleteMoitoIotFrontendConfigurations(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      operatorId
      keyboard
      topbar
      bottombar
      settings
      layout
      numpad
      current_list
      tab_view
      resize_bar
      zoom
      default_list
      last_load_status
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createTobacco = /* GraphQL */ `
  mutation CreateTobacco(
    $input: CreateTobaccoInput!
    $condition: ModelTobaccoConditionInput
  ) {
    createTobacco(input: $input, condition: $condition) {
      id
      tenantId
      s3Key
      otherS3Key
      internalS3Key
      type
      validFrom
      validTo
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateTobacco = /* GraphQL */ `
  mutation UpdateTobacco(
    $input: UpdateTobaccoInput!
    $condition: ModelTobaccoConditionInput
  ) {
    updateTobacco(input: $input, condition: $condition) {
      id
      tenantId
      s3Key
      otherS3Key
      internalS3Key
      type
      validFrom
      validTo
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteTobacco = /* GraphQL */ `
  mutation DeleteTobacco(
    $input: DeleteTobaccoInput!
    $condition: ModelTobaccoConditionInput
  ) {
    deleteTobacco(input: $input, condition: $condition) {
      id
      tenantId
      s3Key
      otherS3Key
      internalS3Key
      type
      validFrom
      validTo
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      id
      tenantId
      deleted
      companyName
      companyVat
      fiscalCode
      zipCode
      county
      municipality
      address
      country
      recipientCode
      email
      mobile
      extensions
      costCenterId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      costCenter {
        id
        tenantId
        name
        description
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      id
      tenantId
      deleted
      companyName
      companyVat
      fiscalCode
      zipCode
      county
      municipality
      address
      country
      recipientCode
      email
      mobile
      extensions
      costCenterId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      costCenter {
        id
        tenantId
        name
        description
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createCostCenter = /* GraphQL */ `
  mutation CreateCostCenter(
    $input: CreateCostCenterInput!
    $condition: ModelCostCenterConditionInput
  ) {
    createCostCenter(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      sorting
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateCostCenter = /* GraphQL */ `
  mutation UpdateCostCenter(
    $input: UpdateCostCenterInput!
    $condition: ModelCostCenterConditionInput
  ) {
    updateCostCenter(input: $input, condition: $condition) {
      id
      tenantId
      name
      description
      sorting
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
