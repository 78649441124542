import React from "react";
import { Switch } from "@material-ui/core";

export function AutorenewColumnFormatter(
	cellContent,
	row,
	rowIndex,
	{ toggleAutorenew, autorenewIsLoadingId }
) {
	return (
		<div>
			{autorenewIsLoadingId === row.id ? (
				<div className="d-flex justify-content-center">
					<div className="spinner text-center"></div>
				</div>
			) : (
				<Switch
					checked={row.autorenew}
					onChange={() => toggleAutorenew(row.id)}
					disabled={new Date(row.validTo) <= new Date()}
				/>
			)}
		</div>
	);
}