/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrency = /* GraphQL */ `
  query GetCurrency($id: ID!) {
    getCurrency(id: $id) {
      id
      name
      symbol
      code
      createdAt
      updatedAt
    }
  }
`;
export const listCurrencys = /* GraphQL */ `
  query ListCurrencys(
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getECRConfigurationFavorite = /* GraphQL */ `
  query GetECRConfigurationFavorite($id: ID!) {
    getECRConfigurationFavorite(id: $id) {
      id
      name
      partnerId
      plu
      content
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listECRConfigurationFavorites = /* GraphQL */ `
  query ListECRConfigurationFavorites(
    $filter: ModelECRConfigurationFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRConfigurationFavorites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        partnerId
        plu
        content
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPartnerNote = /* GraphQL */ `
  query GetPartnerNote($id: ID!) {
    getPartnerNote(id: $id) {
      id
      partnerId
      tenantId
      ecrNumber
      companyName
      retailStoreName
      description
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPartnerNotes = /* GraphQL */ `
  query ListPartnerNotes(
    $filter: ModelPartnerNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        tenantId
        ecrNumber
        companyName
        retailStoreName
        description
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRtUtilityLog = /* GraphQL */ `
  query GetRtUtilityLog($id: ID!) {
    getRtUtilityLog(id: $id) {
      id
      partnerId
      userId
      tenantId
      tenantCompanyName
      tenantCompanyVat
      userAttribute
      number
      description
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const listRtUtilityLogs = /* GraphQL */ `
  query ListRtUtilityLogs(
    $filter: ModelRtUtilityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRtUtilityLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        userId
        tenantId
        tenantCompanyName
        tenantCompanyVat
        userAttribute
        number
        description
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const configurationFavoritesByPartner = /* GraphQL */ `
  query ConfigurationFavoritesByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelECRConfigurationFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    configurationFavoritesByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        partnerId
        plu
        content
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const partnerNotesByPartner = /* GraphQL */ `
  query PartnerNotesByPartner(
    $partnerId: ID
    $companyName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerNotesByPartner(
      partnerId: $partnerId
      companyName: $companyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        tenantId
        ecrNumber
        companyName
        retailStoreName
        description
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rtUtilityLogsByPartner = /* GraphQL */ `
  query RtUtilityLogsByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRtUtilityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rtUtilityLogsByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        tenantId
        tenantCompanyName
        tenantCompanyVat
        userAttribute
        number
        description
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      enabled
      logo
      currency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      shortCode
      isPartner
      partnershipStartDate
      isServiceManager
      nextItemSorting
      nextItemGroupSorting
      defaultPartnerCode
      companyName
      companyVat
      firstUserId
      firstRetailStoreId
      userCount
      moitoIotCount
      retailStoreCount
      cashRegisterCount
      rtUtilityInactive
      currentRtUtilityCategory
      partnerText
      partnerTosAccepted
      partnerTosAcceptedVersion
      privacyPolicyAcceptedDate
      archismallUsername
      archismallPassword
      archismallActiveInvoicesLastDatetime
      archismallPassiveInvoicesLastDatetime
      isBetaTester
      partnerCashRegisterCounter
      partnerMoitoIotCounter
      extensions
      mobile
      email
      zipCode
      county
      municipality
      address
      country
      isAccountant
      accountantId
      stripeAccountId
      stripeRegistrationCompleted
      blockLevel
      dailyReportMode
      createdAt
      updatedAt
      tenantUser {
        items {
          id
          userId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      party {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tenantsByShortCode = /* GraphQL */ `
  query TenantsByShortCode(
    $shortCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByShortCode(
      shortCode: $shortCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tenantsByCompanyVat = /* GraphQL */ `
  query TenantsByCompanyVat(
    $companyVat: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByCompanyVat(
      companyVat: $companyVat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tenantsByDefaultPartnerCode = /* GraphQL */ `
  query TenantsByDefaultPartnerCode(
    $defaultPartnerCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByDefaultPartnerCode(
      defaultPartnerCode: $defaultPartnerCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchTenants = /* GraphQL */ `
  query SearchTenants(
    $filter: SearchableTenantFilterInput
    $sort: SearchableTenantSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTenants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      partyId
      enabled
      tenantDefault
      retailStoreDefault
      showTutorial
      email
      mobile
      pushNotificationTokens {
        service
        deviceToken
      }
      deleted
      firstName
      lastName
      createdAt
      updatedAt
      tenantUser {
        items {
          id
          userId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      userPrivilege {
        items {
          id
          userId
          privilegeId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      userLicense {
        items {
          id
          tenantId
          userId
          licenseId
          validFrom
          validTo
          createdAt
          updatedAt
        }
        nextToken
      }
      userRetailStore {
        items {
          id
          userId
          retailStoreId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPartnershipRequest = /* GraphQL */ `
  query GetPartnershipRequest($id: ID!) {
    getPartnershipRequest(id: $id) {
      id
      tenantId
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const listPartnershipRequests = /* GraphQL */ `
  query ListPartnershipRequests(
    $filter: ModelPartnershipRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnershipRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getLicense = /* GraphQL */ `
  query GetLicense($id: ID!) {
    getLicense(id: $id) {
      id
      name
      commercialName
      baseName
      erpItemId
      createdAt
      updatedAt
    }
  }
`;
export const listLicenses = /* GraphQL */ `
  query ListLicenses(
    $filter: ModelLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppSettings = /* GraphQL */ `
  query GetAppSettings($id: ID!) {
    getAppSettings(id: $id) {
      id
      firstTenantUserPrivilegeGroups
      defaultCurrency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      oldEJExpDate
      lastTobaccoItemsS3Key
      lastTobaccoBarcodesS3Key
      licensesByDealerS3Key
      createdAt
      updatedAt
      defaultPartner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      AtorderLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      AtorderDeliveryLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EInvoiceItLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityFwDownloadLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityLogsLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserCollectorLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotAutomaticUpdateLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotMultipleUsersLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotOnlineBackupLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotCollectorLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotDealerLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotCateringLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUtilityEJExtensionLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      CalendarLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      ForValueLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      RTUserAccountantLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJManagementLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJOneYearLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJTwoYearLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      EJNoLimitLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      OpticalLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotKioskLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      MoitoIotTobaccoLicense {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      partnerPrivilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      serviceManagerPrivilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      lastMoitoIotStableVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      lastMoitoIotBetaVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listAppSettingss = /* GraphQL */ `
  query ListAppSettingss(
    $filter: ModelAppSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstTenantUserPrivilegeGroups
        defaultCurrency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        oldEJExpDate
        lastTobaccoItemsS3Key
        lastTobaccoBarcodesS3Key
        licensesByDealerS3Key
        createdAt
        updatedAt
        defaultPartner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        AtorderLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        AtorderDeliveryLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        EInvoiceItLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUserLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUtilityLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUtilityFwDownloadLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUtilityLogsLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUserCollectorLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotAutomaticUpdateLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotMultipleUsersLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotOnlineBackupLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotCollectorLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotDealerLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotCateringLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUtilityEJExtensionLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        CalendarLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        ForValueLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUserAccountantLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        EJManagementLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        EJOneYearLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        EJTwoYearLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        EJNoLimitLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        OpticalLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotKioskLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotTobaccoLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        partnerPrivilegeGroup {
          id
          name
          createdAt
          updatedAt
        }
        serviceManagerPrivilegeGroup {
          id
          name
          createdAt
          updatedAt
        }
        lastMoitoIotStableVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        lastMoitoIotBetaVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getLicenseRequest = /* GraphQL */ `
  query GetLicenseRequest($id: ID!) {
    getLicenseRequest(id: $id) {
      id
      tenantId
      partnerId
      retailStoreId
      duration
      deleted
      extensions
      startFromDate
      level
      isFree
      autorenew
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      applicantUser {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listLicenseRequests = /* GraphQL */ `
  query ListLicenseRequests(
    $filter: ModelLicenseRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenseRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        partnerId
        retailStoreId
        duration
        deleted
        extensions
        startFromDate
        level
        isFree
        autorenew
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        applicantUser {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const licenseRequestsByPartner = /* GraphQL */ `
  query LicenseRequestsByPartner(
    $partnerId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicenseRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    licenseRequestsByPartner(
      partnerId: $partnerId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        partnerId
        retailStoreId
        duration
        deleted
        extensions
        startFromDate
        level
        isFree
        autorenew
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        applicantUser {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const licenseRequestsByRetailStore = /* GraphQL */ `
  query LicenseRequestsByRetailStore(
    $retailStoreId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicenseRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    licenseRequestsByRetailStore(
      retailStoreId: $retailStoreId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        partnerId
        retailStoreId
        duration
        deleted
        extensions
        startFromDate
        level
        isFree
        autorenew
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        applicantUser {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPrivilege = /* GraphQL */ `
  query GetPrivilege($id: ID!) {
    getPrivilege(id: $id) {
      id
      name
      description
      ability
      entity
      createdAt
      updatedAt
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      privilegeGroups {
        items {
          id
          privilegeId
          privilegeGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      userPrivilege {
        items {
          id
          userId
          privilegeId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPrivileges = /* GraphQL */ `
  query ListPrivileges(
    $filter: ModelPrivilegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivileges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPrivilegePrivilegeGroup = /* GraphQL */ `
  query GetPrivilegePrivilegeGroup($id: ID!) {
    getPrivilegePrivilegeGroup(id: $id) {
      id
      privilegeId
      privilegeGroupId
      createdAt
      updatedAt
      privilege {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
      privilegeGroup {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
    }
  }
`;
export const listPrivilegePrivilegeGroups = /* GraphQL */ `
  query ListPrivilegePrivilegeGroups(
    $filter: ModelPrivilegePrivilegeGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivilegePrivilegeGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privilegeId
        privilegeGroupId
        createdAt
        updatedAt
        privilege {
          id
          name
          description
          ability
          entity
          createdAt
          updatedAt
        }
        privilegeGroup {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPrivilegeGroup = /* GraphQL */ `
  query GetPrivilegeGroup($id: ID!) {
    getPrivilegeGroup(id: $id) {
      id
      name
      createdAt
      updatedAt
      privileges {
        items {
          id
          privilegeId
          privilegeGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPrivilegeGroups = /* GraphQL */ `
  query ListPrivilegeGroups(
    $filter: ModelPrivilegeGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivilegeGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        privileges {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTenantUser = /* GraphQL */ `
  query GetTenantUser($id: ID!) {
    getTenantUser(id: $id) {
      id
      userId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const listTenantUsers = /* GraphQL */ `
  query ListTenantUsers(
    $filter: ModelTenantUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tenantUsersByTenant = /* GraphQL */ `
  query TenantUsersByTenant(
    $tenantId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTenantUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantUsersByTenant(
      tenantId: $tenantId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tenantUsersByUser = /* GraphQL */ `
  query TenantUsersByUser(
    $userId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTenantUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantUsersByUser(
      userId: $userId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserPrivilege = /* GraphQL */ `
  query GetUserPrivilege($id: ID!) {
    getUserPrivilege(id: $id) {
      id
      userId
      privilegeId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      privilege {
        id
        name
        description
        ability
        entity
        createdAt
        updatedAt
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        privilegeGroups {
          nextToken
        }
        userPrivilege {
          nextToken
        }
      }
    }
  }
`;
export const listUserPrivileges = /* GraphQL */ `
  query ListUserPrivileges(
    $filter: ModelUserPrivilegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPrivileges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        privilegeId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        privilege {
          id
          name
          description
          ability
          entity
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userPrivilegesByUser = /* GraphQL */ `
  query UserPrivilegesByUser(
    $userId: ID
    $privilegeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPrivilegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userPrivilegesByUser(
      userId: $userId
      privilegeId: $privilegeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        privilegeId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        privilege {
          id
          name
          description
          ability
          entity
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userPrivilegesByPrivilege = /* GraphQL */ `
  query UserPrivilegesByPrivilege(
    $privilegeId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPrivilegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userPrivilegesByPrivilege(
      privilegeId: $privilegeId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        privilegeId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        privilege {
          id
          name
          description
          ability
          entity
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserLicense = /* GraphQL */ `
  query GetUserLicense($id: ID!) {
    getUserLicense(id: $id) {
      id
      tenantId
      userId
      licenseId
      validFrom
      validTo
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      retailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listUserLicenses = /* GraphQL */ `
  query ListUserLicenses(
    $filter: ModelUserLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        userId
        licenseId
        validFrom
        validTo
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        retailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userLicensesByUser = /* GraphQL */ `
  query UserLicensesByUser(
    $userId: ID
    $licenseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userLicensesByUser(
      userId: $userId
      licenseId: $licenseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        userId
        licenseId
        validFrom
        validTo
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        retailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userLicensesByLicense = /* GraphQL */ `
  query UserLicensesByLicense(
    $licenseId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userLicensesByLicense(
      licenseId: $licenseId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        userId
        licenseId
        validFrom
        validTo
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        retailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userLicensesByTenant = /* GraphQL */ `
  query UserLicensesByTenant(
    $tenantId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userLicensesByTenant(
      tenantId: $tenantId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        userId
        licenseId
        validFrom
        validTo
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        retailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRetailStoreLicense = /* GraphQL */ `
  query GetRetailStoreLicense($id: ID!) {
    getRetailStoreLicense(id: $id) {
      id
      retailStoreId
      licenseId
      partnerId
      tenantId
      validFrom
      validTo
      assignedToUser
      activatedAt
      number
      electronicCashRegisterId
      moitoIotCode
      moitoIotId
      level
      isFree
      autorenew
      orderConfirmationNotified
      firmware
      ecrCompanyName
      ecrCompanyVat
      ecrRetailStoreName
      adminNotified
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      approvedBy {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      renewedRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listRetailStoreLicenses = /* GraphQL */ `
  query ListRetailStoreLicenses(
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetailStoreLicenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreLicensesByRetailStore = /* GraphQL */ `
  query RetailStoreLicensesByRetailStore(
    $retailStoreId: ID
    $partnerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLicensesByRetailStore(
      retailStoreId: $retailStoreId
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreLicensesByPartner = /* GraphQL */ `
  query RetailStoreLicensesByPartner(
    $partnerId: ID
    $retailStoreId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLicensesByPartner(
      partnerId: $partnerId
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreLicensesByTenant = /* GraphQL */ `
  query RetailStoreLicensesByTenant(
    $tenantId: ID
    $retailStoreId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLicensesByTenant(
      tenantId: $tenantId
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreLicensesByLicense = /* GraphQL */ `
  query RetailStoreLicensesByLicense(
    $licenseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLicensesByLicense(
      licenseId: $licenseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreLicensesByLicenseRetailStore = /* GraphQL */ `
  query RetailStoreLicensesByLicenseRetailStore(
    $licenseId: ID
    $retailStoreId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLicensesByLicenseRetailStore(
      licenseId: $licenseId
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchRetailStoreLicenses = /* GraphQL */ `
  query SearchRetailStoreLicenses(
    $filter: SearchableRetailStoreLicenseFilterInput
    $sort: SearchableRetailStoreLicenseSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRetailStoreLicenses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        adminNotified
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        approvedBy {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getParty = /* GraphQL */ `
  query GetParty($id: ID!) {
    getParty(id: $id) {
      id
      firstName
      lastName
      companyName
      companyVat
      fiscalCode
      email
      logo
      mobile
      zipCode
      county
      municipality
      address
      country
      role
      recipientCode
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const listPartys = /* GraphQL */ `
  query ListPartys(
    $filter: ModelPartyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      tenantId
      description
      createdByModule
      deleted
      companyName
      companyVat
      firstName
      lastName
      mobile
      visible
      fiscalCode
      email
      zipCode
      county
      municipality
      address
      country
      recipientCode
      extensions
      latitude
      longitude
      addressPlaceId
      loyaltyPoints
      addressNotes
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      vatRate {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      autoDiscount {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      prepaidCard {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      pointsCard {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const customersByTenant = /* GraphQL */ `
  query CustomersByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const customersByCompanyVat = /* GraphQL */ `
  query CustomersByCompanyVat(
    $companyVat: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByCompanyVat(
      companyVat: $companyVat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const customersByFiscalCode = /* GraphQL */ `
  query CustomersByFiscalCode(
    $fiscalCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByFiscalCode(
      fiscalCode: $fiscalCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const customersByMobile = /* GraphQL */ `
  query CustomersByMobile(
    $mobile: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByMobile(
      mobile: $mobile
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: SearchableCustomerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getSalesOrder = /* GraphQL */ `
  query GetSalesOrder($id: ID!) {
    getSalesOrder(id: $id) {
      id
      tenantId
      customerId
      retailStoreId
      notes
      extensions
      createdByModule
      deleted
      atorderSessionId
      atorderStatus
      atorderType
      atorderOldDatetime
      datetime
      lastCustomerUpdate
      customerCreationDatetime
      amount
      paymentType
      stripePaymentIntent
      stripePaymentStatus
      stripeRefund
      orderNumber
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      salesOrderLines {
        items {
          id
          tenantId
          salesOrderId
          itemId
          price
          quantity
          notes
          extensions
          sorting
          totalAmount
          vatRate
          modifiedByModule
          createdAt
          updatedAt
        }
        nextToken
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreLocation {
        id
        tenantId
        retailStoreRoomId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSalesOrders = /* GraphQL */ `
  query ListSalesOrders(
    $filter: ModelSalesOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        customerId
        retailStoreId
        notes
        extensions
        createdByModule
        deleted
        atorderSessionId
        atorderStatus
        atorderType
        atorderOldDatetime
        datetime
        lastCustomerUpdate
        customerCreationDatetime
        amount
        paymentType
        stripePaymentIntent
        stripePaymentStatus
        stripeRefund
        orderNumber
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        salesOrderLines {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreLocation {
          id
          tenantId
          retailStoreRoomId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const salesOrdersByRetailStore = /* GraphQL */ `
  query SalesOrdersByRetailStore(
    $retailStoreId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesOrdersByRetailStore(
      retailStoreId: $retailStoreId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        customerId
        retailStoreId
        notes
        extensions
        createdByModule
        deleted
        atorderSessionId
        atorderStatus
        atorderType
        atorderOldDatetime
        datetime
        lastCustomerUpdate
        customerCreationDatetime
        amount
        paymentType
        stripePaymentIntent
        stripePaymentStatus
        stripeRefund
        orderNumber
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        salesOrderLines {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreLocation {
          id
          tenantId
          retailStoreRoomId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSalesOrderLine = /* GraphQL */ `
  query GetSalesOrderLine($id: ID!) {
    getSalesOrderLine(id: $id) {
      id
      tenantId
      salesOrderId
      itemId
      price
      quantity
      notes
      extensions
      sorting
      totalAmount
      vatRate
      modifiedByModule
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      salesOrder {
        id
        tenantId
        customerId
        retailStoreId
        notes
        extensions
        createdByModule
        deleted
        atorderSessionId
        atorderStatus
        atorderType
        atorderOldDatetime
        datetime
        lastCustomerUpdate
        customerCreationDatetime
        amount
        paymentType
        stripePaymentIntent
        stripePaymentStatus
        stripeRefund
        orderNumber
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        salesOrderLines {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreLocation {
          id
          tenantId
          retailStoreRoomId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
    }
  }
`;
export const listSalesOrderLines = /* GraphQL */ `
  query ListSalesOrderLines(
    $filter: ModelSalesOrderLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesOrderLines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        salesOrderId
        itemId
        price
        quantity
        notes
        extensions
        sorting
        totalAmount
        vatRate
        modifiedByModule
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        salesOrder {
          id
          tenantId
          customerId
          retailStoreId
          notes
          extensions
          createdByModule
          deleted
          atorderSessionId
          atorderStatus
          atorderType
          atorderOldDatetime
          datetime
          lastCustomerUpdate
          customerCreationDatetime
          amount
          paymentType
          stripePaymentIntent
          stripePaymentStatus
          stripeRefund
          orderNumber
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const salesOrderLinesBySalesOrder = /* GraphQL */ `
  query SalesOrderLinesBySalesOrder(
    $salesOrderId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesOrderLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesOrderLinesBySalesOrder(
      salesOrderId: $salesOrderId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        salesOrderId
        itemId
        price
        quantity
        notes
        extensions
        sorting
        totalAmount
        vatRate
        modifiedByModule
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        salesOrder {
          id
          tenantId
          customerId
          retailStoreId
          notes
          extensions
          createdByModule
          deleted
          atorderSessionId
          atorderStatus
          atorderType
          atorderOldDatetime
          datetime
          lastCustomerUpdate
          customerCreationDatetime
          amount
          paymentType
          stripePaymentIntent
          stripePaymentStatus
          stripeRefund
          orderNumber
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      tenantId
      name
      description
      images
      atorderType
      sorting
      vatRate
      vatCode
      extensions
      createdByModule
      deleted
      qtyOnHand
      qtyOnHandByStoreId
      enabledRetailStoreIds
      loyaltyPoints
      time
      image
      externalId
      atorderHide
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      itemGroup {
        items {
          id
          tenantId
          itemId
          itemGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      priceList {
        items {
          id
          tenantId
          itemId
          priceListId
          price
          deleted
          extensions
          createdAt
          updatedAt
        }
        nextToken
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      variantGroups {
        items {
          id
          tenantId
          variantGroupId
          itemId
          createdAt
          updatedAt
        }
        nextToken
      }
      logicalPrinters {
        items {
          id
          tenantId
          retailStoreId
          itemId
          logicalPrinterId
          isMain
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const itemsByTenant = /* GraphQL */ `
  query ItemsByTenant(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByTenant(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const itemsByName = /* GraphQL */ `
  query ItemsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const itemsByExternalId = /* GraphQL */ `
  query ItemsByExternalId(
    $externalId: String
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByExternalId(
      externalId: $externalId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchItems = /* GraphQL */ `
  query SearchItems(
    $filter: SearchableItemFilterInput
    $sort: SearchableItemSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchItems(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getItemItemGroup = /* GraphQL */ `
  query GetItemItemGroup($id: ID!) {
    getItemItemGroup(id: $id) {
      id
      tenantId
      itemId
      itemGroupId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      itemGroup {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
    }
  }
`;
export const listItemItemGroups = /* GraphQL */ `
  query ListItemItemGroups(
    $filter: ModelItemItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemItemGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        itemId
        itemGroupId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        itemGroup {
          id
          tenantId
          name
          description
          sorting
          extensions
          createdByModule
          atorderType
          deleted
          enabledRetailStoreIds
          color
          isCover
          type
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemItemGroupsByItem = /* GraphQL */ `
  query ItemItemGroupsByItem(
    $itemId: ID
    $itemGroupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemItemGroupsByItem(
      itemId: $itemId
      itemGroupId: $itemGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        itemId
        itemGroupId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        itemGroup {
          id
          tenantId
          name
          description
          sorting
          extensions
          createdByModule
          atorderType
          deleted
          enabledRetailStoreIds
          color
          isCover
          type
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemItemGroupsByItemGroup = /* GraphQL */ `
  query ItemItemGroupsByItemGroup(
    $itemGroupId: ID
    $itemId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemItemGroupsByItemGroup(
      itemGroupId: $itemGroupId
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        itemId
        itemGroupId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        itemGroup {
          id
          tenantId
          name
          description
          sorting
          extensions
          createdByModule
          atorderType
          deleted
          enabledRetailStoreIds
          color
          isCover
          type
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemItemGroupsByTenant = /* GraphQL */ `
  query ItemItemGroupsByTenant(
    $tenantId: ID
    $itemId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemItemGroupsByTenant(
      tenantId: $tenantId
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        itemId
        itemGroupId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        itemGroup {
          id
          tenantId
          name
          description
          sorting
          extensions
          createdByModule
          atorderType
          deleted
          enabledRetailStoreIds
          color
          isCover
          type
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getItemGroup = /* GraphQL */ `
  query GetItemGroup($id: ID!) {
    getItemGroup(id: $id) {
      id
      tenantId
      name
      description
      sorting
      extensions
      createdByModule
      atorderType
      deleted
      enabledRetailStoreIds
      color
      isCover
      type
      externalId
      atorderHide
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        items {
          id
          tenantId
          itemId
          itemGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listItemGroups = /* GraphQL */ `
  query ListItemGroups(
    $filter: ModelItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const itemGroupsByTenant = /* GraphQL */ `
  query ItemGroupsByTenant(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemGroupsByTenant(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const itemGroupsByExternalId = /* GraphQL */ `
  query ItemGroupsByExternalId(
    $externalId: String
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemGroupsByExternalId(
      externalId: $externalId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const itemGroupsByName = /* GraphQL */ `
  query ItemGroupsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemGroupsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchItemGroups = /* GraphQL */ `
  query SearchItemGroups(
    $filter: SearchableItemGroupFilterInput
    $sort: SearchableItemGroupSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchItemGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        name
        description
        sorting
        extensions
        createdByModule
        atorderType
        deleted
        enabledRetailStoreIds
        color
        isCover
        type
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getItemPriceList = /* GraphQL */ `
  query GetItemPriceList($id: ID!) {
    getItemPriceList(id: $id) {
      id
      tenantId
      itemId
      priceListId
      currency {
        id
        name
        symbol
        code
        createdAt
        updatedAt
      }
      price
      deleted
      extensions
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listItemPriceLists = /* GraphQL */ `
  query ListItemPriceLists(
    $filter: ModelItemPriceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemPriceLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        itemId
        priceListId
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        price
        deleted
        extensions
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemPriceListsByItem = /* GraphQL */ `
  query ItemPriceListsByItem(
    $itemId: ID
    $priceListId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemPriceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemPriceListsByItem(
      itemId: $itemId
      priceListId: $priceListId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        itemId
        priceListId
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        price
        deleted
        extensions
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemPriceListsByPriceList = /* GraphQL */ `
  query ItemPriceListsByPriceList(
    $priceListId: ID
    $itemId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemPriceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemPriceListsByPriceList(
      priceListId: $priceListId
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        itemId
        priceListId
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        price
        deleted
        extensions
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemPriceListsByTenant = /* GraphQL */ `
  query ItemPriceListsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemPriceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemPriceListsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        itemId
        priceListId
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        price
        deleted
        extensions
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPriceList = /* GraphQL */ `
  query GetPriceList($id: ID!) {
    getPriceList(id: $id) {
      id
      tenantId
      retailStoreId
      name
      extensions
      createdByModule
      sorting
      deleted
      visible
      enabled
      enowebId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        items {
          id
          tenantId
          itemId
          priceListId
          price
          deleted
          extensions
          createdAt
          updatedAt
        }
        nextToken
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listPriceLists = /* GraphQL */ `
  query ListPriceLists(
    $filter: ModelPriceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const priceListsByTenant = /* GraphQL */ `
  query PriceListsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPriceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priceListsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const priceListsByRetailStore = /* GraphQL */ `
  query PriceListsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPriceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priceListsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRetailStore = /* GraphQL */ `
  query GetRetailStore($id: ID!) {
    getRetailStore(id: $id) {
      id
      tenantId
      name
      extensions
      deleted
      partnerId
      zipCode
      county
      municipality
      address
      country
      latitude
      longitude
      accountantId
      isSharedTenant
      iban
      enowebMemId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStoreLicense {
        items {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          ecrCompanyName
          ecrCompanyVat
          ecrRetailStoreName
          adminNotified
          createdAt
          updatedAt
        }
        nextToken
      }
      party {
        id
        firstName
        lastName
        companyName
        companyVat
        fiscalCode
        email
        logo
        mobile
        zipCode
        county
        municipality
        address
        country
        role
        recipientCode
        latitude
        longitude
        createdAt
        updatedAt
      }
      user {
        items {
          id
          userId
          retailStoreId
          tenantId
          createdAt
          updatedAt
        }
        nextToken
      }
      invoiceSectional {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      cashRegisters {
        items {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRetailStores = /* GraphQL */ `
  query ListRetailStores(
    $filter: ModelRetailStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetailStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const retailStoresByTenant = /* GraphQL */ `
  query RetailStoresByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoresByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const retailStoresByPartner = /* GraphQL */ `
  query RetailStoresByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoresByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRetailStoreRoom = /* GraphQL */ `
  query GetRetailStoreRoom($id: ID!) {
    getRetailStoreRoom(id: $id) {
      id
      tenantId
      retailStoreId
      name
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      coverItem {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      logicalPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listRetailStoreRooms = /* GraphQL */ `
  query ListRetailStoreRooms(
    $filter: ModelRetailStoreRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetailStoreRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreRoomsByRetailStore = /* GraphQL */ `
  query RetailStoreRoomsByRetailStore(
    $retailStoreId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreRoomsByRetailStore(
      retailStoreId: $retailStoreId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRetailStoreLocation = /* GraphQL */ `
  query GetRetailStoreLocation($id: ID!) {
    getRetailStoreLocation(id: $id) {
      id
      tenantId
      retailStoreRoomId
      retailStoreId
      name
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreRoom {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listRetailStoreLocations = /* GraphQL */ `
  query ListRetailStoreLocations(
    $filter: ModelRetailStoreLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetailStoreLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreRoomId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreLocationsByRetailStoreRoom = /* GraphQL */ `
  query RetailStoreLocationsByRetailStoreRoom(
    $retailStoreRoomId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLocationsByRetailStoreRoom(
      retailStoreRoomId: $retailStoreRoomId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreRoomId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreLocationsByRetailStore = /* GraphQL */ `
  query RetailStoreLocationsByRetailStore(
    $retailStoreId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLocationsByRetailStore(
      retailStoreId: $retailStoreId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreRoomId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const retailStoreLocationsByRetailStoreName = /* GraphQL */ `
  query RetailStoreLocationsByRetailStoreName(
    $retailStoreId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLocationsByRetailStoreName(
      retailStoreId: $retailStoreId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreRoomId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserRetailStore = /* GraphQL */ `
  query GetUserRetailStore($id: ID!) {
    getUserRetailStore(id: $id) {
      id
      userId
      retailStoreId
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listUserRetailStores = /* GraphQL */ `
  query ListUserRetailStores(
    $filter: ModelUserRetailStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRetailStores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        retailStoreId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userRetailStoresByUser = /* GraphQL */ `
  query UserRetailStoresByUser(
    $userId: ID
    $retailStoreId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRetailStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRetailStoresByUser(
      userId: $userId
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        retailStoreId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userRetailStoresByRetailStore = /* GraphQL */ `
  query UserRetailStoresByRetailStore(
    $retailStoreId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRetailStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRetailStoresByRetailStore(
      retailStoreId: $retailStoreId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        retailStoreId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      tenantId
      userId
      type
      status
      context
      text
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      news {
        id
        title
        description
        notified
        type
        attribute
        createdAt
        updatedAt
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        userId
        type
        status
        context
        text
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        news {
          id
          title
          description
          notified
          type
          attribute
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const notificationsByTenant = /* GraphQL */ `
  query NotificationsByTenant(
    $tenantId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByTenant(
      tenantId: $tenantId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        userId
        type
        status
        context
        text
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        news {
          id
          title
          description
          notified
          type
          attribute
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const notificationsByUser = /* GraphQL */ `
  query NotificationsByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        userId
        type
        status
        context
        text
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        news {
          id
          title
          description
          notified
          type
          attribute
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getInvoiceSectional = /* GraphQL */ `
  query GetInvoiceSectional($id: ID!) {
    getInvoiceSectional(id: $id) {
      id
      tenantId
      partnerId
      code
      description
      deleted
      nextNumber
      retailStoreId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listInvoiceSectionals = /* GraphQL */ `
  query ListInvoiceSectionals(
    $filter: ModelInvoiceSectionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceSectionals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const invoiceSectionalsByTenant = /* GraphQL */ `
  query InvoiceSectionalsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceSectionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceSectionalsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const invoiceSectionalsByRetailStore = /* GraphQL */ `
  query InvoiceSectionalsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceSectionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceSectionalsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSalesInvoice = /* GraphQL */ `
  query GetSalesInvoice($id: ID!) {
    getSalesInvoice(id: $id) {
      id
      tenantId
      retailStoreId
      customerId
      number
      invoiceId
      date
      type
      taxRegime
      paymentCode
      paymentMode
      paymentAmount
      iban
      notes
      customerFiscalCode
      customerCompanyVat
      customerCompanyName
      customerZipCode
      customerCounty
      customerMunicipality
      customerAddress
      customerCountry
      customerRecipientCode
      customerEmail
      extensions
      isPAInvoice
      sentToArchismall
      pricesWithoutVat
      createdByModule
      xmlS3Key
      pdfS3Key
      archismallProgressiveId
      status
      idSdi
      idArchismall
      errorDescription
      emailNotificationSent
      writeOffInvoiceId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      sectional {
        id
        tenantId
        partnerId
        code
        description
        deleted
        nextNumber
        retailStoreId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      salesInvoiceLines {
        items {
          id
          tenantId
          salesInvoiceId
          vatRate
          vatCode
          vatCodeDescription
          lineNum
          price
          quantity
          unitPrice
          taxableAmount
          vatAmount
          discountType
          discountAmount
          extensions
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSalesInvoices = /* GraphQL */ `
  query ListSalesInvoices(
    $filter: ModelSalesInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const salesInvoicesByTenant = /* GraphQL */ `
  query SalesInvoicesByTenant(
    $tenantId: ID
    $customerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesInvoicesByTenant(
      tenantId: $tenantId
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const salesInvoicesByTenantSortData = /* GraphQL */ `
  query SalesInvoicesByTenantSortData(
    $tenantId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesInvoicesByTenantSortData(
      tenantId: $tenantId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const salesInvoicesByRetailStore = /* GraphQL */ `
  query SalesInvoicesByRetailStore(
    $retailStoreId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesInvoicesByRetailStore(
      retailStoreId: $retailStoreId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const salesInvoicesByCustomer = /* GraphQL */ `
  query SalesInvoicesByCustomer(
    $customerId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesInvoicesByCustomer(
      customerId: $customerId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSalesInvoiceLine = /* GraphQL */ `
  query GetSalesInvoiceLine($id: ID!) {
    getSalesInvoiceLine(id: $id) {
      id
      tenantId
      salesInvoiceId
      vatRate
      vatCode
      vatCodeDescription
      lineNum
      price
      quantity
      unitPrice
      taxableAmount
      vatAmount
      discountType
      discountAmount
      extensions
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      salesInvoice {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
    }
  }
`;
export const listSalesInvoiceLines = /* GraphQL */ `
  query ListSalesInvoiceLines(
    $filter: ModelSalesInvoiceLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesInvoiceLines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        salesInvoiceId
        vatRate
        vatCode
        vatCodeDescription
        lineNum
        price
        quantity
        unitPrice
        taxableAmount
        vatAmount
        discountType
        discountAmount
        extensions
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        salesInvoice {
          id
          tenantId
          retailStoreId
          customerId
          number
          invoiceId
          date
          type
          taxRegime
          paymentCode
          paymentMode
          paymentAmount
          iban
          notes
          customerFiscalCode
          customerCompanyVat
          customerCompanyName
          customerZipCode
          customerCounty
          customerMunicipality
          customerAddress
          customerCountry
          customerRecipientCode
          customerEmail
          extensions
          isPAInvoice
          sentToArchismall
          pricesWithoutVat
          createdByModule
          xmlS3Key
          pdfS3Key
          archismallProgressiveId
          status
          idSdi
          idArchismall
          errorDescription
          emailNotificationSent
          writeOffInvoiceId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const salesInvoiceLinesBySalesInvoice = /* GraphQL */ `
  query SalesInvoiceLinesBySalesInvoice(
    $salesInvoiceId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSalesInvoiceLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesInvoiceLinesBySalesInvoice(
      salesInvoiceId: $salesInvoiceId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        salesInvoiceId
        vatRate
        vatCode
        vatCodeDescription
        lineNum
        price
        quantity
        unitPrice
        taxableAmount
        vatAmount
        discountType
        discountAmount
        extensions
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        salesInvoice {
          id
          tenantId
          retailStoreId
          customerId
          number
          invoiceId
          date
          type
          taxRegime
          paymentCode
          paymentMode
          paymentAmount
          iban
          notes
          customerFiscalCode
          customerCompanyVat
          customerCompanyName
          customerZipCode
          customerCounty
          customerMunicipality
          customerAddress
          customerCountry
          customerRecipientCode
          customerEmail
          extensions
          isPAInvoice
          sentToArchismall
          pricesWithoutVat
          createdByModule
          xmlS3Key
          pdfS3Key
          archismallProgressiveId
          status
          idSdi
          idArchismall
          errorDescription
          emailNotificationSent
          writeOffInvoiceId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getEInvoiceTransmission = /* GraphQL */ `
  query GetEInvoiceTransmission($id: ID!) {
    getEInvoiceTransmission(id: $id) {
      id
      tenantId
      salesInvoiceId
      retailStoreId
      datetime
      status
      details
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      salesInvoice {
        id
        tenantId
        retailStoreId
        customerId
        number
        invoiceId
        date
        type
        taxRegime
        paymentCode
        paymentMode
        paymentAmount
        iban
        notes
        customerFiscalCode
        customerCompanyVat
        customerCompanyName
        customerZipCode
        customerCounty
        customerMunicipality
        customerAddress
        customerCountry
        customerRecipientCode
        customerEmail
        extensions
        isPAInvoice
        sentToArchismall
        pricesWithoutVat
        createdByModule
        xmlS3Key
        pdfS3Key
        archismallProgressiveId
        status
        idSdi
        idArchismall
        errorDescription
        emailNotificationSent
        writeOffInvoiceId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        sectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        salesInvoiceLines {
          nextToken
        }
      }
    }
  }
`;
export const listEInvoiceTransmissions = /* GraphQL */ `
  query ListEInvoiceTransmissions(
    $filter: ModelEInvoiceTransmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEInvoiceTransmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        salesInvoiceId
        retailStoreId
        datetime
        status
        details
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        salesInvoice {
          id
          tenantId
          retailStoreId
          customerId
          number
          invoiceId
          date
          type
          taxRegime
          paymentCode
          paymentMode
          paymentAmount
          iban
          notes
          customerFiscalCode
          customerCompanyVat
          customerCompanyName
          customerZipCode
          customerCounty
          customerMunicipality
          customerAddress
          customerCountry
          customerRecipientCode
          customerEmail
          extensions
          isPAInvoice
          sentToArchismall
          pricesWithoutVat
          createdByModule
          xmlS3Key
          pdfS3Key
          archismallProgressiveId
          status
          idSdi
          idArchismall
          errorDescription
          emailNotificationSent
          writeOffInvoiceId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const einvoiceTransmissionsByRetailStore = /* GraphQL */ `
  query EinvoiceTransmissionsByRetailStore(
    $retailStoreId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEInvoiceTransmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    einvoiceTransmissionsByRetailStore(
      retailStoreId: $retailStoreId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        salesInvoiceId
        retailStoreId
        datetime
        status
        details
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        salesInvoice {
          id
          tenantId
          retailStoreId
          customerId
          number
          invoiceId
          date
          type
          taxRegime
          paymentCode
          paymentMode
          paymentAmount
          iban
          notes
          customerFiscalCode
          customerCompanyVat
          customerCompanyName
          customerZipCode
          customerCounty
          customerMunicipality
          customerAddress
          customerCountry
          customerRecipientCode
          customerEmail
          extensions
          isPAInvoice
          sentToArchismall
          pricesWithoutVat
          createdByModule
          xmlS3Key
          pdfS3Key
          archismallProgressiveId
          status
          idSdi
          idArchismall
          errorDescription
          emailNotificationSent
          writeOffInvoiceId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const einvoiceTransmissionsBySalesInvoice = /* GraphQL */ `
  query EinvoiceTransmissionsBySalesInvoice(
    $salesInvoiceId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEInvoiceTransmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    einvoiceTransmissionsBySalesInvoice(
      salesInvoiceId: $salesInvoiceId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        salesInvoiceId
        retailStoreId
        datetime
        status
        details
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        salesInvoice {
          id
          tenantId
          retailStoreId
          customerId
          number
          invoiceId
          date
          type
          taxRegime
          paymentCode
          paymentMode
          paymentAmount
          iban
          notes
          customerFiscalCode
          customerCompanyVat
          customerCompanyName
          customerZipCode
          customerCounty
          customerMunicipality
          customerAddress
          customerCountry
          customerRecipientCode
          customerEmail
          extensions
          isPAInvoice
          sentToArchismall
          pricesWithoutVat
          createdByModule
          xmlS3Key
          pdfS3Key
          archismallProgressiveId
          status
          idSdi
          idArchismall
          errorDescription
          emailNotificationSent
          writeOffInvoiceId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getElectronicCashRegister = /* GraphQL */ `
  query GetElectronicCashRegister($id: ID!) {
    getElectronicCashRegister(id: $id) {
      id
      tenantId
      retailStoreId
      partnerId
      name
      number
      description
      ejNumber
      ejInitDay
      ejMissingDays
      ejDueDate
      ejType
      lastZNumber
      fwVersion
      enabled
      deleted
      extensions
      rtUtilityInactive
      lastSyncDatetime
      type
      ecrModel
      version
      tags
      advMessage
      lastZErrorIds
      imageS3Key
      pendingEJExtension
      exportPendingXML
      accountantId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      lastPVIntervention {
        id
        partnerId
        tenantId
        retailStoreId
        number
        fiscalCode
        vatNumber
        datetime
        code
        note
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listElectronicCashRegisters = /* GraphQL */ `
  query ListElectronicCashRegisters(
    $filter: ModelElectronicCashRegisterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElectronicCashRegisters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const electronicCashRegistersByTenant = /* GraphQL */ `
  query ElectronicCashRegistersByTenant(
    $tenantId: ID
    $retailStoreId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElectronicCashRegisterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    electronicCashRegistersByTenant(
      tenantId: $tenantId
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const electronicCashRegistersByRetailStore = /* GraphQL */ `
  query ElectronicCashRegistersByRetailStore(
    $retailStoreId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElectronicCashRegisterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    electronicCashRegistersByRetailStore(
      retailStoreId: $retailStoreId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const electronicCashRegistersByPartner = /* GraphQL */ `
  query ElectronicCashRegistersByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelElectronicCashRegisterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    electronicCashRegistersByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const electronicCashRegistersByNumber = /* GraphQL */ `
  query ElectronicCashRegistersByNumber(
    $number: String
    $sortDirection: ModelSortDirection
    $filter: ModelElectronicCashRegisterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    electronicCashRegistersByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchElectronicCashRegisters = /* GraphQL */ `
  query SearchElectronicCashRegisters(
    $filter: SearchableElectronicCashRegisterFilterInput
    $sort: SearchableElectronicCashRegisterSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchElectronicCashRegisters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getECRConfiguration = /* GraphQL */ `
  query GetECRConfiguration($id: ID!) {
    getECRConfiguration(id: $id) {
      id
      partnerId
      number
      content
      version
      plu
      status
      logs
      createdAt
      updatedAt
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listECRConfigurations = /* GraphQL */ `
  query ListECRConfigurations(
    $filter: ModelECRConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        number
        content
        version
        plu
        status
        logs
        createdAt
        updatedAt
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrConfigurationsByNumber = /* GraphQL */ `
  query EcrConfigurationsByNumber(
    $number: String
    $sortDirection: ModelSortDirection
    $filter: ModelECRConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrConfigurationsByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        number
        content
        version
        plu
        status
        logs
        createdAt
        updatedAt
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getECRDailySales = /* GraphQL */ `
  query GetECRDailySales($id: ID!) {
    getECRDailySales(id: $id) {
      id
      tenantId
      electronicCashRegisterId
      number
      datetime
      content
      notified
      accountantId
      createdAt
      updatedAt
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listECRDailySaless = /* GraphQL */ `
  query ListECRDailySaless(
    $filter: ModelECRDailySalesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRDailySaless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        electronicCashRegisterId
        number
        datetime
        content
        notified
        accountantId
        createdAt
        updatedAt
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrDailySalesByElectronicCashRegisterId = /* GraphQL */ `
  query EcrDailySalesByElectronicCashRegisterId(
    $electronicCashRegisterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelECRDailySalesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrDailySalesByElectronicCashRegisterId(
      electronicCashRegisterId: $electronicCashRegisterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        electronicCashRegisterId
        number
        datetime
        content
        notified
        accountantId
        createdAt
        updatedAt
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getECRDailyTech = /* GraphQL */ `
  query GetECRDailyTech($id: ID!) {
    getECRDailyTech(id: $id) {
      id
      number
      datetime
      content
      rtUtilityProcessed
      createdAt
      updatedAt
    }
  }
`;
export const listECRDailyTechs = /* GraphQL */ `
  query ListECRDailyTechs(
    $filter: ModelECRDailyTechFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRDailyTechs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        datetime
        content
        rtUtilityProcessed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ecrDailyTechsByNumber = /* GraphQL */ `
  query EcrDailyTechsByNumber(
    $number: String
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelECRDailyTechFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrDailyTechsByNumber(
      number: $number
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        datetime
        content
        rtUtilityProcessed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getECRTechIntervention = /* GraphQL */ `
  query GetECRTechIntervention($id: ID!) {
    getECRTechIntervention(id: $id) {
      id
      partnerId
      tenantId
      retailStoreId
      number
      fiscalCode
      vatNumber
      datetime
      code
      note
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listECRTechInterventions = /* GraphQL */ `
  query ListECRTechInterventions(
    $filter: ModelECRTechInterventionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRTechInterventions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        tenantId
        retailStoreId
        number
        fiscalCode
        vatNumber
        datetime
        code
        note
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrTechInterventionsByPartner = /* GraphQL */ `
  query EcrTechInterventionsByPartner(
    $partnerId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelECRTechInterventionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrTechInterventionsByPartner(
      partnerId: $partnerId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        tenantId
        retailStoreId
        number
        fiscalCode
        vatNumber
        datetime
        code
        note
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrTechInterventionsByNumber = /* GraphQL */ `
  query EcrTechInterventionsByNumber(
    $number: String
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelECRTechInterventionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrTechInterventionsByNumber(
      number: $number
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        tenantId
        retailStoreId
        number
        fiscalCode
        vatNumber
        datetime
        code
        note
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchECRTechInterventions = /* GraphQL */ `
  query SearchECRTechInterventions(
    $filter: SearchableECRTechInterventionFilterInput
    $sort: SearchableECRTechInterventionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchECRTechInterventions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        partnerId
        tenantId
        retailStoreId
        number
        fiscalCode
        vatNumber
        datetime
        code
        note
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getECRError = /* GraphQL */ `
  query GetECRError($id: ID!) {
    getECRError(id: $id) {
      id
      partnerId
      tenantId
      retailStoreId
      number
      code
      description
      datetime
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listECRErrors = /* GraphQL */ `
  query ListECRErrors(
    $filter: ModelECRErrorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRErrors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        tenantId
        retailStoreId
        number
        code
        description
        datetime
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrErrorsByPartner = /* GraphQL */ `
  query EcrErrorsByPartner(
    $partnerId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelECRErrorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrErrorsByPartner(
      partnerId: $partnerId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        tenantId
        retailStoreId
        number
        code
        description
        datetime
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrErrorsByNumber = /* GraphQL */ `
  query EcrErrorsByNumber(
    $number: String
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelECRErrorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrErrorsByNumber(
      number: $number
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        tenantId
        retailStoreId
        number
        code
        description
        datetime
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchECRErrors = /* GraphQL */ `
  query SearchECRErrors(
    $filter: SearchableECRErrorFilterInput
    $sort: SearchableECRErrorSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchECRErrors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        partnerId
        tenantId
        retailStoreId
        number
        code
        description
        datetime
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getFirmware = /* GraphQL */ `
  query GetFirmware($id: ID!) {
    getFirmware(id: $id) {
      id
      version
      notes
      DCashFilename
      DCashPlusFilename
      DPalmFilename
      DFlexFilename
      BlancaFilename
      DFrontFilename
      DTouch10Filename
      downloadable
      billable
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const listFirmwares = /* GraphQL */ `
  query ListFirmwares(
    $filter: ModelFirmwareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFirmwares(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        version
        notes
        DCashFilename
        DCashPlusFilename
        DPalmFilename
        DFlexFilename
        BlancaFilename
        DFrontFilename
        DTouch10Filename
        downloadable
        billable
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFirmwareUpgradeHeader = /* GraphQL */ `
  query GetFirmwareUpgradeHeader($id: ID!) {
    getFirmwareUpgradeHeader(id: $id) {
      id
      tenantId
      firmwareId
      isServiceManagerIntervention
      fiscalCode
      datetime
      vatNumber
      cancelled
      linesCount
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      firmware {
        id
        version
        notes
        DCashFilename
        DCashPlusFilename
        DPalmFilename
        DFlexFilename
        BlancaFilename
        DFrontFilename
        DTouch10Filename
        downloadable
        billable
        deleted
        createdAt
        updatedAt
      }
      lines {
        items {
          id
          tenantId
          number
          installed
          cancelled
          headerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFirmwareUpgradeHeaders = /* GraphQL */ `
  query ListFirmwareUpgradeHeaders(
    $filter: ModelFirmwareUpgradeHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFirmwareUpgradeHeaders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        firmwareId
        isServiceManagerIntervention
        fiscalCode
        datetime
        vatNumber
        cancelled
        linesCount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        firmware {
          id
          version
          notes
          DCashFilename
          DCashPlusFilename
          DPalmFilename
          DFlexFilename
          BlancaFilename
          DFrontFilename
          DTouch10Filename
          downloadable
          billable
          deleted
          createdAt
          updatedAt
        }
        lines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const firmwareUpgradeHeadersByTenant = /* GraphQL */ `
  query FirmwareUpgradeHeadersByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFirmwareUpgradeHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    firmwareUpgradeHeadersByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        firmwareId
        isServiceManagerIntervention
        fiscalCode
        datetime
        vatNumber
        cancelled
        linesCount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        firmware {
          id
          version
          notes
          DCashFilename
          DCashPlusFilename
          DPalmFilename
          DFlexFilename
          BlancaFilename
          DFrontFilename
          DTouch10Filename
          downloadable
          billable
          deleted
          createdAt
          updatedAt
        }
        lines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const firmwareUpgradeHeadersByFirmware = /* GraphQL */ `
  query FirmwareUpgradeHeadersByFirmware(
    $firmwareId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFirmwareUpgradeHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    firmwareUpgradeHeadersByFirmware(
      firmwareId: $firmwareId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        firmwareId
        isServiceManagerIntervention
        fiscalCode
        datetime
        vatNumber
        cancelled
        linesCount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        firmware {
          id
          version
          notes
          DCashFilename
          DCashPlusFilename
          DPalmFilename
          DFlexFilename
          BlancaFilename
          DFrontFilename
          DTouch10Filename
          downloadable
          billable
          deleted
          createdAt
          updatedAt
        }
        lines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const firmwareUpgradeHeadersByTenantSortDatetime = /* GraphQL */ `
  query FirmwareUpgradeHeadersByTenantSortDatetime(
    $tenantId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFirmwareUpgradeHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    firmwareUpgradeHeadersByTenantSortDatetime(
      tenantId: $tenantId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        firmwareId
        isServiceManagerIntervention
        fiscalCode
        datetime
        vatNumber
        cancelled
        linesCount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        firmware {
          id
          version
          notes
          DCashFilename
          DCashPlusFilename
          DPalmFilename
          DFlexFilename
          BlancaFilename
          DFrontFilename
          DTouch10Filename
          downloadable
          billable
          deleted
          createdAt
          updatedAt
        }
        lines {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFirmwareUpgradeLine = /* GraphQL */ `
  query GetFirmwareUpgradeLine($id: ID!) {
    getFirmwareUpgradeLine(id: $id) {
      id
      tenantId
      number
      installed
      cancelled
      headerId
      createdAt
      updatedAt
      header {
        id
        tenantId
        firmwareId
        isServiceManagerIntervention
        fiscalCode
        datetime
        vatNumber
        cancelled
        linesCount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        firmware {
          id
          version
          notes
          DCashFilename
          DCashPlusFilename
          DPalmFilename
          DFlexFilename
          BlancaFilename
          DFrontFilename
          DTouch10Filename
          downloadable
          billable
          deleted
          createdAt
          updatedAt
        }
        lines {
          nextToken
        }
      }
    }
  }
`;
export const listFirmwareUpgradeLines = /* GraphQL */ `
  query ListFirmwareUpgradeLines(
    $filter: ModelFirmwareUpgradeLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFirmwareUpgradeLines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        number
        installed
        cancelled
        headerId
        createdAt
        updatedAt
        header {
          id
          tenantId
          firmwareId
          isServiceManagerIntervention
          fiscalCode
          datetime
          vatNumber
          cancelled
          linesCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const firmwareUpgradeLinesByHeader = /* GraphQL */ `
  query FirmwareUpgradeLinesByHeader(
    $headerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFirmwareUpgradeLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    firmwareUpgradeLinesByHeader(
      headerId: $headerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        number
        installed
        cancelled
        headerId
        createdAt
        updatedAt
        header {
          id
          tenantId
          firmwareId
          isServiceManagerIntervention
          fiscalCode
          datetime
          vatNumber
          cancelled
          linesCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const firmwareUpgradeLinesByNumber = /* GraphQL */ `
  query FirmwareUpgradeLinesByNumber(
    $number: String
    $sortDirection: ModelSortDirection
    $filter: ModelFirmwareUpgradeLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    firmwareUpgradeLinesByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        number
        installed
        cancelled
        headerId
        createdAt
        updatedAt
        header {
          id
          tenantId
          firmwareId
          isServiceManagerIntervention
          fiscalCode
          datetime
          vatNumber
          cancelled
          linesCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCertificate = /* GraphQL */ `
  query GetCertificate($id: ID!) {
    getCertificate(id: $id) {
      id
      name
      type
      notes
      filename
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const listCertificates = /* GraphQL */ `
  query ListCertificates(
    $filter: ModelCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        notes
        filename
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommunication = /* GraphQL */ `
  query GetCommunication($id: ID!) {
    getCommunication(id: $id) {
      id
      title
      description
      notified
      type
      attribute
      createdAt
      updatedAt
    }
  }
`;
export const listCommunications = /* GraphQL */ `
  query ListCommunications(
    $filter: ModelCommunicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        notified
        type
        attribute
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFirmwareStat = /* GraphQL */ `
  query GetFirmwareStat($id: ID!) {
    getFirmwareStat(id: $id) {
      id
      version
      numbers
      createdAt
      updatedAt
    }
  }
`;
export const listFirmwareStats = /* GraphQL */ `
  query ListFirmwareStats(
    $filter: ModelFirmwareStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFirmwareStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        version
        numbers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMoitoIot = /* GraphQL */ `
  query GetMoitoIot($id: ID!) {
    getMoitoIot(id: $id) {
      id
      tenantId
      retailStoreId
      partnerId
      name
      code
      version
      androidApiLevel
      isBetaInstallation
      lastSyncDatetime
      lastBackupS3Key
      lastBackupDatetime
      usersCount
      kiosksCount
      deleted
      moitoIotInactive
      tags
      firstSyncDone
      extensions
      isFree
      lastAssistanceUpload
      ecrNumber
      isSyncDisabled
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      fixedVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listMoitoIots = /* GraphQL */ `
  query ListMoitoIots(
    $filter: ModelMoitoIotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoitoIots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotsByTenant = /* GraphQL */ `
  query MoitoIotsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotsByRetailStore = /* GraphQL */ `
  query MoitoIotsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotsByPartner = /* GraphQL */ `
  query MoitoIotsByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotsByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotsByCode = /* GraphQL */ `
  query MoitoIotsByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotsByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchMoitoIots = /* GraphQL */ `
  query SearchMoitoIots(
    $filter: SearchableMoitoIotFilterInput
    $sort: SearchableMoitoIotSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMoitoIots(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getMoitoIotAppVersion = /* GraphQL */ `
  query GetMoitoIotAppVersion($id: ID!) {
    getMoitoIotAppVersion(id: $id) {
      id
      adminId
      version
      frontendZipPath
      apkPath
      apk21Path
      datetime
      notes
      isStable
      deleted
      recordType
      createdAt
      updatedAt
      fromVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      toVersion {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listMoitoIotAppVersions = /* GraphQL */ `
  query ListMoitoIotAppVersions(
    $filter: ModelMoitoIotAppVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoitoIotAppVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotAppVersionsByAdmin = /* GraphQL */ `
  query MoitoIotAppVersionsByAdmin(
    $adminId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotAppVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotAppVersionsByAdmin(
      adminId: $adminId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminId
        version
        frontendZipPath
        apkPath
        apk21Path
        datetime
        notes
        isStable
        deleted
        recordType
        createdAt
        updatedAt
        fromVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
        toVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPurchInvoice = /* GraphQL */ `
  query GetPurchInvoice($id: ID!) {
    getPurchInvoice(id: $id) {
      id
      tenantId
      number
      date
      idSdi
      vendorId
      filename
      progressiveId
      type
      xmlS3Key
      pdfS3Key
      taxableAmount
      vatAmount
      paymentDueDate
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      vendor {
        id
        tenantId
        deleted
        companyName
        companyVat
        fiscalCode
        zipCode
        county
        municipality
        address
        country
        recipientCode
        email
        mobile
        extensions
        costCenterId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        costCenter {
          id
          tenantId
          name
          description
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPurchInvoices = /* GraphQL */ `
  query ListPurchInvoices(
    $filter: ModelPurchInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        number
        date
        idSdi
        vendorId
        filename
        progressiveId
        type
        xmlS3Key
        pdfS3Key
        taxableAmount
        vatAmount
        paymentDueDate
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vendor {
          id
          tenantId
          deleted
          companyName
          companyVat
          fiscalCode
          zipCode
          county
          municipality
          address
          country
          recipientCode
          email
          mobile
          extensions
          costCenterId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const purchInvoicesByTenant = /* GraphQL */ `
  query PurchInvoicesByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPurchInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchInvoicesByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        number
        date
        idSdi
        vendorId
        filename
        progressiveId
        type
        xmlS3Key
        pdfS3Key
        taxableAmount
        vatAmount
        paymentDueDate
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vendor {
          id
          tenantId
          deleted
          companyName
          companyVat
          fiscalCode
          zipCode
          county
          municipality
          address
          country
          recipientCode
          email
          mobile
          extensions
          costCenterId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const purchInvoicesByVendor = /* GraphQL */ `
  query PurchInvoicesByVendor(
    $vendorId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchInvoicesByVendor(
      vendorId: $vendorId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        number
        date
        idSdi
        vendorId
        filename
        progressiveId
        type
        xmlS3Key
        pdfS3Key
        taxableAmount
        vatAmount
        paymentDueDate
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vendor {
          id
          tenantId
          deleted
          companyName
          companyVat
          fiscalCode
          zipCode
          county
          municipality
          address
          country
          recipientCode
          email
          mobile
          extensions
          costCenterId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPublishedMoitoIotMessage = /* GraphQL */ `
  query GetPublishedMoitoIotMessage($id: ID!) {
    getPublishedMoitoIotMessage(id: $id) {
      id
      timestamp
      deviceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const listPublishedMoitoIotMessages = /* GraphQL */ `
  query ListPublishedMoitoIotMessages(
    $filter: ModelPublishedMoitoIotMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishedMoitoIotMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceId
        payload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const publishedMoitoIotMessagesByDeviceId = /* GraphQL */ `
  query PublishedMoitoIotMessagesByDeviceId(
    $deviceId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublishedMoitoIotMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publishedMoitoIotMessagesByDeviceId(
      deviceId: $deviceId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceId
        payload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReceivedMoitoIotMessage = /* GraphQL */ `
  query GetReceivedMoitoIotMessage($id: ID!) {
    getReceivedMoitoIotMessage(id: $id) {
      id
      timestamp
      deviceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const listReceivedMoitoIotMessages = /* GraphQL */ `
  query ListReceivedMoitoIotMessages(
    $filter: ModelReceivedMoitoIotMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceivedMoitoIotMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceId
        payload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const receivedMoitoIotMessageByDeviceId = /* GraphQL */ `
  query ReceivedMoitoIotMessageByDeviceId(
    $deviceId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceivedMoitoIotMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receivedMoitoIotMessageByDeviceId(
      deviceId: $deviceId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceId
        payload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVatRate = /* GraphQL */ `
  query GetVatRate($id: ID!) {
    getVatRate(id: $id) {
      id
      tenantId
      code
      description
      sorting
      rate
      extensions
      isAdded
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listVatRates = /* GraphQL */ `
  query ListVatRates(
    $filter: ModelVatRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVatRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vatRatesByTenant = /* GraphQL */ `
  query VatRatesByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVatRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vatRatesByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vatRatesByTenantSorting = /* GraphQL */ `
  query VatRatesByTenantSorting(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVatRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vatRatesByTenantSorting(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      tenantId
      color
      description
      forFree
      sorting
      enabled
      activityCode
      extensions
      createdByModule
      deleted
      rtDepartment
      expenseType
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      vatRate {
        id
        tenantId
        code
        description
        sorting
        rate
        extensions
        isAdded
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const departmentsByTenant = /* GraphQL */ `
  query DepartmentsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const departmentsByTenantSorting = /* GraphQL */ `
  query DepartmentsByTenantSorting(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByTenantSorting(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVariant = /* GraphQL */ `
  query GetVariant($id: ID!) {
    getVariant(id: $id) {
      id
      tenantId
      description
      sorting
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variantVariantGroups {
        items {
          id
          tenantId
          variantId
          variantGroupId
          createdAt
          updatedAt
        }
        nextToken
      }
      variantModifiers {
        items {
          id
          tenantId
          variantId
          modifierId
          price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listVariants = /* GraphQL */ `
  query ListVariants(
    $filter: ModelVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const variantsByTenant = /* GraphQL */ `
  query VariantsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const variantsByTenantSorting = /* GraphQL */ `
  query VariantsByTenantSorting(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantsByTenantSorting(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getModifier = /* GraphQL */ `
  query GetModifier($id: ID!) {
    getModifier(id: $id) {
      id
      tenantId
      description
      color
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listModifiers = /* GraphQL */ `
  query ListModifiers(
    $filter: ModelModifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModifiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const modifiersByTenant = /* GraphQL */ `
  query ModifiersByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelModifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    modifiersByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVariantGroup = /* GraphQL */ `
  query GetVariantGroup($id: ID!) {
    getVariantGroup(id: $id) {
      id
      tenantId
      description
      sorting
      enabled
      color
      extensions
      createdByModule
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listVariantGroups = /* GraphQL */ `
  query ListVariantGroups(
    $filter: ModelVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariantGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const variantGroupsByTenant = /* GraphQL */ `
  query VariantGroupsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantGroupsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const variantGroupsByTenantSorting = /* GraphQL */ `
  query VariantGroupsByTenantSorting(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantGroupsByTenantSorting(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVariantVariantGroup = /* GraphQL */ `
  query GetVariantVariantGroup($id: ID!) {
    getVariantVariantGroup(id: $id) {
      id
      tenantId
      variantId
      variantGroupId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      variantGroup {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listVariantVariantGroups = /* GraphQL */ `
  query ListVariantVariantGroups(
    $filter: ModelVariantVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariantVariantGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantId
        variantGroupId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        variantGroup {
          id
          tenantId
          description
          sorting
          enabled
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const variantVariantGroupsByTenant = /* GraphQL */ `
  query VariantVariantGroupsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVariantVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantVariantGroupsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantId
        variantGroupId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        variantGroup {
          id
          tenantId
          description
          sorting
          enabled
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const variantVariantGroupsByVariant = /* GraphQL */ `
  query VariantVariantGroupsByVariant(
    $variantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVariantVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantVariantGroupsByVariant(
      variantId: $variantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantId
        variantGroupId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        variantGroup {
          id
          tenantId
          description
          sorting
          enabled
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVariantModifier = /* GraphQL */ `
  query GetVariantModifier($id: ID!) {
    getVariantModifier(id: $id) {
      id
      tenantId
      variantId
      modifierId
      price
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listVariantModifiers = /* GraphQL */ `
  query ListVariantModifiers(
    $filter: ModelVariantModifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariantModifiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantId
        modifierId
        price
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const variantModifiersByTenant = /* GraphQL */ `
  query VariantModifiersByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVariantModifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantModifiersByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantId
        modifierId
        price
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const variantModifiersByVariant = /* GraphQL */ `
  query VariantModifiersByVariant(
    $variantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVariantModifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantModifiersByVariant(
      variantId: $variantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantId
        modifierId
        price
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMoitoIotOperator = /* GraphQL */ `
  query GetMoitoIotOperator($id: ID!) {
    getMoitoIotOperator(id: $id) {
      id
      tenantId
      retailStoreId
      description
      username
      password
      deleted
      sorting
      icon
      color
      enabled
      isAdmin
      blockPayments
      printWorkshift
      openDrawer
      incoming
      withdrawals
      insertPrice
      voids
      refunds
      discounts
      cancellation
      changeVat
      vatImmune
      preAccounts
      zreport
      updateWarehouse
      insertItems
      takeDesk
      forceTakeDesk
      journal
      finalPreAccounts
      testMode
      frontendConfigurations
      type
      showSettings
      showEditingMode
      showDtrs
      showInvoiceOutcome
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      priceList {
        id
        tenantId
        retailStoreId
        name
        extensions
        createdByModule
        sorting
        deleted
        visible
        enabled
        enowebId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          nextToken
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      retailStoreRoom {
        id
        tenantId
        retailStoreId
        name
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        coverItem {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      orderPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      receiptPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      invoicePrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      preorderPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      tallonPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      reportPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      kioskPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listMoitoIotOperators = /* GraphQL */ `
  query ListMoitoIotOperators(
    $filter: ModelMoitoIotOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoitoIotOperators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotOperatorsByTenant = /* GraphQL */ `
  query MoitoIotOperatorsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotOperatorsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotOperatorsByRetailStore = /* GraphQL */ `
  query MoitoIotOperatorsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotOperatorsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDiscountSurcharge = /* GraphQL */ `
  query GetDiscountSurcharge($id: ID!) {
    getDiscountSurcharge(id: $id) {
      id
      tenantId
      description
      value
      percValue
      discountSurcharge
      itemSubTotal
      enabled
      sorting
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listDiscountSurcharges = /* GraphQL */ `
  query ListDiscountSurcharges(
    $filter: ModelDiscountSurchargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscountSurcharges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const discountSurchargesByTenant = /* GraphQL */ `
  query DiscountSurchargesByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountSurchargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountSurchargesByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const discountSurchargesByTenantSorting = /* GraphQL */ `
  query DiscountSurchargesByTenantSorting(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountSurchargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountSurchargesByTenantSorting(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPhase = /* GraphQL */ `
  query GetPhase($id: ID!) {
    getPhase(id: $id) {
      id
      tenantId
      description
      sorting
      enabled
      code
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPhases = /* GraphQL */ `
  query ListPhases(
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        description
        sorting
        enabled
        code
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const phasesByTenant = /* GraphQL */ `
  query PhasesByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    phasesByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        sorting
        enabled
        code
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const phasesByTenantSorting = /* GraphQL */ `
  query PhasesByTenantSorting(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    phasesByTenantSorting(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        sorting
        enabled
        code
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getItemVariantGroup = /* GraphQL */ `
  query GetItemVariantGroup($id: ID!) {
    getItemVariantGroup(id: $id) {
      id
      tenantId
      variantGroupId
      itemId
      createdAt
      updatedAt
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      variantGroup {
        id
        tenantId
        description
        sorting
        enabled
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listItemVariantGroups = /* GraphQL */ `
  query ListItemVariantGroups(
    $filter: ModelItemVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemVariantGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantGroupId
        itemId
        createdAt
        updatedAt
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        variantGroup {
          id
          tenantId
          description
          sorting
          enabled
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemVariantGroupsByTenant = /* GraphQL */ `
  query ItemVariantGroupsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemVariantGroupsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantGroupId
        itemId
        createdAt
        updatedAt
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        variantGroup {
          id
          tenantId
          description
          sorting
          enabled
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemVariantGroupsByItem = /* GraphQL */ `
  query ItemVariantGroupsByItem(
    $itemId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemVariantGroupsByItem(
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantGroupId
        itemId
        createdAt
        updatedAt
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        variantGroup {
          id
          tenantId
          description
          sorting
          enabled
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemVariantGroupsByVariantGroup = /* GraphQL */ `
  query ItemVariantGroupsByVariantGroup(
    $variantGroupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemVariantGroupsByVariantGroup(
      variantGroupId: $variantGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantGroupId
        itemId
        createdAt
        updatedAt
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        variantGroup {
          id
          tenantId
          description
          sorting
          enabled
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMoitoIotPhysicalPrinter = /* GraphQL */ `
  query GetMoitoIotPhysicalPrinter($id: ID!) {
    getMoitoIotPhysicalPrinter(id: $id) {
      id
      tenantId
      retailStoreId
      type
      driver
      name
      description
      model
      version
      number
      com
      baud
      ip
      port
      rowLength
      isTest
      screenSize
      cashBalance
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listMoitoIotPhysicalPrinters = /* GraphQL */ `
  query ListMoitoIotPhysicalPrinters(
    $filter: ModelMoitoIotPhysicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoitoIotPhysicalPrinters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        type
        driver
        name
        description
        model
        version
        number
        com
        baud
        ip
        port
        rowLength
        isTest
        screenSize
        cashBalance
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotPhysicalPrintersByTenant = /* GraphQL */ `
  query MoitoIotPhysicalPrintersByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotPhysicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotPhysicalPrintersByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        type
        driver
        name
        description
        model
        version
        number
        com
        baud
        ip
        port
        rowLength
        isTest
        screenSize
        cashBalance
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotPhysicalPrintersByRetailStore = /* GraphQL */ `
  query MoitoIotPhysicalPrintersByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotPhysicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotPhysicalPrintersByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        type
        driver
        name
        description
        model
        version
        number
        com
        baud
        ip
        port
        rowLength
        isTest
        screenSize
        cashBalance
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMoitoIotLogicalPrinter = /* GraphQL */ `
  query GetMoitoIotLogicalPrinter($id: ID!) {
    getMoitoIotLogicalPrinter(id: $id) {
      id
      tenantId
      retailStoreId
      physicalPrinterId
      name
      description
      printLogoOrder
      beepAfterOrder
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      physicalPrinter {
        id
        tenantId
        retailStoreId
        type
        driver
        name
        description
        model
        version
        number
        com
        baud
        ip
        port
        rowLength
        isTest
        screenSize
        cashBalance
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listMoitoIotLogicalPrinters = /* GraphQL */ `
  query ListMoitoIotLogicalPrinters(
    $filter: ModelMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoitoIotLogicalPrinters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotLogicalPrintersByTenant = /* GraphQL */ `
  query MoitoIotLogicalPrintersByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotLogicalPrintersByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const moitoIotLogicalPrintersByRetailStore = /* GraphQL */ `
  query MoitoIotLogicalPrintersByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotLogicalPrintersByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getItemMoitoIotLogicalPrinter = /* GraphQL */ `
  query GetItemMoitoIotLogicalPrinter($id: ID!) {
    getItemMoitoIotLogicalPrinter(id: $id) {
      id
      tenantId
      retailStoreId
      itemId
      logicalPrinterId
      isMain
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      logicalPrinter {
        id
        tenantId
        retailStoreId
        physicalPrinterId
        name
        description
        printLogoOrder
        beepAfterOrder
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        physicalPrinter {
          id
          tenantId
          retailStoreId
          type
          driver
          name
          description
          model
          version
          number
          com
          baud
          ip
          port
          rowLength
          isTest
          screenSize
          cashBalance
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listItemMoitoIotLogicalPrinters = /* GraphQL */ `
  query ListItemMoitoIotLogicalPrinters(
    $filter: ModelItemMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemMoitoIotLogicalPrinters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        logicalPrinterId
        isMain
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemMoitoIotLogicalPrinterByTenant = /* GraphQL */ `
  query ItemMoitoIotLogicalPrinterByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemMoitoIotLogicalPrinterByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        logicalPrinterId
        isMain
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemMoitoIotLogicalPrinterByRetailStore = /* GraphQL */ `
  query ItemMoitoIotLogicalPrinterByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemMoitoIotLogicalPrinterByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        logicalPrinterId
        isMain
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemMoitoIotLogicalPrinterByItem = /* GraphQL */ `
  query ItemMoitoIotLogicalPrinterByItem(
    $itemId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemMoitoIotLogicalPrinterByItem(
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        logicalPrinterId
        isMain
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemMoitoIotLogicalPrinterByLogicalPrinter = /* GraphQL */ `
  query ItemMoitoIotLogicalPrinterByLogicalPrinter(
    $logicalPrinterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemMoitoIotLogicalPrinterByLogicalPrinter(
      logicalPrinterId: $logicalPrinterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        logicalPrinterId
        isMain
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReceipt = /* GraphQL */ `
  query GetReceipt($id: ID!) {
    getReceipt(id: $id) {
      id
      tenantId
      retailStoreId
      moitoIotId
      moitoIotNumber
      ecrNumber
      moitoIotDatetime
      zMoitoIot
      zEcr
      ecrDatetime
      number
      amount
      percDiscount
      valDiscount
      discountDescription
      customerId
      posted
      type
      description
      extensions
      deleted
      netAmount
      taxAmount
      payedAmount
      changeAmount
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        kiosksCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        lastAssistanceUpload
        ecrNumber
        isSyncDisabled
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        fixedVersion {
          id
          adminId
          version
          frontendZipPath
          apkPath
          apk21Path
          datetime
          notes
          isStable
          deleted
          recordType
          createdAt
          updatedAt
        }
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      receiptPayments {
        items {
          id
          tenantId
          receiptId
          paymentId
          retailStoreId
          amount
          sorting
          description
          loyaltyCardId
          loyaltyCardInitialAmount
          loyaltyCardFinalAmount
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      lines {
        items {
          id
          tenantId
          retailStoreId
          receiptId
          departmentId
          sorting
          description
          percDiscount
          valDiscount
          discountDescription
          comment
          price
          quantity
          total
          loyaltyPoints
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
    }
  }
`;
export const listReceipts = /* GraphQL */ `
  query ListReceipts(
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptsByTenant = /* GraphQL */ `
  query ReceiptsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptsByRetailStore = /* GraphQL */ `
  query ReceiptsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptsByMoitoIot = /* GraphQL */ `
  query ReceiptsByMoitoIot(
    $moitoIotId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptsByMoitoIot(
      moitoIotId: $moitoIotId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptsByCustomer = /* GraphQL */ `
  query ReceiptsByCustomer(
    $customerId: ID
    $moitoIotDatetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptsByCustomer(
      customerId: $customerId
      moitoIotDatetime: $moitoIotDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      tenantId
      sorting
      type
      name
      color
      enabled
      presetAmount
      automaticDiscount
      allowLeftovers
      allowIncoming
      allowWithdrawals
      allowComment
      allowZero
      automaticOpening
      willFollowInvoice
      isLoyaltyCard
      extensions
      deleted
      isBankTransfer
      isTracked
      eftAmount
      cashDrawer
      adminOnly
      satispay
      satispayMode
      paymentDtrId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        sorting
        type
        name
        color
        enabled
        presetAmount
        automaticDiscount
        allowLeftovers
        allowIncoming
        allowWithdrawals
        allowComment
        allowZero
        automaticOpening
        willFollowInvoice
        isLoyaltyCard
        extensions
        deleted
        isBankTransfer
        isTracked
        eftAmount
        cashDrawer
        adminOnly
        satispay
        satispayMode
        paymentDtrId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const paymentsByTenant = /* GraphQL */ `
  query PaymentsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        sorting
        type
        name
        color
        enabled
        presetAmount
        automaticDiscount
        allowLeftovers
        allowIncoming
        allowWithdrawals
        allowComment
        allowZero
        automaticOpening
        willFollowInvoice
        isLoyaltyCard
        extensions
        deleted
        isBankTransfer
        isTracked
        eftAmount
        cashDrawer
        adminOnly
        satispay
        satispayMode
        paymentDtrId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const paymentsByTenantSorting = /* GraphQL */ `
  query PaymentsByTenantSorting(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentsByTenantSorting(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        sorting
        type
        name
        color
        enabled
        presetAmount
        automaticDiscount
        allowLeftovers
        allowIncoming
        allowWithdrawals
        allowComment
        allowZero
        automaticOpening
        willFollowInvoice
        isLoyaltyCard
        extensions
        deleted
        isBankTransfer
        isTracked
        eftAmount
        cashDrawer
        adminOnly
        satispay
        satispayMode
        paymentDtrId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReceiptPayment = /* GraphQL */ `
  query GetReceiptPayment($id: ID!) {
    getReceiptPayment(id: $id) {
      id
      tenantId
      receiptId
      paymentId
      retailStoreId
      amount
      sorting
      description
      loyaltyCardId
      loyaltyCardInitialAmount
      loyaltyCardFinalAmount
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      payment {
        id
        tenantId
        sorting
        type
        name
        color
        enabled
        presetAmount
        automaticDiscount
        allowLeftovers
        allowIncoming
        allowWithdrawals
        allowComment
        allowZero
        automaticOpening
        willFollowInvoice
        isLoyaltyCard
        extensions
        deleted
        isBankTransfer
        isTracked
        eftAmount
        cashDrawer
        adminOnly
        satispay
        satispayMode
        paymentDtrId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReceiptPayments = /* GraphQL */ `
  query ListReceiptPayments(
    $filter: ModelReceiptPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceiptPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        receiptId
        paymentId
        retailStoreId
        amount
        sorting
        description
        loyaltyCardId
        loyaltyCardInitialAmount
        loyaltyCardFinalAmount
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
        payment {
          id
          tenantId
          sorting
          type
          name
          color
          enabled
          presetAmount
          automaticDiscount
          allowLeftovers
          allowIncoming
          allowWithdrawals
          allowComment
          allowZero
          automaticOpening
          willFollowInvoice
          isLoyaltyCard
          extensions
          deleted
          isBankTransfer
          isTracked
          eftAmount
          cashDrawer
          adminOnly
          satispay
          satispayMode
          paymentDtrId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptPaymentsByTenant = /* GraphQL */ `
  query ReceiptPaymentsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptPaymentsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        receiptId
        paymentId
        retailStoreId
        amount
        sorting
        description
        loyaltyCardId
        loyaltyCardInitialAmount
        loyaltyCardFinalAmount
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
        payment {
          id
          tenantId
          sorting
          type
          name
          color
          enabled
          presetAmount
          automaticDiscount
          allowLeftovers
          allowIncoming
          allowWithdrawals
          allowComment
          allowZero
          automaticOpening
          willFollowInvoice
          isLoyaltyCard
          extensions
          deleted
          isBankTransfer
          isTracked
          eftAmount
          cashDrawer
          adminOnly
          satispay
          satispayMode
          paymentDtrId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptPaymentsByReceipt = /* GraphQL */ `
  query ReceiptPaymentsByReceipt(
    $receiptId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptPaymentsByReceipt(
      receiptId: $receiptId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        receiptId
        paymentId
        retailStoreId
        amount
        sorting
        description
        loyaltyCardId
        loyaltyCardInitialAmount
        loyaltyCardFinalAmount
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
        payment {
          id
          tenantId
          sorting
          type
          name
          color
          enabled
          presetAmount
          automaticDiscount
          allowLeftovers
          allowIncoming
          allowWithdrawals
          allowComment
          allowZero
          automaticOpening
          willFollowInvoice
          isLoyaltyCard
          extensions
          deleted
          isBankTransfer
          isTracked
          eftAmount
          cashDrawer
          adminOnly
          satispay
          satispayMode
          paymentDtrId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReceiptLine = /* GraphQL */ `
  query GetReceiptLine($id: ID!) {
    getReceiptLine(id: $id) {
      id
      tenantId
      retailStoreId
      receiptId
      departmentId
      sorting
      description
      percDiscount
      valDiscount
      discountDescription
      comment
      price
      quantity
      total
      loyaltyPoints
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      department {
        id
        tenantId
        color
        description
        forFree
        sorting
        enabled
        activityCode
        extensions
        createdByModule
        deleted
        rtDepartment
        expenseType
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      discountSurcharge {
        id
        tenantId
        description
        value
        percValue
        discountSurcharge
        itemSubTotal
        enabled
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReceiptLines = /* GraphQL */ `
  query ListReceiptLines(
    $filter: ModelReceiptLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceiptLines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        departmentId
        sorting
        description
        percDiscount
        valDiscount
        discountDescription
        comment
        price
        quantity
        total
        loyaltyPoints
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptLinesByTenant = /* GraphQL */ `
  query ReceiptLinesByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptLinesByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        departmentId
        sorting
        description
        percDiscount
        valDiscount
        discountDescription
        comment
        price
        quantity
        total
        loyaltyPoints
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptLinesByReceipt = /* GraphQL */ `
  query ReceiptLinesByReceipt(
    $receiptId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptLineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptLinesByReceipt(
      receiptId: $receiptId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        departmentId
        sorting
        description
        percDiscount
        valDiscount
        discountDescription
        comment
        price
        quantity
        total
        loyaltyPoints
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReceiptVat = /* GraphQL */ `
  query GetReceiptVat($id: ID!) {
    getReceiptVat(id: $id) {
      id
      tenantId
      retailStoreId
      receiptId
      sorting
      vatCode
      vatRate
      totalAmount
      netAmount
      vatAmount
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReceiptVats = /* GraphQL */ `
  query ListReceiptVats(
    $filter: ModelReceiptVatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceiptVats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        sorting
        vatCode
        vatRate
        totalAmount
        netAmount
        vatAmount
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptVatsByTenant = /* GraphQL */ `
  query ReceiptVatsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptVatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptVatsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        sorting
        vatCode
        vatRate
        totalAmount
        netAmount
        vatAmount
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptVatsByReceipt = /* GraphQL */ `
  query ReceiptVatsByReceipt(
    $receiptId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptVatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptVatsByReceipt(
      receiptId: $receiptId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        sorting
        vatCode
        vatRate
        totalAmount
        netAmount
        vatAmount
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReceiptLineVariant = /* GraphQL */ `
  query GetReceiptLineVariant($id: ID!) {
    getReceiptLineVariant(id: $id) {
      id
      tenantId
      retailStoreId
      receiptId
      receiptLineId
      variantId
      modifierId
      modifierDescription
      modifierRightPosition
      modifierColor
      variantDescription
      price
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      receipt {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          kiosksCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          lastAssistanceUpload
          ecrNumber
          isSyncDisabled
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
        receiptPayments {
          nextToken
        }
        lines {
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
      }
      receiptLine {
        id
        tenantId
        retailStoreId
        receiptId
        departmentId
        sorting
        description
        percDiscount
        valDiscount
        discountDescription
        comment
        price
        quantity
        total
        loyaltyPoints
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReceiptLineVariants = /* GraphQL */ `
  query ListReceiptLineVariants(
    $filter: ModelReceiptLineVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceiptLineVariants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        receiptLineId
        variantId
        modifierId
        modifierDescription
        modifierRightPosition
        modifierColor
        variantDescription
        price
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
        receiptLine {
          id
          tenantId
          retailStoreId
          receiptId
          departmentId
          sorting
          description
          percDiscount
          valDiscount
          discountDescription
          comment
          price
          quantity
          total
          loyaltyPoints
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptLineVariantsByTenant = /* GraphQL */ `
  query ReceiptLineVariantsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptLineVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptLineVariantsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        receiptLineId
        variantId
        modifierId
        modifierDescription
        modifierRightPosition
        modifierColor
        variantDescription
        price
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
        receiptLine {
          id
          tenantId
          retailStoreId
          receiptId
          departmentId
          sorting
          description
          percDiscount
          valDiscount
          discountDescription
          comment
          price
          quantity
          total
          loyaltyPoints
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const receiptLineVariantsByReceiptLine = /* GraphQL */ `
  query ReceiptLineVariantsByReceiptLine(
    $receiptLineId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptLineVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptLineVariantsByReceiptLine(
      receiptLineId: $receiptLineId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        receiptId
        receiptLineId
        variantId
        modifierId
        modifierDescription
        modifierRightPosition
        modifierColor
        variantDescription
        price
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        receipt {
          id
          tenantId
          retailStoreId
          moitoIotId
          moitoIotNumber
          ecrNumber
          moitoIotDatetime
          zMoitoIot
          zEcr
          ecrDatetime
          number
          amount
          percDiscount
          valDiscount
          discountDescription
          customerId
          posted
          type
          description
          extensions
          deleted
          netAmount
          taxAmount
          payedAmount
          changeAmount
          createdAt
          updatedAt
        }
        receiptLine {
          id
          tenantId
          retailStoreId
          receiptId
          departmentId
          sorting
          description
          percDiscount
          valDiscount
          discountDescription
          comment
          price
          quantity
          total
          loyaltyPoints
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMoitoIotMessageFilter = /* GraphQL */ `
  query GetMoitoIotMessageFilter($id: ID!) {
    getMoitoIotMessageFilter(id: $id) {
      id
      timestamp
      deviceId
      createdAt
      updatedAt
    }
  }
`;
export const listMoitoIotMessageFilters = /* GraphQL */ `
  query ListMoitoIotMessageFilters(
    $filter: ModelMoitoIotMessageFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoitoIotMessageFilters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const moitoIotMessageFiltersByDeviceId = /* GraphQL */ `
  query MoitoIotMessageFiltersByDeviceId(
    $deviceId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotMessageFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moitoIotMessageFiltersByDeviceId(
      deviceId: $deviceId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getECRPendingXML = /* GraphQL */ `
  query GetECRPendingXML($id: ID!) {
    getECRPendingXML(id: $id) {
      id
      electronicCashRegisterId
      partnerId
      tenantId
      number
      filename
      datetime
      s3Key
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listECRPendingXMLs = /* GraphQL */ `
  query ListECRPendingXMLs(
    $filter: ModelECRPendingXMLFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRPendingXMLs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        electronicCashRegisterId
        partnerId
        tenantId
        number
        filename
        datetime
        s3Key
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrPendingXMLsByElectronicCashRegister = /* GraphQL */ `
  query EcrPendingXMLsByElectronicCashRegister(
    $electronicCashRegisterId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelECRPendingXMLFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrPendingXMLsByElectronicCashRegister(
      electronicCashRegisterId: $electronicCashRegisterId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        electronicCashRegisterId
        partnerId
        tenantId
        number
        filename
        datetime
        s3Key
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrPendingXMLsByPartner = /* GraphQL */ `
  query EcrPendingXMLsByPartner(
    $partnerId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelECRPendingXMLFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrPendingXMLsByPartner(
      partnerId: $partnerId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        electronicCashRegisterId
        partnerId
        tenantId
        number
        filename
        datetime
        s3Key
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCalendarEventType = /* GraphQL */ `
  query GetCalendarEventType($id: ID!) {
    getCalendarEventType(id: $id) {
      id
      tenantId
      retailStoreId
      name
      color
      notificationEnabled
      notificationAnticipationMinutes
      title
      extensions
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listCalendarEventTypes = /* GraphQL */ `
  query ListCalendarEventTypes(
    $filter: ModelCalendarEventTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarEventTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const calendarEventTypeByTenant = /* GraphQL */ `
  query CalendarEventTypeByTenant(
    $tenantId: ID
    $retailStoreId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventTypeByTenant(
      tenantId: $tenantId
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const calendarEventTypeByRetailStore = /* GraphQL */ `
  query CalendarEventTypeByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventTypeByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCalendarEvent = /* GraphQL */ `
  query GetCalendarEvent($id: ID!) {
    getCalendarEvent(id: $id) {
      id
      tenantId
      retailStoreId
      userId
      calendarEventTypeId
      customerId
      notificationEnabled
      notificationDatetime
      notificationSent
      title
      description
      startDatetime
      endDatetime
      tags
      isAllDayEvent
      extensions
      mobile
      email
      deleted
      operatorId
      closed
      isPending
      address
      source
      printed
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEventType {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      calendarEventItems {
        items {
          id
          tenantId
          retailStoreId
          itemId
          qty
          calendarEventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCalendarEvents = /* GraphQL */ `
  query ListCalendarEvents(
    $filter: ModelCalendarEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const calendarEventByUser = /* GraphQL */ `
  query CalendarEventByUser(
    $userId: ID
    $startDatetimeEndDatetime: ModelCalendarEventByUserCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventByUser(
      userId: $userId
      startDatetimeEndDatetime: $startDatetimeEndDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const calendarEventByRetailStore = /* GraphQL */ `
  query CalendarEventByRetailStore(
    $retailStoreId: ID
    $startDatetimeEndDatetime: ModelCalendarEventByRetailStoreCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventByRetailStore(
      retailStoreId: $retailStoreId
      startDatetimeEndDatetime: $startDatetimeEndDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const calendarEventByCustomer = /* GraphQL */ `
  query CalendarEventByCustomer(
    $customerId: ID
    $startDatetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventByCustomer(
      customerId: $customerId
      startDatetime: $startDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getECRAdvMessage = /* GraphQL */ `
  query GetECRAdvMessage($id: ID!) {
    getECRAdvMessage(id: $id) {
      id
      electronicCashRegisterId
      partnerId
      message
      printed
      deleted
      createdAt
      updatedAt
      partner {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        ejType
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        lastPVIntervention {
          id
          partnerId
          tenantId
          retailStoreId
          number
          fiscalCode
          vatNumber
          datetime
          code
          note
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listECRAdvMessages = /* GraphQL */ `
  query ListECRAdvMessages(
    $filter: ModelECRAdvMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRAdvMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        electronicCashRegisterId
        partnerId
        message
        printed
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrAdvMessagesByElectronicCashRegister = /* GraphQL */ `
  query EcrAdvMessagesByElectronicCashRegister(
    $electronicCashRegisterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelECRAdvMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrAdvMessagesByElectronicCashRegister(
      electronicCashRegisterId: $electronicCashRegisterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        electronicCashRegisterId
        partnerId
        message
        printed
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ecrAdvMessagesByPartner = /* GraphQL */ `
  query EcrAdvMessagesByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelECRAdvMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrAdvMessagesByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        electronicCashRegisterId
        partnerId
        message
        printed
        deleted
        createdAt
        updatedAt
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          ejType
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getLoyaltyCard = /* GraphQL */ `
  query GetLoyaltyCard($id: ID!) {
    getLoyaltyCard(id: $id) {
      id
      tenantId
      retailStoreId
      customerId
      s3Key
      initialAmount
      remainingAmount
      email
      type
      percDiscount
      datetime
      dueDate
      revoked
      description
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
        pointsCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          description
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listLoyaltyCards = /* GraphQL */ `
  query ListLoyaltyCards(
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const loyaltyCardsByTenant = /* GraphQL */ `
  query LoyaltyCardsByTenant(
    $tenantId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loyaltyCardsByTenant(
      tenantId: $tenantId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const loyaltyCardsByCustomer = /* GraphQL */ `
  query LoyaltyCardsByCustomer(
    $customerId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loyaltyCardsByCustomer(
      customerId: $customerId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        customerId
        s3Key
        initialAmount
        remainingAmount
        email
        type
        percDiscount
        datetime
        dueDate
        revoked
        description
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOperator = /* GraphQL */ `
  query GetOperator($id: ID!) {
    getOperator(id: $id) {
      id
      tenantId
      retailStoreId
      description
      name
      deleted
      sorting
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listOperators = /* GraphQL */ `
  query ListOperators(
    $filter: ModelOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const operatorsByTenant = /* GraphQL */ `
  query OperatorsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    operatorsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const operatorsByRetailStore = /* GraphQL */ `
  query OperatorsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    operatorsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCalendarEventItem = /* GraphQL */ `
  query GetCalendarEventItem($id: ID!) {
    getCalendarEventItem(id: $id) {
      id
      tenantId
      retailStoreId
      itemId
      qty
      calendarEventId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      calendarEventItemVariants {
        items {
          id
          tenantId
          retailStoreId
          price
          calendarEventId
          calendarEventItemId
          variantId
          modifierId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCalendarEventItems = /* GraphQL */ `
  query ListCalendarEventItems(
    $filter: ModelCalendarEventItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarEventItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const calendarEventItemsByItem = /* GraphQL */ `
  query CalendarEventItemsByItem(
    $itemId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventItemsByItem(
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const calendarEventItemsByCalendarEvent = /* GraphQL */ `
  query CalendarEventItemsByCalendarEvent(
    $calendarEventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventItemsByCalendarEvent(
      calendarEventId: $calendarEventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const calendarEventItemsByTenant = /* GraphQL */ `
  query CalendarEventItemsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventItemsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const calendarEventItemsByRetailStore = /* GraphQL */ `
  query CalendarEventItemsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventItemsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCalendarEventItemVariant = /* GraphQL */ `
  query GetCalendarEventItemVariant($id: ID!) {
    getCalendarEventItemVariant(id: $id) {
      id
      tenantId
      retailStoreId
      price
      calendarEventId
      calendarEventItemId
      variantId
      modifierId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      variant {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        variantVariantGroups {
          nextToken
        }
        variantModifiers {
          nextToken
        }
      }
      modifier {
        id
        tenantId
        description
        color
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      calendarEvent {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          nextToken
        }
      }
      calendarEventItem {
        id
        tenantId
        retailStoreId
        itemId
        qty
        calendarEventId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItemVariants {
          nextToken
        }
      }
    }
  }
`;
export const listCalendarEventItemVariants = /* GraphQL */ `
  query ListCalendarEventItemVariants(
    $filter: ModelCalendarEventItemVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarEventItemVariants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        price
        calendarEventId
        calendarEventItemId
        variantId
        modifierId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItem {
          id
          tenantId
          retailStoreId
          itemId
          qty
          calendarEventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const calendarEventItemVariantsByCalendarEventItem = /* GraphQL */ `
  query CalendarEventItemVariantsByCalendarEventItem(
    $calendarEventItemId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventItemVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventItemVariantsByCalendarEventItem(
      calendarEventItemId: $calendarEventItemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        price
        calendarEventId
        calendarEventItemId
        variantId
        modifierId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItem {
          id
          tenantId
          retailStoreId
          itemId
          qty
          calendarEventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const calendarEventItemVariantsByTenant = /* GraphQL */ `
  query CalendarEventItemVariantsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventItemVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventItemVariantsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        price
        calendarEventId
        calendarEventItemId
        variantId
        modifierId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItem {
          id
          tenantId
          retailStoreId
          itemId
          qty
          calendarEventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const calendarEventItemVariantsByRetailStore = /* GraphQL */ `
  query CalendarEventItemVariantsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventItemVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventItemVariantsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        price
        calendarEventId
        calendarEventItemId
        variantId
        modifierId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        variant {
          id
          tenantId
          description
          sorting
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        modifier {
          id
          tenantId
          description
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          email
          deleted
          operatorId
          closed
          isPending
          address
          source
          printed
          createdAt
          updatedAt
        }
        calendarEventItem {
          id
          tenantId
          retailStoreId
          itemId
          qty
          calendarEventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getEJProgramming = /* GraphQL */ `
  query GetEJProgramming($id: ID!) {
    getEJProgramming(id: $id) {
      id
      tenantId
      status
      type
      number
      completedDatetime
      deleted
      reason
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
    }
  }
`;
export const listEJProgrammings = /* GraphQL */ `
  query ListEJProgrammings(
    $filter: ModelEJProgrammingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEJProgrammings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        status
        type
        number
        completedDatetime
        deleted
        reason
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ejProgrammingsByNumber = /* GraphQL */ `
  query EjProgrammingsByNumber(
    $number: String
    $sortDirection: ModelSortDirection
    $filter: ModelEJProgrammingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ejProgrammingsByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        status
        type
        number
        completedDatetime
        deleted
        reason
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ejProgrammingsByTenant = /* GraphQL */ `
  query EjProgrammingsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEJProgrammingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ejProgrammingsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        status
        type
        number
        completedDatetime
        deleted
        reason
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchEJProgrammings = /* GraphQL */ `
  query SearchEJProgrammings(
    $filter: SearchableEJProgrammingFilterInput
    $sort: SearchableEJProgrammingSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEJProgrammings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        status
        type
        number
        completedDatetime
        deleted
        reason
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getECRNumber = /* GraphQL */ `
  query GetECRNumber($id: ID!) {
    getECRNumber(id: $id) {
      id
      fwVersion
      ejNumber
      ejInitDay
      ejMissingDays
      ejType
      ejExtensionRequested
      ejProgrammingRequested
      forcedEjExpires
      createdAt
      updatedAt
    }
  }
`;
export const listECRNumbers = /* GraphQL */ `
  query ListECRNumbers(
    $filter: ModelECRNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECRNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fwVersion
        ejNumber
        ejInitDay
        ejMissingDays
        ejType
        ejExtensionRequested
        ejProgrammingRequested
        forcedEjExpires
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOpticalFile = /* GraphQL */ `
  query GetOpticalFile($id: ID!) {
    getOpticalFile(id: $id) {
      id
      tenantId
      retailStoreId
      status
      date
      xmlS3Key
      pdfS3Key
      zipS3Key
      csvS3Key
      errorReason
      protocolId
      sendOutcomeCode
      sendOutcomeDescription
      errorDescription
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listOpticalFiles = /* GraphQL */ `
  query ListOpticalFiles(
    $filter: ModelOpticalFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpticalFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        status
        date
        xmlS3Key
        pdfS3Key
        zipS3Key
        csvS3Key
        errorReason
        protocolId
        sendOutcomeCode
        sendOutcomeDescription
        errorDescription
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const opticalFilesByRetailStore = /* GraphQL */ `
  query OpticalFilesByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOpticalFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    opticalFilesByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        status
        date
        xmlS3Key
        pdfS3Key
        zipS3Key
        csvS3Key
        errorReason
        protocolId
        sendOutcomeCode
        sendOutcomeDescription
        errorDescription
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const opticalFilesByTenant = /* GraphQL */ `
  query OpticalFilesByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOpticalFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    opticalFilesByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        status
        date
        xmlS3Key
        pdfS3Key
        zipS3Key
        csvS3Key
        errorReason
        protocolId
        sendOutcomeCode
        sendOutcomeDescription
        errorDescription
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getInventoryMovement = /* GraphQL */ `
  query GetInventoryMovement($id: ID!) {
    getInventoryMovement(id: $id) {
      id
      tenantId
      retailStoreId
      datetime
      reasonCode
      itemId
      qtyDiff
      notes
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
    }
  }
`;
export const listInventoryMovements = /* GraphQL */ `
  query ListInventoryMovements(
    $filter: ModelInventoryMovementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventoryMovements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        datetime
        reasonCode
        itemId
        qtyDiff
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const inventoryMovementsByRetailStore = /* GraphQL */ `
  query InventoryMovementsByRetailStore(
    $retailStoreId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryMovementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryMovementsByRetailStore(
      retailStoreId: $retailStoreId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        datetime
        reasonCode
        itemId
        qtyDiff
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const inventoryMovementsByTenant = /* GraphQL */ `
  query InventoryMovementsByTenant(
    $tenantId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryMovementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryMovementsByTenant(
      tenantId: $tenantId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        datetime
        reasonCode
        itemId
        qtyDiff
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const inventoryMovementsByItem = /* GraphQL */ `
  query InventoryMovementsByItem(
    $itemId: ID
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryMovementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryMovementsByItem(
      itemId: $itemId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        datetime
        reasonCode
        itemId
        qtyDiff
        notes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBarcode = /* GraphQL */ `
  query GetBarcode($id: ID!) {
    getBarcode(id: $id) {
      id
      tenantId
      itemId
      barcode
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      item {
        id
        tenantId
        name
        description
        images
        atorderType
        sorting
        vatRate
        vatCode
        extensions
        createdByModule
        deleted
        qtyOnHand
        qtyOnHandByStoreId
        enabledRetailStoreIds
        loyaltyPoints
        time
        image
        externalId
        atorderHide
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        itemGroup {
          nextToken
        }
        priceList {
          nextToken
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          rtDepartment
          expenseType
          createdAt
          updatedAt
        }
        discountSurcharge {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        variantGroups {
          nextToken
        }
        logicalPrinters {
          nextToken
        }
      }
    }
  }
`;
export const listBarcodes = /* GraphQL */ `
  query ListBarcodes(
    $filter: ModelBarcodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBarcodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        itemId
        barcode
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const barcodesByTenant = /* GraphQL */ `
  query BarcodesByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBarcodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    barcodesByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        itemId
        barcode
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const barcodesByItem = /* GraphQL */ `
  query BarcodesByItem(
    $itemId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBarcodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    barcodesByItem(
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        itemId
        barcode
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          time
          image
          externalId
          atorderHide
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMoitoIotFrontendConfigurations = /* GraphQL */ `
  query GetMoitoIotFrontendConfigurations($id: ID!) {
    getMoitoIotFrontendConfigurations(id: $id) {
      id
      tenantId
      retailStoreId
      operatorId
      keyboard
      topbar
      bottombar
      settings
      layout
      numpad
      current_list
      tab_view
      resize_bar
      zoom
      default_list
      last_load_status
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        isSharedTenant
        iban
        enowebMemId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          retailStoreId
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        username
        password
        deleted
        sorting
        icon
        color
        enabled
        isAdmin
        blockPayments
        printWorkshift
        openDrawer
        incoming
        withdrawals
        insertPrice
        voids
        refunds
        discounts
        cancellation
        changeVat
        vatImmune
        preAccounts
        zreport
        updateWarehouse
        insertItems
        takeDesk
        forceTakeDesk
        journal
        finalPreAccounts
        testMode
        frontendConfigurations
        type
        showSettings
        showEditingMode
        showDtrs
        showInvoiceOutcome
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          enowebId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        retailStoreRoom {
          id
          tenantId
          retailStoreId
          name
          extensions
          deleted
          createdAt
          updatedAt
        }
        orderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        receiptPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        invoicePrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        preorderPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        tallonPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        reportPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
        kioskPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listMoitoIotFrontendConfigurationss = /* GraphQL */ `
  query ListMoitoIotFrontendConfigurationss(
    $filter: ModelMoitoIotFrontendConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoitoIotFrontendConfigurationss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        operatorId
        keyboard
        topbar
        bottombar
        settings
        layout
        numpad
        current_list
        tab_view
        resize_bar
        zoom
        default_list
        last_load_status
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const frontendConfigurationsByTenant = /* GraphQL */ `
  query FrontendConfigurationsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotFrontendConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    frontendConfigurationsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        operatorId
        keyboard
        topbar
        bottombar
        settings
        layout
        numpad
        current_list
        tab_view
        resize_bar
        zoom
        default_list
        last_load_status
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const frontendConfigurationsByRetailStore = /* GraphQL */ `
  query FrontendConfigurationsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotFrontendConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    frontendConfigurationsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        operatorId
        keyboard
        topbar
        bottombar
        settings
        layout
        numpad
        current_list
        tab_view
        resize_bar
        zoom
        default_list
        last_load_status
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const frontendConfigurationsByOperator = /* GraphQL */ `
  query FrontendConfigurationsByOperator(
    $operatorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMoitoIotFrontendConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    frontendConfigurationsByOperator(
      operatorId: $operatorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        operatorId
        keyboard
        topbar
        bottombar
        settings
        layout
        numpad
        current_list
        tab_view
        resize_bar
        zoom
        default_list
        last_load_status
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          username
          password
          deleted
          sorting
          icon
          color
          enabled
          isAdmin
          blockPayments
          printWorkshift
          openDrawer
          incoming
          withdrawals
          insertPrice
          voids
          refunds
          discounts
          cancellation
          changeVat
          vatImmune
          preAccounts
          zreport
          updateWarehouse
          insertItems
          takeDesk
          forceTakeDesk
          journal
          finalPreAccounts
          testMode
          frontendConfigurations
          type
          showSettings
          showEditingMode
          showDtrs
          showInvoiceOutcome
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTobacco = /* GraphQL */ `
  query GetTobacco($id: ID!) {
    getTobacco(id: $id) {
      id
      tenantId
      s3Key
      otherS3Key
      internalS3Key
      type
      validFrom
      validTo
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listTobaccos = /* GraphQL */ `
  query ListTobaccos(
    $filter: ModelTobaccoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTobaccos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        s3Key
        otherS3Key
        internalS3Key
        type
        validFrom
        validTo
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tobaccosByTenantSortValidFrom = /* GraphQL */ `
  query TobaccosByTenantSortValidFrom(
    $tenantId: ID
    $validFrom: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTobaccoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tobaccosByTenantSortValidFrom(
      tenantId: $tenantId
      validFrom: $validFrom
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        s3Key
        otherS3Key
        internalS3Key
        type
        validFrom
        validTo
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($id: ID!) {
    getVendor(id: $id) {
      id
      tenantId
      deleted
      companyName
      companyVat
      fiscalCode
      zipCode
      county
      municipality
      address
      country
      recipientCode
      email
      mobile
      extensions
      costCenterId
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      costCenter {
        id
        tenantId
        name
        description
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        deleted
        companyName
        companyVat
        fiscalCode
        zipCode
        county
        municipality
        address
        country
        recipientCode
        email
        mobile
        extensions
        costCenterId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        costCenter {
          id
          tenantId
          name
          description
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vendorsByTenant = /* GraphQL */ `
  query VendorsByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorsByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        deleted
        companyName
        companyVat
        fiscalCode
        zipCode
        county
        municipality
        address
        country
        recipientCode
        email
        mobile
        extensions
        costCenterId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        costCenter {
          id
          tenantId
          name
          description
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vendorsByCompanyVat = /* GraphQL */ `
  query VendorsByCompanyVat(
    $companyVat: String
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorsByCompanyVat(
      companyVat: $companyVat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        deleted
        companyName
        companyVat
        fiscalCode
        zipCode
        county
        municipality
        address
        country
        recipientCode
        email
        mobile
        extensions
        costCenterId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        costCenter {
          id
          tenantId
          name
          description
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchVendors = /* GraphQL */ `
  query SearchVendors(
    $filter: SearchableVendorFilterInput
    $sort: SearchableVendorSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchVendors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        deleted
        companyName
        companyVat
        fiscalCode
        zipCode
        county
        municipality
        address
        country
        recipientCode
        email
        mobile
        extensions
        costCenterId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        costCenter {
          id
          tenantId
          name
          description
          sorting
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getCostCenter = /* GraphQL */ `
  query GetCostCenter($id: ID!) {
    getCostCenter(id: $id) {
      id
      tenantId
      name
      description
      sorting
      deleted
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        partnerTosAcceptedVersion
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        blockLevel
        dailyReportMode
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listCostCenters = /* GraphQL */ `
  query ListCostCenters(
    $filter: ModelCostCenterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCostCenters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        description
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const costCentersByTenant = /* GraphQL */ `
  query CostCentersByTenant(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCostCenterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    costCentersByTenant(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        description
        sorting
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
