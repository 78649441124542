import React, { Component, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { lazyRetry } from "../../utils";
//import BasePage from "./pages/BasePage";
import { AuthPage } from "../AuthCognito";
import ErrorsPage from "../ErrorsExamples/ErrorsPage";
import { bindActionCreators } from "redux";
import { validateUserSession } from "../AuthCognito/_redux/authActions";
import { connect } from "react-redux";
//import { UnauthorizedEntryPage as AtorderUnauthorizedEntryPage } from "../Atorder/pages/UnauthorizedEntryPage";
import { TenantTermsOfService } from "./pages/terms/TenantTermsOfService";
import { PartnerTermsOfService } from "./pages/terms/PartnerTermsOfService";
import { PrivacyPolicy } from "./pages/terms/PrivacyPolicy";
import { CookiePolicy } from "./pages/terms/CookiePolicy";
//import { DownloadPage as PublicDownloadPage } from "../Download/pages/DownloadPage";

const PublicDownloadPage = lazy(() => lazyRetry(() => import("../Download/pages/DownloadPage"), "PublicDownloadPage"));
const AtorderUnauthorizedEntryPage = lazy(() => lazyRetry(() => import("../Atorder/pages/UnauthorizedEntryPage"), "AtorderUnauthorizedEntryPage"));
const ForValueUnauthorizedEntryPage = lazy(() => lazyRetry(() => import("../ForValue/pages/UnauthorizedEntryPage"), "ForValueUnauthorizedEntryPage"));
const ReceiptUnauthorizedEntryPage = lazy(() => lazyRetry(() => import("../MoitoIOT/pages/ReceiptUnauthorizedEntryPage"), "ReceiptUnauthorizedEntryPage"));
const EInvoiceUnauthorizedEntryPage = lazy(() => lazyRetry(() => import("../EInvoice/pages/EInvoiceUnauthorizedEntryPage"), "EInvoiceUnauthorizedEntryPage"));
const CalendarUnauthorizedEntryPage = lazy(() => lazyRetry(() => import("../Calendar/pages/UnauthorizedEntryPage"), "CalendarUnauthorizedEntryPage"));
const BasePageWrapper = lazy(() => lazyRetry(() => import("./pages/BasePageWrapper"), "BasePageWrapper"));

class Routes extends Component {
  render() {
    let authenticated = this.props.authenticated;

    return (
      <Switch>
        <Route path="/error" component={ErrorsPage} />
        <Route path="/tenant-terms" component={TenantTermsOfService} />
        <Route path="/partner-terms" component={PartnerTermsOfService} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/cookie-policy" component={CookiePolicy} />
        <ContentRoute path="/atorder/public" component={AtorderUnauthorizedEntryPage} />
        <ContentRoute path="/forvalue/public" component={ForValueUnauthorizedEntryPage} />
        <ContentRoute path="/receipt/public" component={ReceiptUnauthorizedEntryPage} />
        <ContentRoute path="/doc" component={ReceiptUnauthorizedEntryPage} />
        <ContentRoute path="/invoice" component={EInvoiceUnauthorizedEntryPage} />
        <ContentRoute path="/calendar/public" component={CalendarUnauthorizedEntryPage} />

        <Route path="/download" component={PublicDownloadPage} />

        {!authenticated ? (
          <Switch>
            <Route path="/atorder/public" component={AtorderUnauthorizedEntryPage} />
            <Route path="/forvalue/public" component={ForValueUnauthorizedEntryPage} />
            <Route path="/receipt/public" component={ReceiptUnauthorizedEntryPage} />
            <Route path="/doc" component={ReceiptUnauthorizedEntryPage} />
            <Route path="/invoice" component={EInvoiceUnauthorizedEntryPage} />
            <Route path="/auth" component={AuthPage} />
            <Route path="/" component={AuthPage} />
            <Redirect to="/error" />
          </Switch>
        ) : (
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />

        {!authenticated ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <BasePageWrapper />
        )}
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        validateUserSession,
      },
      dispatch
    ),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
